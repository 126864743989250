import Service from "./service"

class InvoicesService extends Service {
    async getInvoices(offset, count, orderBy, asc, acvId) {
      let formBody = [];
      if (offset) { formBody.push(`offset=${offset}`); }
      if (count) { formBody.push(`count=${count}`); }
      if (orderBy) { formBody.push(`orderBy=${orderBy}`); }
      if (asc) { formBody.push(`asc=${asc}`); }
      if (acvId) { formBody.push(`acvId=${acvId}`); }
      formBody = formBody.join("&");

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Invoices?${formBody}`, requestOptions);
    }

    async getActiveInvoicesCount(acvId, isPaid) {
      let formBody = [];
      if (acvId) { formBody.push(`acvId=${acvId}`); }
      if (isPaid !== null ) { formBody.push(`isPaid=${isPaid}`)}
      formBody = formBody.join("&");

      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Invoices/Count?${formBody}`, requestOptions);
    }
}

export default new InvoicesService();