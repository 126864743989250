import userManager from "../userManager"

class Service {
    getAuthorizationToken() {
      const user = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_PORTAL_IDENTITY_URI}:web_client`));
      if (user) {
        return `Bearer ${user.access_token}`;
      }
      return '';
    }

    async authenticatedFetch(url, settings) {
      settings.headers.Authorization = this.getAuthorizationToken();

      const response = await fetch(url, settings);

      if (response.status === 401) {
        //to prevent redirect loop when backend identity settings issue
        const user = await userManager.getUser();
        if (user) { 
          userManager.signoutRedirect() 
        }
        else {
          userManager.signinRedirect();
        } 
      }

      if (response.status === 404) {
        throw { Message: `Не найдена страница по адресу ${url}` };
      }

      if (response.status === 200){
        const result = await response.json();

        //TODO возможно стоит переделать
        if (result.IsOk === false) {
          throw { Message: result.Message }
        }

        //если результат из СРМ формата { IsOk, Message, Value }
        if (result.IsOk === true) {
          return result.Value;
        }

        return result;
      }

      const result = await response.json();
      
      if (result){
        throw result;
      }

      throw { Message: "Произошла ошибка при запросе" };
    }

    async authenticatedFetchWithBooleanResult(url, settings) {
      settings.headers.Authorization = this.getAuthorizationToken();

      const response = await fetch(url, settings);

      if (response.status === 401) {
        userManager.signinRedirect();
      }

      if (response.status === 404) {
        throw { Message: `Не найдена страница по адресу ${url}` };
      }

      if (response.status === 200){
        const result = await response.json();

        if (result.IsOk !== null && result.IsOk !== undefined) {
          return result.IsOk;
        }
      }

      throw { Message: "Произошла ошибка при запросе" };
    }
}

export default Service;