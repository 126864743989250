import Service from "../service";

class OkpdService extends Service {
  async getByParent(parentAndSelectedCodes) {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      },
      body: JSON.stringify(parentAndSelectedCodes)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_OKPD_API_URI : 'http://localhost:5567';
    return await this.authenticatedFetch(`${baseURL}/api/okpd/getByParent`, requestOptions);
  }

  async search(searchText) {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    //если поиск идет по строке не содержащей ни одной буквы или цифры - выдаем пустой результат
    const regex = new RegExp("[a-zA-Zа-яА-Я0-9]+");
    if (!regex.test(searchText)) {
        return { count: 0, okpdCodes: []};
    }

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_OKPD_API_URI : 'http://localhost:5567';
    return await this.authenticatedFetch(`${baseURL}/api/okpd/search/${searchText}`, requestOptions);
  }
}

export default new OkpdService();