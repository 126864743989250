import React, {useState} from "react";
import {useDispatch} from 'react-redux';
import { Dialog, DialogTitle, Typography, Button, DialogContent, DialogActions, IconButton, Box, Select, MenuItem } from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from 'notistack';

import purchasesInWorkService from "../../services/purchasesInWorkService";

export const PurchaseInWorkCancelRequestDialog = (props) => {
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [comment, setComment] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();

    const handleSendCancelRequest = async (event) => {
        event.preventDefault();

        const cancelRequest = {
            Comment: comment,
            Id: props.purchaseInWorkId
        };
        setSubmitInProgress(true);

        return purchasesInWorkService.cancelPurchaseInWork(cancelRequest)
            .then(() => {
                enqueueSnackbar('Запрос на отзыв закупки успешно отправлен', { variant: "success" });
                purchasesInWorkService.getSinglePurchaseInWork(props.purchaseInWorkId)
                .then((purchaseInWork) => {
                    const action = {
                        type: "PURCHASE_IN_WORK_UPDATED_SINGLE",
                        purchaseInWork: purchaseInWork
                    };
                    dispatch(action);
                })
                .catch((error) => {
                    console.log(error);
                })
                props.onClose();                
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error?.Message ? error.Message : ''}`, { variant: "error" });
            })
            .finally(() => {
                setSubmitInProgress(false);
            }) 
    }

    return (
        <React.Fragment>
            <Dialog open={props.openDialog} onClose={props.onClose} className="modal_purchase">
                <form onSubmit={handleSendCancelRequest}>
                    <Box position="absolute" top={0} right={0} className="modal_purchase_close">
                        <IconButton 
                            onClick={props.onClose}
                        >
                            <CloseIcon color="action"/>
                        </IconButton>
                    </Box>
                    <DialogTitle className="modal_purchase_title">
                        Запрос на отзыв закупки из работы
                    </DialogTitle>
                    <DialogContent className="modal_purchase_content">
                        <Typography className="modal_purchase_title_textarea">Причина:</Typography>
                        <TextareaAutosize
                            minRows={12}
                            cols={60}
                            required={true}
                            onChange={(event) => setComment(event.target.value)}
                        />
                    </DialogContent>
                    <DialogActions className="modal_purchase_btns">
                        <Button onClick={props.onClose} variant="outlined">
                            Отмена
                        </Button>
                        <Button disabled={submitInProgress} type="submit" variant="contained">
                            Отправить
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
		</React.Fragment>
    )
}
