import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';

import {Button, CircularProgress, Stack} from '@mui/material';

import TemplateService from '../../services/search-purchases-services/TemplateService.js';

class TemplatesPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };

    }

    componentDidMount() {
        this.setState({isLoading: true});
        TemplateService.getAll()
            .then((templates) => {
                this.props.onGetTemplates(templates);
                this.setState({isLoading: false});
            })
            .catch((error) => {
                this.props.enqueueSnackbar(
                    'Произошла ошибка при получении списка шаблонов',
                    {
                        variant: 'error'
                    });
                this.setState({isLoading: false});
            });
    }

    render() {
        return (
            <React.Fragment>
                <Stack>
                    {
                        this.props.templates.length > 0
                            ?
                            <>
                                <div className='filter_block_header'>
                                    Шаблоны
                                </div>
                                <div className='templates_btns'>
                                    {this.props.templateId ?
                                        <Button className='unset_template_btn' variant="outlined" color="error"
                                                size="small" onClick={() => this.props.onResetTemplate()}>Закрыть
                                            шаблон</Button>
                                        : null
                                    }
                                    <div className='templates_btns_inner'>
                                        {this.props.templates.map((template, key) => {
                                            var variant = template.id == this.props.templateId ? "contained" : "outlined";
                                            return <Button key={key} variant={variant}
                                                           onClick={() => this.props.onGetTemplate(template.id, this.props.enqueueSnackbar)}>{template.name}</Button>
                                        })}
                                    </div>
                                    
                                </div>
                            </>
                            :
                            this.state.isLoading
                                ? <CircularProgress sx={{mb: 3}}/>
                                : null
                    }
                </Stack>

            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        templateId: state.searchPurchasesState.templateId,
        templates: state.searchPurchasesState.templates
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onGetTemplates: (templates) => {
            const action = {
                type: 'GET_TEMPLATES',
                templates: templates
            };

            dispatch(action);
        },
        onResetTemplate: () => {
            const action = {
                type: 'RESET_TEMPLATES'
            };
            dispatch(action);
        },
        onGetTemplate: (templateId, enqueueSnackbar) => {
            TemplateService.getById(templateId)
                .then(template => {
                        const action = {
                            type: 'GET_TEMPLATE',
                            keywords: template.keywords,
                            ignoreKeywords: template.ignoreKeywords,
                            shouldSearchInDocuments: template.shouldSearchInDocuments,
                            shouldSearchInOkpdNames: template.shouldSearchInOkpdNames,
                            havePrepayment: template.havePrepayment,
                            haveRecommendation: template.haveRecommendation,
                            laws: template.laws,
                            datePublicationFrom: template.datePublicationFrom,
                            datePublicationTo: template.datePublicationTo,
                            dateExpirationFrom: template.dateExpirationFrom,
                            priceFrom: template.priceFrom,
                            priceTo: template.priceTo,
                            stages: template.stages,
                            kladrCodes: template.kladrCodes,
                            auctionTypes: template.auctionTypes,
                            okpdCodes: template.okpdCodes,
                            industryCodes: template.industryCodes,
                            customers: template.customers,
                            shouldIncludeEmptyPrice: template.shouldIncludeEmptyPrice,

                            name: template.name,
                            templateId: templateId,

                            purchases: [],
                            notifyIsEnabled: template.emailNotificationOptions ? template.emailNotificationOptions.isEnabled : false,
                            notifyDays: template.emailNotificationOptions ? template.emailNotificationOptions.notifyDays : []
                        };
                        dispatch(action);
                    }
                )
                .catch((error) => {
                    enqueueSnackbar(
                        'Произошла ошибка при получении шаблона',
                        {
                            variant: 'error'
                        });
                });
        },
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(TemplatesPanel));
