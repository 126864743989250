import React from "react"
import {Button, Tooltip} from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import MailIcon from '@mui/icons-material/Mail';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import mailingsService from "../../services/mailingsService";
import {useSnackbar} from 'notistack';
import {useDispatch} from "react-redux";

import {ConfirmationDialog} from '../confirmation-dialog';

export const MailingsItem = (props) => {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();

    const mailing = props.mailing;

    const subscribe = async () => {
        return mailingsService.subscribe(mailing.Id)
            .then(() => {
                enqueueSnackbar('Подписка на рассылку успешно возобновлена', { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar('При возобновлении подписки на рассылку возникла ошибка', { variant: "error" });
            })
            .finally(() => {
                const action = {
                    type: 'MAILINGS_LOADING_STARTED'
                };
                dispatch(action);
                
                props.onRefresh();
            })
    }
    
    const unsubscribe = async () => {
        return mailingsService.unsubscribe(mailing.Id)
            .then(() => {
                enqueueSnackbar('Подписка на рассылку успешно приостановлена', { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar('При приостановке подписки на рассылку возникла ошибка', { variant: "error" });
            })
            .finally(() => {
                const action = {
                    type: 'MAILINGS_LOADING_STARTED'
                };
                dispatch(action);
                
                props.onRefresh();
            })
    }

    const remove = async () => {
        return mailingsService.remove(mailing.Id)
            .then(() => {
                enqueueSnackbar('Рассылка успешно удалена', { variant: "success" });
            })
            .catch(() => {
                enqueueSnackbar('При удалении рассылки возникла ошибка', { variant: "error" });
            })
            .finally(() => {
                const action = {
                    type: 'MAILINGS_LOADING_STARTED'
                };
                dispatch(action);

                props.onRefresh();
            })
    }

    const openModal = (mailing) => {
        const action = {
            type: 'MAILING_EDIT_STARTED',
            currentEditedMailing: mailing
        };
        dispatch(action);
    }

    return (
        <React.Fragment>
            <div className="one_mailing">
                <Tooltip title={mailing.Name}>
                    <div className="one_mailing_name">
                        {mailing.Name}
                    </div>
                </Tooltip>
                <Tooltip title={mailing.Keywords}>
                    <div className="one_mailing_description">
                        <span>
                            {mailing.Keywords}
                        </span>
                    </div>
                </Tooltip>
                <Tooltip title={mailing.ExcludeKeywords}>
                    <div className="one_mailing_description">
                        <span>
                            {mailing.ExcludeKeywords}
                        </span>
                    </div>
                </Tooltip>

                <div className="one_mailing_btn_cnt">
                    {
                        !mailing.AllowEditToClient
                        ?
                        <>
                            <Tooltip title="Просмотреть">
                                <Button onClick={() => openModal(mailing)} className="one_mailing_btn">
                                    <SearchIcon/>
                                </Button>
                            </Tooltip>
                        </>
                        :
                        <>
                            <Tooltip title="Редактировать">
                                <Button onClick={() => openModal(mailing)} className="one_mailing_btn">
                                    <EditIcon/>
                                </Button>
                            </Tooltip>
                        </>
                    }

                    {
                        !mailing.AllowUnsubscribe
                        ?
                        <Tooltip title="Менеджер отключил Вам эту возможность">
                            {/* span - костыль чтобы тултип отображался для отключенной кнопки */}
                            <span>
                                <Button disabled className="one_mailing_btn">
                                    {
                                        mailing.IsActive !== true
                                            ?
                                            <MailIcon/>
                                            :
                                            <UnsubscribeIcon/>
                                    }
                                </Button>
                            </span>
                        </Tooltip>
                        :
                        mailing.IsActive !== true
                            ?
                            <ConfirmationDialog
                                onConfirm={() => {
                                    return subscribe();
                                }}
                                confirmationText="Вы действительно хотите подписаться на эту рассылку?"
                            >
                                <Tooltip title="Подписаться">
                                    <Button onClick={() => subscribe()} className="one_mailing_btn">
                                        <MailIcon/>
                                    </Button>
                                </Tooltip>
                            </ConfirmationDialog>
                            :
                            <ConfirmationDialog
                                onConfirm={() => {
                                    return unsubscribe();
                                }}
                                confirmationText="Вы действительно хотите отписаться от этой рассылки?"
                            >
                                <Tooltip title="Отписаться">
                                    <Button onClick={() => unsubscribe()} className="one_mailing_btn">
                                        <UnsubscribeIcon/>
                                    </Button>
                                </Tooltip>
                            </ConfirmationDialog>
                    }
                    {
                        !mailing.AllowEditToClient
                        ?
                        <Tooltip title="Менеджер отключил Вам эту возможность">
                            <span>
                                <Button disabled className="one_mailing_btn">
                                    <DeleteIcon/>
                                </Button>
                            </span>
                        </Tooltip>
                        :
                        <>
                            <ConfirmationDialog
                                onConfirm={() => {
                                    return remove();
                                }}
                                confirmationText="Вы действительно хотите удалить эту рассылку?"
                            >
                                <Tooltip title="Удалить">
                                    <Button className="one_mailing_btn">
                                        <DeleteIcon/>
                                    </Button>
                                </Tooltip>
                            </ConfirmationDialog>
                        </>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}
