import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { connect } from 'react-redux';

import { EmailConfirmation } from "./email-confirmation"
import { UpdateUserInfo } from "./update-userinfo"
import { UpdatePassword } from "./update-password"
import CompaniesList from "../company-management/companies-list";
import { UserInfo } from './user-info';
const UserSettings = (props) => {
    useEffect(() => {
        props.onLoadingPage();
    }, []);

    return (
        <React.Fragment>
            <Box className="settings_cnt">
                <Box className="settings_wrapper">
                    <UserInfo />
                </Box>
                <Box className="settings_companies">
                    <CompaniesList />
                </Box>
                <Box className="settings_user_block">
                    <Box className="settings_update_user">
                        <UpdateUserInfo />
                    </Box>
                    <Box className="settings_update_password">
                        <UpdatePassword />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentPage: state.pagesState.currentPage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadingPage: () => {
            const pageAction = {
                type: 'CURRENT_PAGE_CHANGED',
                currentPage: 'settings'
            };
            dispatch(pageAction);
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)

