import { useEffect } from "react";

/**
 * Hook that listen clicks outside of the passed ref and do action when matching condition
 * props:
 * ref - DOM element ref,
 * condition - boolean condition when start listening outside clicks,
 * action - action to execute on outside click
 */
export function useOutsideClickListener(ref, condition, action) {
    useEffect(() => {
        if (!condition) {
            return;
        }

        function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            action();
        }}

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, action, condition]);
}