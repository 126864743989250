import { Log, UserManager, WebStorageStateStore } from 'oidc-client';

const settings = {
  authority: process.env.REACT_APP_PORTAL_IDENTITY_URI,
  client_id: 'web_client',
  redirect_uri: window.location.origin + '/signin-callback',
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  silent_redirect_uri: window.location.origin + '/silent-renew-callback',
  post_logout_redirect_uri: window.location.origin + '/logout-callback',
  response_type: 'id_token token',
  scope: 'purchases templates openid profile clientportal',
  userStore: new WebStorageStateStore({ store: localStorage}),
  monitorSession: false,
};
const userManager = new UserManager(settings);

userManager.events.addSilentRenewError((error) => {
  console.log("Не удается получить токен " + error);
  userManager.signoutRedirect();
});

Log.logger = console;
Log.level = Log.INFO;

export default userManager;