const initialState = {
  isLoading: true,
  mailings: [],
  showMailingEditModal: false,
  currentEditedMailing: {
    Name: '',
    PurchaseStatusStarted: true,
    PurchaseStatusInProgress: true,
    PurchaseStatusCompleted: true,
    PurchaseStatusCancelled: true,
    KoAuctions: true,
    EpAuctions: true,
    OtherAuctions: true,
    ZkAuctions: true,
    EaAuctions: true,
    Ea615Auctions: true,
    KladrCodeItems: [],
    MinContractPrice: 0,
    MaxContractPrice: 200000000000,
    MinApplicationGuarantee: 0,
    MaxApplicationGuarantee: 200000000000,
    MinContractGuarantee: 0,
    MaxContractGuarantee: 200000000000,
    Purchases44: true,
    Purchases223: true,
    Commercial: true,
    Keywords: '',
    ExcludeKeywords: '',
    DaysLeftTillCollectingEnd: null,
    Okpd2Codes: [],
    SearchKeywordsInDocuments: false,
    SearchKeywordsInOkpdNames: true,
    HasPrepayment: false,
    IncludeWithoutContractPrice: true,
    AllowEditToClient: true
  }
}

const reducer = (state = initialState, action) => {
  if (action.type === 'MAILINGS_LOADING_STARTED') {
    return {
      ...state,
      isLoading: true
    }
  }

  if (action.type === 'MAILINGS_LOADING_COMPLETED') {
    return {
      ...state,
      isLoading: false,
      mailings: action.mailings
    }
  }

  if (action.type === 'MAILINGS_LOADING_FAILED') {
    return {
      ...state,
      isLoading: false
    }
  }

  if (action.type === 'MAILING_EDIT_STARTED') {
    return {
      ...state,
      showMailingEditModal: true,
      currentEditedMailing: action.currentEditedMailing ?? initialState.currentEditedMailing
    }
  }

  if (action.type === 'MAILING_EDIT_FINISHED') {
    return {
      ...state, 
      showMailingEditModal: false,
      currentEditedMailing: initialState.currentEditedMailing
    }
  }

  if (action.type === 'MAILINGS_EDIT_NAME') {
    return {
      ...state,
      currentEditedMailing: {
        ...state.currentEditedMailing,
        Name: action.Name
      }
    }
  }

  if (action.type === 'MAILING_REDIRECTED_TO_SEARCH') {
    return {
      ...state, 
      showMailingEditModal: false,
      currentEditedMailing: initialState.currentEditedMailing
    }
  }

  return state;
}

export default reducer;