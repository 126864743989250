const initialState = {
  currentPage : 0,
  pagesCount : 0,
  pageSize : 20,
  orderBy : 'Created',
  asc : false,
  invoices : [],
  isLoading: true
}

const reducer = (state = initialState, action) => {
  if (action.type === 'INVOICES_LOAD_STARTED') {
    return {
      ...state,
      isLoading: true
    }
  }
  if (action.type === 'INVOICES_LOAD_ERROR') {
    return {
      ...state,
      isLoading: false
    }
  }
  if (action.type === 'INVOICES_PAGE_LOADED') {
    return {
      ...state,
      pagesCount: action.pagesCount,
      currentPage: action.currentPage,
      invoices: action.invoices,
      isLoading: false
    }
  }
  if (action.type === 'INVOICES_SORTING_CHANGED') {
    const sortByObject = JSON.parse(action.value);
    return {
      ...state,
      orderBy: sortByObject.orderBy,
      asc: sortByObject.asc
    }
  }
  if (action.type === 'INVOICES_PAGE_SIZE_CHANGED') {
    return {
      ...state,
      pageSize: action.value
    }
  }

  return state;
}

export default reducer;