import React, { useEffect, useState } from "react"
import { Box, CardContent, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from "react-router-dom";
import { DateTime } from 'luxon';
import { NumberFormatCustom } from '../number-format-custom';

export const MainPageRelevantSelectedPurchases = (props) => {
    const getSourcePlatform = (purchase) => {
        if (purchase.sourcePlatform) {
          return purchase.sourcePlatform.name;
        } else {
          return null
        }
      }  
    
    const getLaw = (purchase) => {
        switch(purchase.law) {
            case 1:
            return '44-ФЗ';
            case 2:
            return '223-ФЗ';
            case 3:
            return 'Коммерческие';
            default:
            return 'Не определено';
        }    
    }
    
    const getFormatDate = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

        return formattedDate;
    }

    const getDateDiff = (inputDate) => {
        const date = DateTime.fromISO(inputDate);
        const currentDate = DateTime.now();
        let result = "";
        let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();
    
        if (dateDiff.days === 1 || (dateDiff.Days === 0 && dateDiff.hours === 1)) {
          result = result.concat("Остался ")
        }
        else {
          result = result.concat("Осталось ");
        }
    
        if (dateDiff.days > 0) {
          result = result.concat(dateDiff.days+" д. ");
        }
        if (dateDiff.hours > 1) {
          result = result.concat(parseInt(dateDiff.hours)+" ч. ");
        }
        if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
          result = result.concat("менее часа");
        }
        if (dateDiff.hours < 0){
          result = "";
        }
    
        return result;
    }

    const getLocalFormatDate = (inputDate) => {
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');
    
        return formattedDate;
      }

    const getStage = (purchase) => {
        switch(purchase.stage) {
          case 1:
            //return <div>Подача заявок <div>{getFormatDate(purchase.purchaseExpirationAt)}</div></div>;
            const dateMsk = getFormatDate(purchase.purchaseExpirationAt);
            const dateLocal = getLocalFormatDate(purchase.purchaseExpirationAt);

            return (
            <React.Fragment>
                <div className='zakupka_block_header'>Подача заявок до: </div>
                <Typography className="zakupka_date_before">{dateMsk} (по МСК)</Typography>
                {dateMsk != dateLocal ? <Typography className="zakupka_date_before">{dateLocal} (по местному)</Typography> : null}
                <Typography className="zakupka_date_before">{getDateDiff(purchase.purchaseExpirationAt)}</Typography>
            </React.Fragment>
            );
          case 2:
            return <div>Работа комиссии</div>;
          case 3:
            return <div>Размещение завершено</div>;
          case 4:
            return <div>Размещение отменено</div>;
          case 5:
            return <div>Торги не состоялись</div>;
          case 6: 
            return <div>Торги запланированы</div>;
          case 7: 
            return <div>На исполнении</div>;
          default:
            return <div>Не определено</div>;
        }
      }

    return (
        <React.Fragment>
            <>
                <h4 className="main_page_header">Актуальные закупки</h4>
                {props.selectedPurchases.length > 0
                  ? props.selectedPurchases.map((purchase, key) => 
                      <div key={key} className="main_selected_purchases_card">
                          <div>
                          <CardContent className="card_content_mobile">
                              <div className="zakupka_card">
                              <Grid item xs={7}>
                                  <Link component={RouterLink} rel="noopener noreferrer" to={`/purchases/${purchase.id}`} underline="hover">  
                                  <div className="highlighted zakupka_name">
                                      {purchase.title}
                                  </div>
                                  </Link>
                                  <Typography variant="caption" color="text.secondary" component="div">
                                  {[getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                                  </Typography>
                                  <Typography sx={{mt: 2, mb: 2}} component="div" className="zakupka_platform_container">
                                  <div className='zakupka_block_header'>
                                      Площадка:
                                  </div> 
                                  {getSourcePlatform(purchase)}
                                  </Typography>
                              </Grid>
                              <Grid className='zakupka_aside'>
                                  <div className='zakupka_price'>
                                      <NumberFormatCustom value={purchase.price ? purchase.price : 0} displayType="text" /> ₽
                                  </div>
                                  <Typography variant="subtitle1" component="div" sx={{mt: 2}}>
                                  {getStage(purchase)}
                                  </Typography>
                                  {purchase.isInWork
                                  ? <div className='zakupka_inwork'>Закупка уже в работе</div>
                                  : null
                                  }
                              </Grid>
                              </div>
                          </CardContent>
                          </div>
                      </div>
                    )
                  :
                  <Box className="main_page_small_block">
                    <Box className="main_page_small_block_header">
                      <Box sx={{padding:'5px', fontSize:'15px'}} >
                        <Box className="companies_grid_no_companies_title">На данный момент у вас нет актуальных закупок</Box>
                        <Box className="text_second">
                          Добавьте закупки в избранное и вы увидите здесь напоминания о самых свежих закупках из своего избранного.
                          <br/>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
            </>
		</React.Fragment>
    )
}
