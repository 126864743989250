const initialState = {
  currentPage : 0,
  pagesCount : 0,
  pageSize : 20,
  orderBy : 'CreatedAtUtc',
  asc : false,
  purchasesInWork : [],
  isLoading: true
}

const reducer = (state = initialState, action) => {
  if (action.type === 'PURCHASE_IN_WORK_LOAD_STARTED') {
    return {
      ...state,
      isLoading: true
    }
  }
  if (action.type === 'PURCHASE_IN_WORK_LOAD_ERROR') {
    return {
      ...state,
      isLoading: false
    }
  }
  if (action.type === 'PURCHASE_IN_WORK_PAGE_LOADED') {
    return {
      ...state,
      pagesCount: action.pagesCount,
      currentPage: action.currentPage,
      purchasesInWork: action.purchasesInWork,
      isLoading: false
    }
  }
  if (action.type === 'PURCHASE_IN_WORK_SORTING_CHANGED') {
    const sortByObject = JSON.parse(action.value);
    return {
      ...state,
      orderBy: sortByObject.orderBy,
      asc: sortByObject.asc
    }
  }
  if (action.type === 'PURCHASE_IN_WORK_PAGE_SIZE_CHANGED') {
    return {
      ...state,
      pageSize: action.value
    }
  }

  if (action.type === 'PURCHASE_IN_WORK_UPDATED_SINGLE') {
    let updatedPurchasesInWork = state.purchasesInWork.map((purchaseInWork, key) => {
      if (purchaseInWork.Id == action.purchaseInWork.Id) {
        return action.purchaseInWork;
      } else {
        return purchaseInWork;
      }
    });
    
    return {
      ...state,
      purchasesInWork: updatedPurchasesInWork
    }
  }

  return state;
}

export default reducer;