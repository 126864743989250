import React, { useState } from 'react';
import SettlementsService from '../services/search-purchases-services/SettlementService';
import { useSnackbar } from 'notistack';
import { Autocomplete, Tooltip, Chip, TextField } from '@mui/material';

export const SettlementsSelection = (props) => {
    const [settlements, setSettlements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [settlementsInputTimeout, setSettlementsInputTimeout] = useState(null);

    const { enqueueSnackbar } = useSnackbar();

    return (
        <Autocomplete
            readOnly={props.readOnly}
            freeSolo
            multiple
            clearOnBlur
            forcePopupIcon={false}
            autoHighlight
            id="checkboxes-tags-demo"
            options={settlements}
            filterOptions={(options) => options}
            renderTags={(values, getTagProps) =>
                values.map((value, key) => 
                    <Tooltip 
                        title={value.fullRegionName}
                        key={key}
                    >
                        <Chip
                            onDelete = {() => {
                                const updatedKladrCodes = [...values];
                                updatedKladrCodes.splice(key, 1);
                                props.onKladrCodesChanges(updatedKladrCodes);
                            }}
                            label={`${value.shortObjectType} ${value.name}`}
                        />
                    </Tooltip>
                )
            }
            getOptionLabel={(option) => {
                return option.shortObjectType + ' ' + option.name;
            } }
            loading = {isLoading}
            renderOption={(properties, option, { selected }) => {
                if (!props.kladrCodes.some((kladrCode) => kladrCode.code === option.code)) {
                    if (option.fullRegionName) {
                    return (<li {...properties} key={option.code}>
                        <span>{option.shortObjectType + ' ' + option.name + ' '}</span>
                        <p style={{ color: "#aeb3b7"}}>
                            {option.fullRegionName}
                        </p>
                    </li>)
                    } else {
                    return (<li {...properties} key={option.code}>
                        <p className='search_item_2'>
                        {option.shortObjectType + ' ' + option.name + ' '}
                        </p>
                    </li>)
                    }
                }
            }}
            renderInput={(params) => (
            <TextField
                {...params}
                placeholder="Область, округ, республика, край, город, деревня, село"
                onChange={(event) => {
                const value = event.target.value;
                if (value) {
                    if (settlementsInputTimeout !== null){
                        clearTimeout(settlementsInputTimeout);
                    }
                    setSettlementsInputTimeout(setTimeout(() => {
                        setIsLoading(true);
                        SettlementsService.search(value)
                        .then(settlements => {
                            setSettlements(settlements);
                          })
                        .catch((error) => {
                            enqueueSnackbar(
                                'Произошла ошибка при получении списка населенных пунктов',
                                { 
                                variant: 'error'
                            });
                        })
                        .finally(() => {
                            setIsLoading(false);
                        });
                    }, 500));
                }
                else {
                    clearTimeout(settlementsInputTimeout);
                    setSettlements([]);
                }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && settlements.length === 0) {
                        event.stopPropagation();
                    }
                }
                }
            />
            )}
            onChange={(event, options) => {
                props.onKladrCodesChanges(options);
            }}
            onClose={(event, reason) => {
                setSettlements([]);
            }}
            value={props.kladrCodes}
        />
    )
}
