import Service from "../service";

class PurchaseService extends Service {
    async search(options) {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        },
        body: JSON.stringify(options)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      return await this.authenticatedFetch(`${baseURL}/api/purchases/search`, requestOptions);
    }
  
    async get(id) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        }
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      return await this.authenticatedFetch(`${baseURL}/api/purchases/${id}`, requestOptions);
    }

    async getByOrganizationId(request) {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        },
        body: JSON.stringify(request)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      return await this.authenticatedFetch(`${baseURL}/api/purchases/organization`, requestOptions);
    }
    
    async getMany(ids) {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json',
          'Authorization': this.getAuthorizationToken()
        },
        body: JSON.stringify({ ids }),
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      
      const response = await fetch(`${baseURL}/api/purchases/search`, requestOptions);
      const result = await response.json();

      //TODO разобраться и привести к универсальному
      if (response.status == 200) {  
        return result;
      } else {
        console.error('PurchaseService > getMany > ', result);
        return {
          hasError: true
        };
      }
    }

    async getByIds(ids) {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        },
        body: JSON.stringify(ids)
      };
  
      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_API_URI : 'http://localhost:5552';
      return await this.authenticatedFetch(`${baseURL}/api/purchases/byids`, requestOptions);      
    }
  }
  
  export default new PurchaseService();