import React, { Component } from 'react';
import CustomerService from '../../services/search-purchases-services/CustomerService';
import CustomerRatingService from '../../services/search-purchases-services/CustomerRatingService';
import { withSnackbar } from 'notistack';

// MUI:
import { 
  Card, CardContent, Box, CardHeader, Rating
} from '@mui/material';

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      averageRating: 0,
      customer: null
    }; 
  }

  componentDidMount() {
    const customerOrganizationId = this.props.customerOrganizationId;
    if (customerOrganizationId) {
      CustomerService.getCustomer(customerOrganizationId)
        .then(customer => { 
          this.setState({ customer: customer}); 
        })
        .catch(error => {
          this.props.enqueueSnackbar(
            'Произошла ошибка при получении данных о заказчике',
            { 
              variant: 'error'
            });
        });
      CustomerRatingService.getCustomerRating(customerOrganizationId)
        .then(rating => {
          this.setState({ averageRating: parseFloat(rating)});
        })
        .catch(error => {
          this.props.enqueueSnackbar(
            'Произошла ошибка при получении данных о рейтинге заказчика',
            { 
              variant: 'error'
            });
        });
    }
  }


  render() {
    if (this.state.customer) {
      return (
        <React.Fragment> 
          <div className='detail_block customer_block'>
            <div className='detail_block_header'>
              Заказчик
            </div>
            <div className='customer_block_grid'>
              <div className='card_content customer_card_name'>
                <div className='card_title'>
                  Наименование
                </div>
                <div className='card_info'>
                  {this.state.customer.name}
                </div>
              </div>
              {this.state.customer.kpp
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      КПП
                    </div>
                    <div className='card_info'>
                      {this.state.customer.kpp}
                    </div>
                  </div>
                </>
              : <></>}    
              {this.state.customer.inn
              ? <>                
                  <div className='card_content'>
                    <div className='card_title'>
                      ИНН
                    </div>
                    <div className='card_info'>
                      {this.state.customer.inn}
                    </div>
                  </div>
                </>
              : <></>}          
              {this.state.customer.ogrn
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      ОГРН
                    </div>
                    <div className='card_info'>
                      {this.state.customer.ogrn}
                    </div>
                  </div>
                </>
              : <></>}
              {this.state.customer.email
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Электронная почта
                    </div>
                    <a href={`mailto:${this.state.customer.email}`} className='card_info'>
                      {this.state.customer.email}
                    </a>
                  </div>
                </>
              : <></>}             
              {this.state.customer.phone
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Контактный телефон
                    </div>
                    <a href={`tel:${this.state.customer.phone}`} className='card_info'>
                      {this.state.customer.phone}
                    </a>
                  </div>
                </>
              : <></>}
              {this.state.customer.sourceRegion
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Регион
                    </div>
                    <div className='card_info'>
                      {this.state.customer.sourceRegion}
                    </div>
                  </div>                
                </>
              : <></>} 
              {this.state.customer.sourceActualAddress
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Место нахождения
                    </div>
                    <div className='card_info'>
                      {this.state.customer.sourceActualAddress}
                    </div>
                  </div>
                </>
              : <></>}
              {this.state.customer.sourcePostalAddress
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Почтовый адрес
                    </div>
                    <div className='card_info'>
                      {this.state.customer.sourcePostalAddress}
                    </div>
                  </div>               
                </>
              : <></>}     
              {this.state.customer.responsiblePerson
              ? <>
                  <div className='card_content'>
                    <div className='card_title'>
                      Ответственное лицо
                    </div>
                    <div className='card_info'>
                      {this.state.customer.responsiblePerson}
                    </div>
                  </div>
                </>
              : <></>}  
              <div className='card_content'>
                <div className='card_title'>
                  Средняя оценка
                </div>
                <div className='card_info'>
                  <Rating value={this.state.averageRating} precision={0.1} readOnly />
                </div>
              </div>
            </div>      
          </div>
        </React.Fragment>
      )  
    }
  }
}

export default withSnackbar(CustomerDetails);