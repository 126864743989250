const initialState = {
  IndustryCodes: [],
  checkedIndustryCodes: [],
  openIndustrySelectionModal: false,
  isLoading: false
}

const reducer = (state = initialState, action) => {
  if (action.type === 'INDUSTRIES_INITIATED') {
    var updatedIndustryCodes = [...action.IndustryCodes];
    return {
      ...state,
      IndustryCodes: updatedIndustryCodes,
      searchWarningMessage: ''
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_OPENED') {
    return {
      ...state,
      checkedIndustryCodes: action.checkedIndustryCodes,
      openIndustrySelectionModal: true
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_CLOSED') {
    return {
      ...state,
      openIndustrySelectionModal: false
    }
  }

  if (action.type === 'INDUSTRIES_SELECTION_COMPLETED') {
    return {
      ...state,
      openIndustrySelectionModal: false
    }
  }

  if (action.type === 'INDUSTRY_CODE_CHECKED') {
    let updatedCheckedIndustryCodes = [];

    let positions = [...action.checkedIndustryNodesPositions];

    let iterator = 0;
    while (positions[iterator]) {
      const currentPos = positions[iterator].pos + '-';

      //remove all descendant checked elements
      //so only parent elements left
      positions = positions.filter((element) => {
        if (element.pos.startsWith(currentPos) === false)
        {
          return element;
        }
      });
      iterator++;
    }

    updatedCheckedIndustryCodes = positions.map((element, key) => {
      return {
        key: element.node.key,
        title: element.node.title,
        children: element.node.children
      };
    })

    return {
      ...state,
      checkedIndustryCodes: updatedCheckedIndustryCodes
    }
  }

  if (action.type === 'SINGLE_SELECTED_INDUSTRY_REMOVED' || action.type === 'TEMPLATES_SINGLE_SELECTED_INDUSTRY_REMOVED') {
    const value = action.key;
    const array = state.checkedIndustryCodes.map(code => {
      return code.key;
    });
    const index = array.indexOf(value);
    const updatedCheckedIndustryCodes = [...state.checkedIndustryCodes];
    if (index !== -1) {
      updatedCheckedIndustryCodes.splice(index, 1);
      return {
        ...state,
        checkedIndustryCodes: updatedCheckedIndustryCodes
      }
    }
  }

  if (action.type === 'SINGLE_SELECTED_INDUSTRIES_REMOVED') {
    const value = action.key;
    const array = state.checkedIndustryCodes.slice();
    const deletedIndustries = array.find(industry => industry.key === value);
    const index = array.indexOf(deletedIndustries);
    if (index !== -1) {
      array.splice(index, 1);
      return {
        ...state,
        checkedIndustryCodes: array
      }
    }
  }

  return state;
}

export default reducer;