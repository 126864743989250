import React, { useState, useEffect } from "react"
import liveChatService from "../../services/liveChatService";
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';

const LiveChatUserRegistration = (props) => {
    const getUserIdFromLocalStorage = () => {
        const user = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_PORTAL_IDENTITY_URI}:web_client`));
        return user?.profile?.sub;
    }

    const userId = getUserIdFromLocalStorage();

    const currentUserInfo = useSelector((state) => state.profileState.currentUserInfo);

    const cookies = new Cookies(null, { path: '/' });
    const carrotquest_uid = cookies.get('carrotquest_uid', { doNotParse: true});
    const liveChatIdLocalStorageName = `saved-live-chat-id-${userId}`;

    useEffect(() => {
        //if user not logged saving chatId is pointless
        if (userId) {
            if (carrotquest_uid && !localStorage.getItem(liveChatIdLocalStorageName) && currentUserInfo?.phone_number ) {
                liveChatService.SaveChatId({ LiveChatId: carrotquest_uid })
                .then(() => {
                    liveChatService.GetChatId()
                    .then((result) => {
                        localStorage.setItem(liveChatIdLocalStorageName, result);
                    })
                })
            }
        }
    }, [carrotquest_uid])

    useEffect(() => {
        //append carrot quest
        if (!document.getElementById('carrot-quest-script')){
            cookies.remove('carrotquest_uid');

            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('id', 'carrot-quest-script');
            scriptEl.innerHTML = `!function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("${process.env.REACT_APP_CARROT_QUEST_API_KEY}");`;
            document.body.appendChild(scriptEl);
        }

        liveChatService.GetSecretHash()
            .then((result) => {
                window.carrotquest.auth(userId, result);

                window.carrotquest.identify([
                    { op: 'set_once', key: '$email', value: currentUserInfo?.email ?? '' },
                    { op: 'set_once', key: '$phone', value: currentUserInfo?.phone_number ?? '' }
                ]);
            });
    }, [cookies]);

    return (
        <></>
    )
}

export default React.memo(LiveChatUserRegistration);
