import React, { Component } from 'react';
import { connect } from 'react-redux';
import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import { withSnackbar } from 'notistack';
import { ButtonWithWaitForCompletion } from '../button-with-wait-for-completion';

// MUI:
import { 
  Dialog, DialogTitle, List, ListItem, ListItemText, TextField, Button, Grid, IconButton, CircularProgress
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

class SelectTagDialog extends Component {
  render() {
    const errorDivs = this.props.newTagValidationErrors.map((err, key) => <Grid item xs={12} sx={{color:'red'}} key={key}>{err}</Grid>);
    return (
      <React.Fragment> 
        <Dialog open={this.props.openSelectTagDialog} onClose={this.props.onClose} >
          <DialogTitle>
            <Grid container direction="row" justify="space-between" alignItems="center">
              Выберите папки избранного для данной закупки
              <IconButton aria-label="close" onClick={this.props.onClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          
          <List className='folder_list'>
            {
              this.props.isLoading
              ?
                /* TODO эту крутилку заменить на затемнение модалки или что-то типа того? */
                <CircularProgress />
              :
                this.props.tags.map((tag, key) => 
                  (this.props.selectedPurchase.tags && this.props.selectedPurchase.tags.some(e => e.id === tag.id))
                    ? 
                      <ListItem 
                        className='folder_item selected'
                        key={key} 
                        secondaryAction={
                          <CheckIcon />
                        }
                        onClick={() => this.props.onRemovePurchaseTag(this.props.selectedPurchase.id, tag, this.props.enqueueSnackbar)}
                        >
                        <ListItemText primary={tag.name} />
                      </ListItem>
                    : 
                      <ListItem className='folder_item' 
                        onClick={() => this.props.onAddPurchaseTag(this.props.selectedPurchase.id, tag, this.props.selectedTagId, this.props.enqueueSnackbar)}
                        key={key}
                      >
                        <ListItemText primary={tag.name} />
                      </ListItem>
                )
            }
            </List>
            <div className='add_new_folder'>
              <div className='add_new_folder_name'>
                Добавить новую папку для избранного:
              </div>
              <TextField 
                size="small"
                onChange={(event) => {
                  var tag = event.target.value;
                  this.props.onNewTagChanged(tag);
                }}
                value={this.props.newTagName}
                sx={{mr: 1}}
              />
              <ButtonWithWaitForCompletion 
                variant="contained" 
                onClick={() => { 
                  return this.props.onAddTag(this.props.newTagName, this.props.selectedPurchase.id, this.props.selectedTagId, this.props.enqueueSnackbar)
                }}
              > +
              </ButtonWithWaitForCompletion>
            </div>
            <ListItem>
              <Grid item xs={12}>
                { errorDivs }
              </Grid>
            </ListItem>
        </Dialog>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedPurchase: state.selectTagDialogState.selectedPurchase,
    selectedTagId: state.selectTagDialogState.selectedTagId,
    openSelectTagDialog: state.selectTagDialogState.openSelectTagDialog,
    newTagName: state.selectTagDialogState.newTagName,
    tags: state.selectTagDialogState.tags,
    newTagValidationErrors: state.selectTagDialogState.newTagValidationErrors,
    isLoading: state.selectTagDialogState.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onAddTag: async (newTagName, purchaseId, oldTagId, enqueueSnackbar) => {
      return PurchaseTagsService.addTag({name:newTagName})
      .then((response) => {
        if (response && response.status >= 400 && response.errors) {
          const action = {
            type: 'NEW_TAG_ADD_ERROR_OCCURED',
            newTagValidationErrors: response.errors['Name']
          };
          dispatch(action);
        }
        else {
          PurchaseTagsService.getTags()
            .then((tags) => {
              const action = {
                type: 'UPDATE_TAG_LIST',
                tags: tags
              };
              dispatch(action);
            })
            .catch(() => {
              enqueueSnackbar(
                'Произошла ошибка при обновлении списка папок избранного',
                { 
                  variant: 'error'
                });
            })
        }
      })
      .catch(error => {
        enqueueSnackbar(
          'Произошла ошибка при добавлении папки избранного',
          { 
            variant: 'error'
          });
      })
    },
    onNewTagChanged: (value) => {
      const action = {
        type: 'NEW_TAG_FIELD_CHANGED',
        newTagName: value
      };
      dispatch(action);
    },
    onAddPurchaseTag: (purchaseId, newTag, oldTagId, enqueueSnackbar) => {
      const action = {
        type: 'SET_IS_LOADING'
      }
      dispatch(action);

      if (purchaseId) {
        PurchaseTagsService.setPurchaseTag({ purchaseId: purchaseId, oldTagId: oldTagId, newTagId: newTag.id })
          .then(() => {
            const action = {
              type: 'TOGGLE_PURCHASE_TAG',
              newTag: newTag
            }
            dispatch(action);
          })
          .catch(error => {
            enqueueSnackbar(
              'Произошла ошибка при добавлении закупки в избранное',
              { 
                variant: 'error'
              });
          })
      }
    },
    onRemovePurchaseTag: (purchaseId, newTag, enqueueSnackbar) => {
      const action = {
        type: 'SET_IS_LOADING'
      }
      dispatch(action);

      PurchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: newTag.id})
        .then(() => {
          const action = {
            type: 'TOGGLE_PURCHASE_TAG',
            newTag: newTag
          };
          dispatch(action);
        })
        .catch(() => {
          enqueueSnackbar(
            'Произошла ошибка при удалении закупки из избранного',
            { 
              variant: 'error'
            });
        })
    },
    onClose: () => {
      const action = {
        type: 'CLOSE_TAG_DIALOG'
      };
      dispatch(action);
    }
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SelectTagDialog));