import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, MenuItem, Typography, Box } from '@mui/material';

export const PurchaseSearchResultSortingPanel = (props) => {

	const dispatch = useDispatch();
	const sortBy = useSelector((state) => state.searchPurchasesState.sortBy);
	const ascending = useSelector((state) => state.searchPurchasesState.ascending);

	const options = [
		{ title: 'Дата публикации по возрастанию', value: '{"sortBy":"purchaseCreateAt","ascending":true}' },
		{ title: 'Дата публикации по убыванию', value: '{"sortBy":"purchaseCreateAt","ascending":false}'},
		{ title: 'Дата окончания подачи заявок по возрастанию', value: '{"sortBy":"purchaseExpirationAt","ascending":true}'},
		{ title: 'Дата окончания подачи заявок по убыванию', value: '{"sortBy":"purchaseExpirationAt","ascending":false}'},
		{ title: 'Цена по возрастанию', value: '{"sortBy":"price","ascending":true}'},
		{ title: 'Цена по убыванию', value: '{"sortBy":"price","ascending":false}'}
	]

    return (
        <React.Fragment>
			<Box sx={{display:'flex', flexDirection: 'column'}}>
				<Typography align='right' className='search_result_amount_title'>
					Сортировка:
				</Typography>
				<Select
					className='search_result_select'
					variant="standard" 
					size="small" 
					value={JSON.stringify({ sortBy: sortBy, ascending: ascending })}
					onChange={(event) => {
						const value = event.target.value;
						const action = {
							type: 'SORT_BY_CHANGED',
							value: value
						}
						dispatch(action);
					}}
				>
					{ options.map((option, key) => 
						<MenuItem value={option.value} key={key}>{option.title}</MenuItem>
					)}
				</Select>
			</Box>
		</React.Fragment>
    )
}
