import React, { Component } from 'react';
import SelectTagDialog from './SelectTagDialog';
import PurchaseCard from './PurchaseCard';
import { PurchaseSearchResultSortingPanel } from './PurchaseSearchResultSortingPanel'

import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import { DateTime } from 'luxon';

// MUI
import Pagination from '@mui/material/Pagination';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import { CircularProgress, Box, Stack } from '@mui/material';
import { withSnackbar } from 'notistack';
import { PurchaseSearchResultTips } from './PurchaseSearchResultTips';

class PurchaseSearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenUploadSettingsModal: false,
      isLoadingUploadSettings: false
    };    

    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(event, page) {
    //При смене страницы вызывается функция переданная в пропсы от родительского компонента (purchaseSearchPanel или Templates)
    //Сделано так чтобы PurchaseSearchResult мог запускать поиск на конкретном компоненте вызвавшем его - без передачи стейта в этот компонент
    this.props.onPageSearch(page);
  }

  getSourcePlatform = (purchase) => {
    if (purchase.sourcePlatform) {
      return purchase.sourcePlatform.name;
    } else {
      return null
    }
  }  

  getLotInfo = (purchase) => {
    let value = "";
    if (purchase.lotNumber < 2) {
      return null;
    }
    if (purchase.lotNumber > 1 && purchase.lotNumber < 5) {
      value = purchase.lotNumber + " лота"
    }
    if (purchase.lotNumber > 4) {
      value = purchase.lotNumber + " лот"
    }

    return <div className='lots_count'>{value}</div>
  }
  
  getLaw = (purchase) => {
    switch(purchase.law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      default:
        return 'Не определено';
    }    
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }  

  getLocalFormatDate = (inputDate) => {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }

  getDateDiff = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const currentDate = DateTime.now();
    let result = "";
    let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();

    if (dateDiff.days === 1 || (dateDiff.Days === 0 && dateDiff.hours === 1)) {
      result = result.concat("Остался ")
    }
    else {
      result = result.concat("Осталось ");
    }

    if (dateDiff.days > 0) {
      result = result.concat(dateDiff.days+" д. ");
    }
    if (dateDiff.hours > 1) {
      result = result.concat(parseInt(dateDiff.hours)+" ч. ");
    }
    if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
      result = result.concat("менее часа");
    }
    if (dateDiff.hours < 0){
      result = "";
    }

    return result;
  }

  getStage = (purchase) => {
    switch(purchase.stage) {
      case 1:
        const dateMsk = this.getFormatDate(purchase.purchaseExpirationAt);
        const dateLocal = this.getLocalFormatDate(purchase.purchaseExpirationAt);

        return (
          <React.Fragment>
            <div className='zakupka_block_header'>Подача заявок до: </div>
            <Typography>{dateMsk} (по МСК)</Typography>
            {dateMsk != dateLocal ? <Typography>{dateLocal} (по местному)</Typography> : null}
            <Typography>{this.getDateDiff(purchase.purchaseExpirationAt)}</Typography>
          </React.Fragment>
        );
      case 2:
        return 'Работа комиссии';
      case 3:
        return 'Размещение завершено';
      case 4:
        return 'Размещение отменено';
      case 5:
        return 'Торги не состоялись';
      case 6: 
        return 'Торги запланированы';
      case 7: 
        return 'На исполнении';
      default:
        return 'Не определено';
    }
  }

  render() {
    const purchases = this.props.purchases;

    if (this.props.isLoaded) {
      return (
        <Box sx={{ display: 'flex' }} className='search_result_spinner'>
          <CircularProgress />
        </Box>
      );
    } else {
      return (
        <React.Fragment>
          <SelectTagDialog/>
          {(() => {
            if (purchases.length > 0) {
              return (
                <>
                  <Box sx={{ display: 'flex',  width: '100%', justifyContent: 'space-between', mb: 2 }} className='search_result_container_panel'>
                    <Box
                      sx={{ ml:1 }}
                      className='search_result_container_panel_inner'
                    >
                      <Box>
                        {this.props.total > 0 
                          ? <Box>
                              <Typography className='search_result_amount_title'>
                                Количество:
                              </Typography>
                              <Typography sx={{color:'black'}} className='search_result_amount_value'>
                                {this.props.total >= 10000 ? 'более 10000' : this.props.total}
                              </Typography>
                            </Box>
                          : null}
                      </Box>
                    </Box>
                    <Box sx={{ mr:1 }} className='search_result_container_panel_inner'>
                      <PurchaseSearchResultSortingPanel />
                    </Box>
                  </Box>
                  {purchases.map((purchase, key) =>
                    <PurchaseCard purchase={purchase} key={key}/>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Stack spacing={2}>
                      <Pagination count={this.props.pageCount ? this.props.pageCount : 1} page={this.props.pageNumber ? this.props.pageNumber : 1} onChange={this.handlePaginationChange} />
                    </Stack>
                  </Box>
                  
                </>
              );
            } else {
              if (this.props.searchCompleted === true) {
                return ( 
                  <>
                    <div className='empty-search-results'>
                      К сожалению, по заданным ключевым словам мы не нашли подходящих тендеров.
                    </div>
                  </>
                )
              }
            }
          })()}
          <PurchaseSearchResultTips />
        </React.Fragment>);
    }
  }
}

function mapStateToProps(state) {
    return {
      purchases: state.searchPurchasesState.purchases,
      pageNumber: state.searchPurchasesState.pageNumber,
      pageSize: state.searchPurchasesState.pageSize,
      total: state.searchPurchasesState.total,
      pageCount: state.searchPurchasesState.pageCount,
      isLoaded: state.searchPurchasesState.isLoaded,
      uploadFields: state.searchPurchasesState.uploadFields,
      uploadPurchaseIds: state.searchPurchasesState.uploadPurchaseIds,
      searchCompleted: state.searchPurchasesState.searchCompleted
    };
}

function mapDispatchToProps(dispatch) {
  return {
    onPurchaseTagRemoved: (purchaseId, tagId, enqueueSnackbar) => {
      PurchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: tagId})
        .then(() => {
          const purchaseIds = [purchaseId];
          PurchaseTagsService.getPurchaseTags(purchaseIds)
            .then(result => {
              const action = {
                type: 'REFRESH_PURCHASE_TAGS',
                purchase: result[0]
              };
              dispatch(action);
            })
            .catch(error => {
              enqueueSnackbar(
                'Произошла ошибка при получении избранного для закупки',
                { 
                  variant: 'error'
                });
            }) 
        })
        .catch(error => {
          enqueueSnackbar(
            'Произошла ошибка при удалении закупки из избранного',
            { 
              variant: 'error'
            });
        })  
    },
    onPurchaseTagAddCalled: (purchase, enqueueSnackbar) => {
      PurchaseTagsService.getTags().then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          tags: result
        };
        dispatch(action);
      })
      .catch(error => {
        enqueueSnackbar(
          'Произошла ошибка при получении списка папок избранного',
          { 
            variant: 'error'
          });
      })  
    },
    onPurchaseTagChangeCalled: (purchase, tagId, enqueueSnackbar) => {
      PurchaseTagsService.getTags().then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          selectedTagId: tagId,
          tags: result
        };
        dispatch(action);
      })
      .catch(error => {
        enqueueSnackbar(
          'Произошла ошибка при получении списка папок избранного',
          { 
            variant: 'error'
          });
      })
    }
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(PurchaseSearchResult));
