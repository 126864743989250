import Service from "../service";

class SettlementService extends Service {
  async search(keyword) {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify({
          keyword: keyword,
          size: 5
      })
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_SETTLEMENT_API_URI : 'http://localhost:5554';
    // try {
    //   const response = await fetch(`${baseURL}/api/kladr/search`, requestOptions);
    //   const settlements = await response.json();
    //   return settlements;
    // } catch (error) {
    //   console.log('SETTLEMENT SERVICE | SEARCH | ERROR: ', error);
    // }
    return this.authenticatedFetch(`${baseURL}/api/kladr/search`, requestOptions);
  }
}

export default new SettlementService();