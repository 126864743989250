import React, {useEffect, useState, useRef} from "react"
import {Link, Outlet} from "react-router-dom";
import {ListItemButton} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';

import purchasesInWorkService from "../../services/purchasesInWorkService";
import {CompanySelect} from "../company-management/company-select-dropdown";
import invoicesService from "../../services/invoicesService";

import {SignOutButton} from "../authentication/sign-out-button";
import LiveChatUserRegistration from "../live-chat/user-registration";
import { useOutsideClickListener } from "../../hooks/useOutsideClickListener";

const InvoicesListItem = (props) => {
    return (
        <ListItemButton className="menu_link secondary_item first_child"
                        component={Link}
                        to={"/invoices"}
                        selected={props.selected}
        >
            <span className="menu_icon invoices_icon"></span>
            Счета
            {props.activeInvoicesCount
                ? <span className="current_count">{props.activeInvoicesCount}</span>
                : null
            }
        </ListItemButton>
    );
}

const PurchaseInWorkListItem = (props) => {
    const isDeviceMobile = window.matchMedia('(max-width: 1024px');
    return (
        <ListItemButton className="menu_link"
                        component={Link}
                        to={"/purchases-in-work"}
                        selected={props.selected}
        >
            <span className="menu_icon work_icon"></span>
            {isDeviceMobile.matches ? 'В работе' : 'Закупки в работе'}
            {props.activePurchasesInWorkCount
                ? <span className="current_count">{props.activePurchasesInWorkCount}</span>
                : null
            }
        </ListItemButton>
    );
}

const UserInfo = ({phone_number, email}) => {
    const [userTabExpanded, setUserTabExpanded] = useState(false);
    const ref = useRef(null);

    useOutsideClickListener(ref, userTabExpanded, () => { setUserTabExpanded(false)}); //ref, condition, action

    return (
        <div className={`account_menu ${userTabExpanded ? 'act' : ''}`} ref={ref}>
            <div className="account_menu_btn"
                    onClick={() => setUserTabExpanded(!userTabExpanded)}></div>
            <div className="account_menu_dropdown">
                <div className="account_menu_user">
                    <div className="account_menu_avatar"></div>
                    <div className="account_menu_userinfo">
                        <div className="account_menu_phone">
                            {phone_number}
                        </div>
                        <div className="account_menu_username">
                            {email}
                        </div>
                    </div>
                </div>
                <div className="account_menu_dropdown_links">
                    <SignOutButton/>
                </div>
            </div>
        </div>
    )
}

export const MainMenu = (props) => {
    const [modalMenu, setModalMenu] = useState(false)
    const [tabletMenu, setTabletMenu] = useState(false)

    const dispatch = useDispatch();
    const currentPage = useSelector((state) => state.pagesState.currentPage);
    const activePurchasesInWorkCount = useSelector((state) => state.mainMenuState.activePurchasesInWorkCount);
    const activeInvoicesCount = useSelector((state) => state.mainMenuState.activeInvoicesCount);
    const currentCompany = useSelector((state) => state.profileState.currentCompany);
    const currentUserInfo = useSelector((state) => state.profileState.currentUserInfo);
    
    useEffect(() => {
        setModalMenu(false)
        setTabletMenu(false)
    }, [currentPage])
    
    useEffect(() => {
        if (currentCompany && currentCompany.Id)
        {
            purchasesInWorkService.getActivePurchasesInWork(currentCompany.Id)
                .then((result) => {
                    const action = {
                        type: 'ACTIVE_PURCHASES_IN_WORK_LOADED',
                        activePurchasesInWorkCount: result
                    };
                    dispatch(action);
                });

            invoicesService.getActiveInvoicesCount(currentCompany.Id, false)
                .then((result) => {
                    const action = {
                        type: 'ACTIVE_INVOICES_LOADED',
                        activeInvoicesCount: result
                    };
                    dispatch(action);
                })
        }
    }, [currentCompany, dispatch]);

    return (
        <React.Fragment>
	    <LiveChatUserRegistration />
            {/*<MobileDeviceCheck />*/}
            <div className="main_container">
                <div className={`menu ${tabletMenu ? 'menu_opened' : ''}`}>
                    <div className="menu_inner_container">
                        <a href="/" className="menu_logo">
                            <img src="\logo.svg" alt="Эксперт Центр" title="Эксперт Центр"/>
                        </a>

                        <span
                            onClick={() => setTabletMenu(false)}
                            className="menu_icon_big menu_icon_white"></span>
                    </div>
                    <div className="nav_menu">
                        <ListItemButton className="menu_link"
                                        component={Link}
                                        to={"/"}
                                        selected={currentPage === 'main'}
                        >
                            <span className="menu_icon home_icon"></span>
                            Главная
                        </ListItemButton>

                        <ListItemButton className="menu_link"
                                        component={Link}
                                        to={"/search"}
                                        selected={currentPage === 'search'}
                        >
                            <span className="menu_icon search_icon"></span>
                            Поиск
                        </ListItemButton>
                        <ListItemButton className="menu_link"
                                        component={Link}
                                        to={"/selected"}
                                        selected={currentPage === 'selected'}
                        >
                            <span className="menu_icon selected_icon"></span>
                            Избранные закупки
                        </ListItemButton>

                        <PurchaseInWorkListItem selected={currentPage === 'purchases-in-work'}
                                                activePurchasesInWorkCount={activePurchasesInWorkCount}/>
                        <InvoicesListItem selected={currentPage === 'invoices'}
                                          activeInvoicesCount={activeInvoicesCount}/>

                        <ListItemButton className="menu_link"
                                        component={Link}
                                        to={"/mailings"}
                                        selected={currentPage === "mailings"}
                        >
                            <span className="menu_icon mailings_icon"></span>
                            Рассылки
                        </ListItemButton>

                        <ListItemButton className="menu_link"
                                        component={Link}
                                        to={"/settings"}
                                        selected={currentPage === 'settings'}
                        >
                            <span className="menu_icon settings_icon"></span>
                            Настройки
                        </ListItemButton>
                    </div>
                    <div className="menu_footer">
                        <div className="menu_footer_policy">
                            <a target="_blank" rel="noreferrer"
                               href={process.env.REACT_APP_PORTAL_IDENTITY_FRONTEND_URI + "/personal-data-policy.html"}>Политика
                                обработки персональных данных</a>
                        </div>
                        <div className="menu_footer_logo_text">
                            © {DateTime.now().year ?? ''} Эксперт Центр.
                        </div>
                    </div>
                </div>

                <div className="menu_mobile">
                    <ListItemButton className="menu_link"
                                    component={Link}
                                    to={"/"}
                                    selected={currentPage === 'main'}
                    >
                        <span className="menu_icon home_icon"></span>
                        Главная
                    </ListItemButton>

                    <ListItemButton className="menu_link"
                                    component={Link}
                                    to={"/search"}
                                    selected={currentPage === 'search'}
                    >
                        <span className="menu_icon search_icon"></span>
                        Поиск
                    </ListItemButton>

                    <PurchaseInWorkListItem selected={currentPage === 'purchases-in-work'}
                                            activePurchasesInWorkCount={activePurchasesInWorkCount}/>

                    <ListItemButton className="menu_link"
                                    component={Link}
                                    to={"/selected"}
                                    selected={currentPage === 'selected'}
                    >
                        <span className="menu_icon selected_icon"></span>
                        Избранные
                    </ListItemButton>

                    <ListItemButton
                        className="menu_link menu_link_last_hover" onClick={() => setModalMenu(prev => !prev)}>
                        <span className="menu_icon more_icon"></span>
                        Ещё
                    </ListItemButton>


                    {modalMenu ?
                        <div className="dropdown_menu">
                            <InvoicesListItem selected={currentPage === 'invoices'}
                                              activeInvoicesCount={activeInvoicesCount}/>

                            <ListItemButton className="menu_link secondary_item"
                                            component={Link}
                                            to={"/mailings"}
                                            selected={currentPage === "mailings"}
                            >
                                <span className="menu_icon mailings_icon"></span>
                                Рассылки
                            </ListItemButton>

                            <ListItemButton className="menu_link secondary_item"
                                            component={Link}
                                            to={"/settings"}
                                            selected={currentPage === 'settings'}
                            >
                                <span className="menu_icon settings_icon"></span>
                                Настройки
                            </ListItemButton>

                            <div className="menu_footer">
                                <div className="menu_footer_policy">
                                    <a target="_blank" rel="noreferrer"
                                       href={process.env.REACT_APP_PORTAL_IDENTITY_FRONTEND_URI + "/personal-data-policy.html"}>Политика
                                        обработки персональных данных</a>
                                </div>
                            </div>
                        </div> :
                        null
                    }
                </div>

                <div className="right_container">
                    <div className="header">
                        <div className="header_left">
                            <span
                                onClick={() => setTabletMenu(true)}
                                className="menu_icon_big menu_icon_black"></span>

                            <a href="/" className="menu_logo_header">
                                <img src="\logo.svg" alt="Эксперт Центр" title="Эксперт Центр"/>
                            </a>
                        </div>

                        <div className="header_inner">
                            <CompanySelect/>
                            <UserInfo 
                                phone_number={currentUserInfo ? currentUserInfo.phone_number : null}
                                email={currentUserInfo ? currentUserInfo.email : null}
                            />
                        </div>
                    </div>
                    <div component="main" className="main_area">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
