//Методы определения поставщика
export const auctionTypes = [
    {
        id: 1,
        name: "Аукцион"
    },
    {
        id: 2,
        name: "Запрос предложений"
    },
    {
        id: 3,
        name: "Запрос котировок"
    },
    {
        id: 4,
        name: "Конкурс"
    },
    {
        id: 5,
        name: "Закупка у единственного поставщика"
    },
    {
        id: 6,
        name: "Закупка иным способом"
    },
    {
        id: 7,
        name: "Предварительный квалификационный отбор"
    },
]
