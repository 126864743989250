import React, { useState } from "react"
import { Typography, IconButton, Stack, Menu, MenuItem, Tooltip } from "@mui/material"
import MoreIcon from '@mui/icons-material/MoreVert';

import { PurchaseInWorkComplaintDialog } from "./purchase-in-work-complaint";
import { PurchaseInWorkCancelRequestDialog } from './purchase-in-work-cancel-request';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportIcon from '@mui/icons-material/Report';

export const PurchaseInWorkItemOptionsButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openComplaints, setOpenComplaints] = useState(false);
    const [openCancelRequest, setOpenCancelRequest] = useState(false);

    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleComplaintsButton = () => {
        setAnchorEl(null);
        setOpenComplaints(true);
    }

    const handleCancelRequestButton = () => {
        setAnchorEl(null);
        setOpenCancelRequest(true);
    }

    const isPurchaseInWorkBeforeSigning = props.purchaseInWorkStage < 500;

    return (
        <React.Fragment>
            <PurchaseInWorkComplaintDialog openDialog={openComplaints} onClose={() => setOpenComplaints(false)} purchaseInWorkId={props.purchaseInWorkId}/>
            <PurchaseInWorkCancelRequestDialog openDialog={openCancelRequest} onClose={() => setOpenCancelRequest(false)} purchaseInWorkId={props.purchaseInWorkId}/>
            <IconButton
                size="large"
                edge="end"
                onClick={handleClick}
                className="appbar_options_button"
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleComplaintsButton}>
                    <Stack direction="row" alignItems="center">
                        <ReportIcon className="menu_icon"/>
                        <Typography>Пожаловаться</Typography>
                    </Stack>
                </MenuItem>
                {
                    isPurchaseInWorkBeforeSigning
                    ?
                        props.hasActiveCancelRequest
                        ?
                            <Tooltip title="Вы уже отправили запрос на отзыв данной закупки из работы">
                                <span>
                                    <MenuItem disabled={true} onClick={handleCancelRequestButton}>
                                        <Stack direction="row" alignItems="center">
                                        <CancelIcon className="menu_icon" />
                                            <Typography>Отзыв закупки из работы</Typography>
                                        </Stack>
                                    </MenuItem>
                                </span>
                            </Tooltip>
                        :
                            <MenuItem onClick={handleCancelRequestButton}>
                                <Stack direction="row" alignItems="center">
                                    <CancelIcon className="menu_icon" />
                                    <Typography>Отзыв закупки из работы</Typography>
                                </Stack>
                            </MenuItem>
                    :
                        null
                }
                
            </Menu>
		</React.Fragment>
    )
}
