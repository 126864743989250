import React, { Component } from 'react';
import { NumberFormatCustom } from '../number-format-custom';

// MUI:
import {
    Card, CardContent, Box, CardHeader, Table, TableContainer, TableHead, TableCell, TableRow, TableBody, Typography
} from '@mui/material';

class PurchaseLotDetails extends Component {
    render() {
        return (
            <React.Fragment>
                <div className='detail_block lots_block'>
                    <div className='detail_block_header'>
                        Список лотов
                    </div>
                    <TableContainer className='lots_table'>
                        <Table>
                            {window.innerWidth <= 480 ?
                                <>
                                    {this.props.lots.map((lot, key) =>
                                        <TableBody key={key}>
                                            <tr>
                                                <th>Наименование</th>
                                                <td>{lot.title}</td>
                                            </tr>
                                            <tr>
                                                <th>ОКПД2/КТРУ</th>
                                                <td>
                                                    {lot.okpdCodes.map((okpd, key) =>
                                                        <div key={key}>
                                                            {okpd.code} {okpd.name}
                                                        </div>
                                                    )}
                                                    {lot.ktruCodes.map((ktru, key) =>
                                                        <div key={key}>
                                                            {ktru.code} {ktru.name}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Стоимость</th>
                                                <td>
                                                    { lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Количество</th>
                                                <td>{lot.quantity}</td>
                                            </tr>
                                            {lot.lotItemsType === 1
                                                ?
                                                <React.Fragment>
                                                    <TableRow sx={{ "& td": { border: 0 } }}>
                                                        <TableCell colSpan={4}>
                                                            <Box>
                                                                <Typography variant="caption" component="div" align="center">
                                                                    <b>Составляющие лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    {lot.lotItems.map((lotItem, key) =>
                                                        <React.Fragment key={key}>
                                                            <TableRow sx={{ "& td": { border: 0 } }}>
                                                                <th>Наименование</th>
                                                                <TableCell>{lotItem.title}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <th>ОКПД2/КТРУ</th>
                                                                <TableCell>
                                                                    {lotItem.okpdCodes.map((okpd, key) =>
                                                                        <div key={key}>
                                                                            {okpd.code} {okpd.name}
                                                                        </div>
                                                                    )}
                                                                    {lotItem.ktruCodes.map((ktru, key) =>
                                                                        <div key={key}>
                                                                            {ktru.code} {ktru.name}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <th>Стоимость</th>
                                                                <TableCell>{ lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <th>Количество</th>
                                                                <TableCell>{lotItem.quantity}</TableCell>
                                                            </TableRow>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                                : null
                                            }
                                            {lot.lotItemsType === 2
                                                ?
                                                <React.Fragment>
                                                    <TableRow sx={{ "& td": { border: 0 } }}>
                                                        <TableCell colSpan={4}>
                                                            <Box>
                                                                <Typography variant="caption" component="div" align="center">
                                                                    <b>Альтернативы лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        lot.lotItems.map((lotItem, key) =>
                                                            <>
                                                                <TableRow key={key} sx={{ "& td": { border: 0 } }}>

                                                                    <th>Наименование</th>
                                                                    <TableCell>{lotItem.title}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <th>ОКПД2/КТРУ</th>
                                                                    <TableCell>
                                                                        {lotItem.okpdCodes.map((okpd, key) =>
                                                                            <div key={key}>
                                                                                {okpd.code} {okpd.name}
                                                                            </div>
                                                                        )}
                                                                        {lotItem.ktruCodes.map((ktru, key) =>
                                                                            <div key={key}>
                                                                                {ktru.code} {ktru.name}
                                                                            </div>
                                                                        )}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <th>Стоимость</th>
                                                                    <TableCell>{ lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <th>Количество</th>
                                                                    <TableCell>{lotItem.quantity}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        )}
                                                </React.Fragment>
                                                : null
                                            }
                                        </TableBody>)}
                                </>
                                :
                                <>
                                    <TableHead>
                                        <tr className='lots_table_header'>
                                            <th>Наименование</th>
                                            <th style={{ width:"35%" }}>ОКПД2/КТРУ</th>
                                            <th style={{ width:"10%" }}>Стоимость</th>
                                            <th>Количество</th>
                                        </tr>
                                    </TableHead>
                                    {this.props.lots.map((lot, key) =>
                                        <TableBody key={key}>
                                            <tr>
                                                <td>{lot.title}</td>
                                                <td>
                                                    {lot.okpdCodes.map((okpd, key) =>
                                                        <div key={key}>
                                                            {okpd.code} {okpd.name}
                                                        </div>
                                                    )}
                                                    {lot.ktruCodes.map((ktru, key) =>
                                                        <div key={key}>
                                                            {ktru.code} {ktru.name}
                                                        </div>
                                                    )}
                                                </td>
                                                <td> { lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}</td>
                                                <td className='lot_quantity'>{lot.quantity}</td>
                                            </tr>
                                            {lot.lotItemsType === 1
                                                ?
                                                <React.Fragment>
                                                    <TableRow sx={{ "& td": { border: 0 } }}>
                                                        <TableCell colSpan={4}>
                                                            <Box>
                                                                <Typography variant="caption" component="div" align="center">
                                                                    <b>Составляющие лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    {lot.lotItems.map((lotItem, key) =>
                                                        <TableRow key={key} sx={{ "& td": { border: 0 } }}>
                                                            <TableCell>{lotItem.title}</TableCell>
                                                            <TableCell>
                                                                {lotItem.okpdCodes.map((okpd, key) =>
                                                                    <div key={key}>
                                                                        {okpd.code} {okpd.name}
                                                                    </div>
                                                                )}
                                                                {lotItem.ktruCodes.map((ktru, key) =>
                                                                    <div key={key}>
                                                                        {ktru.code} {ktru.name}
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                            <TableCell>{ lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}</TableCell>
                                                            <TableCell>{lotItem.quantity}</TableCell>
                                                        </TableRow>
                                                    )}
                                                </React.Fragment>
                                                : null
                                            }
                                            {lot.lotItemsType === 2
                                                ?
                                                <React.Fragment>
                                                    <TableRow sx={{ "& td": { border: 0 } }}>
                                                        <TableCell colSpan={4}>
                                                            <Box>
                                                                <Typography variant="caption" component="div" align="center">
                                                                    <b>Альтернативы лота:</b>
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        lot.lotItems.map((lotItem, key) =>
                                                            <TableRow key={key} sx={{ "& td": { border: 0 } }}>
                                                                <TableCell>{lotItem.title}</TableCell>
                                                                <TableCell>
                                                                    {lotItem.okpdCodes.map((okpd, key) =>
                                                                        <div key={key}>
                                                                            {okpd.code} {okpd.name}
                                                                        </div>
                                                                    )}
                                                                    {lotItem.ktruCodes.map((ktru, key) =>
                                                                        <div key={key}>
                                                                            {ktru.code} {ktru.name}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell>{ lot.price ? <><NumberFormatCustom value={lot.price} displayType="text" /> ₽</> : '—'}</TableCell>
                                                                <TableCell>{lotItem.quantity}</TableCell>
                                                            </TableRow>
                                                        )}
                                                </React.Fragment>
                                                : null
                                            }
                                        </TableBody>
                                    )}
                                </>
                            }
                        </Table>
                    </TableContainer>
                </div>
            </React.Fragment>
        )
    }
}

export default PurchaseLotDetails;
