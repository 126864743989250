import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import mailingsService from "../../services/mailingsService"
import { MailingsItem } from "./mailings-item";
import { CircularProgress, Tooltip, IconButton, Box, Link } from "@mui/material"
import { MailingsEditModal } from "./mailings-edit-modal";
import noContentImg from "../../img/icons/empty-mailbox.png"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorIcon from '@mui/icons-material/Error';

export const MailingsList = (props) => {  
    const dispatch = useDispatch();
    const currentCompany = useSelector((state) => state.profileState.currentCompany);
    const mailings = useSelector((state) => state.mailingsState.mailings);
    const isLoading = useSelector((state) =>  state.mailingsState.isLoading);
    const showMailingEditModal = useSelector((state) => state.mailingsState.showMailingEditModal);
    const currentEditedMailing = useSelector((state) => state.mailingsState.currentEditedMailing);
    const emailConfirmed = useSelector((state) => state.profileState.currentUserInfo?.email_confirmed);

    useEffect(() => {
        const pageAction = {
            type: 'CURRENT_PAGE_CHANGED',
            currentPage: 'mailings'
        };
        dispatch(pageAction);
    }, [])

    useEffect(() => {
        if (currentCompany) {
            refreshMailings();
        }
    },[currentCompany])

    const refreshMailings = () => {
        if (currentCompany)
        {
            const action = {
                type: 'MAILINGS_LOADING_STARTED'
            };
            dispatch(action);

            if (currentCompany.Id) {
                mailingsService.getMailings(currentCompany.Id)
                    .then(result => {
                        const action = {
                            type: 'MAILINGS_LOADING_COMPLETED',
                            mailings: result
                        };
                        dispatch(action);
                    })            
                    .catch(error => {
                        const action = {
                            type: 'MAILINGS_LOADING_FAILED'
                        };
                        dispatch(action);
                    })
            } else {
                const action = {
                    type: 'MAILINGS_LOADING_COMPLETED',
                    mailings: []
                };
                dispatch(action);
            }
        }  
    }

    const onCloseModal = () => {
        const action = {
            type: 'MAILING_EDIT_FINISHED'
        };
        dispatch(action);
    }

    const onSaveModal = () => {
        const action = {
            type: 'MAILING_EDIT_FINISHED'
        };
        dispatch(action);
        refreshMailings();
    }

    const onCreateMailing = () => {
        const action = {
            type: 'MAILING_EDIT_STARTED'
        };
        dispatch(action);
    }

    return (
        <React.Fragment>
            <MailingsEditModal open={showMailingEditModal} mailing={currentEditedMailing} onClose={onCloseModal} onSave={onSaveModal} />
            {
                isLoading !== true 
                ?
                    mailings.length > 0
                    ? 
                        <Box className="one_mailing_correct">
                            {
                                <Box className="one_mailing_header">
                                    <span className="one_mailing_name_header_mobile">
                                        Рассылки 
                                    </span>
                                    <span className="one_mailing_name_header">
                                        Наименование 
                                    </span>
                                    <span className="one_mailing_description_header">
                                        Ключевые слова
                                    </span>
                                    <span className="one_mailing_description_header">
                                        Слова исключения
                                    </span>
                                    <span className="one_mailing_buttons_header">
                                        {emailConfirmed === true
                                            ?
                                                <Tooltip sx={{color:'black'}} title="Добавить новую рассылку">
                                                    <IconButton onClick={onCreateMailing}>
                                                        <AddCircleOutlineIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                            :
                                                <Tooltip sx={{color:'#e0c53a'}} title="Возможность добавлять и редактировать рассылки вручную появится только при подтвержденном email адресе на вашем аккаунте">
                                                    <IconButton>
                                                        <ErrorIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Tooltip>
                                        }
                                    </span>
                                </Box>
                            }
                            {
                                <Box className="mailings_box">
                                    {mailings.map((mailing, key) => <MailingsItem mailing={mailing} key={key} onRefresh={refreshMailings}  />)}
                                </Box>
                            }
                        </Box>
                    :
                        <Box className="no_item_block">
                            <Box className="no_item_block_icon">
                                <img src={noContentImg}/>
                            </Box>
                            <Box className="no_item_block_text_main" sx={{mt:2}}>
                                Упс... кажется на данный момент у вас нет ни одной рассылки для выбранной компании.
                            </Box>
                            <Box className="no_item_block_text_second">
                                При заключении с нами договора вы сможете просматривать и корректировать рассылки, которые настроит вам ваш персональный менеджер.
                                <br/>
                                {
                                    currentCompany?.Id && emailConfirmed
                                    ?
                                        //если нет прикрепленной компании не показываем ссылку на добавление
                                        currentCompany.Company
                                        ?
                                            <>
                                                Также вы можете самостоятельно <Link href="#" onClick={onCreateMailing}>добавить</Link> себе рассылку.
                                            </>
                                        :
                                            <></>
                                    :
                                        <>
                                            Также вы можете самостоятельно создать себе рассылку, но перед этим вам необходимо добавить компанию и подтвердить свой e-mail в настройках.
                                        </>
                                }
                            </Box>
                        </Box>
                :
                <CircularProgress />
            }

        </React.Fragment>
    )
}