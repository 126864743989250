import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import purchaseTagsService from '../../services//search-purchases-services/PurchaseTagsService';
import { DateTime } from 'luxon';
import htmlParse from 'html-react-parser';
import { Link as RouterLink } from "react-router-dom";

// MUI
import Card from '@mui/material/Card';
import { connect } from 'react-redux';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link, Rating, Chip, Stack, Grid, Button, Breadcrumbs, Divider } from '@mui/material';
import { withSnackbar } from 'notistack';
import { LiveChatEventButton } from "../live-chat/live-chat-event-button";

class PurchaseCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
			purchase: this.props.purchase
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.purchase.tags && this.props.purchase.tags !== prevProps.purchase.tags) {
      this.setState({purchase: this.props.purchase});
    }
  
    if (this.props.tagDialogSelectedPurchase && this.props.tagDialogSelectedPurchase.id === this.state.purchase.id && this.props.tagDialogSelectedPurchase.tags !== this.state.purchase.tags) {
      this.setState({ purchase: { ...this.props.purchase, tags: this.props.tagDialogSelectedPurchase.tags } })
    }
  }

  getSourcePlatform = (purchase) => {
    if (this.props.purchase.sourcePlatform) {
      return this.props.purchase.sourcePlatform.name;
    } else {
      return null
    }
  }  

  getLotInfo = (purchase) => {
    let value = "";
    if (this.props.purchase.lotNumber < 2) {
      return null;
    }
    if (this.props.purchase.lotNumber > 1 && this.props.purchase.lotNumber < 5) {
      value = this.props.purchase.lotNumber + " лота"
    }
    if (this.props.purchase.lotNumber > 4) {
      value = this.props.purchase.lotNumber + " лот"
    }

    return <>
      <CardContent className='purchase_chip_container'>
        <Stack direction="row" spacing={1}>
          <Chip label={value} />
        </Stack>
      </CardContent>
    </>
  }
  
  getLaw = (purchase) => {
    switch(this.props.purchase.law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      case 4: 
        return 'ПП РФ 615';
      default:
        return 'Не определено';
    }    
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }  

  getLocalFormatDate = (inputDate) => {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.setZone(localTimezone).toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }

  getDateDiff = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    
    const currentDate = DateTime.now();
    let result = "";
    let dateDiff = date.diff(currentDate, ['days', 'hours']).toObject();

    if (dateDiff.days === 1 || (dateDiff.Days === 0 && dateDiff.hours === 1)) {
      result = result.concat("Остался ")
    }
    else {
      result = result.concat("Осталось ");
    }

    if (dateDiff.days > 0) {
      result = result.concat(dateDiff.days+" д. ");
    }
    if (dateDiff.hours > 1) {
      result = result.concat(parseInt(dateDiff.hours)+" ч. ");
    }
    if (dateDiff.days === 0 && dateDiff.hours < 1 && dateDiff.hours > 0) {
      result = result.concat("менее часа");
    }
    if (dateDiff.hours < 0){
      result = "";
    }

    return result;
  }

  getStage = (purchase) => {
    switch(this.props.purchase.stage) {
      case 1:
          if (!this.props.purchase.purchaseExpirationAt) {
            return <Typography className='purchase_time_header'>Подача заявок</Typography>;
          }

          const dateMsk = this.getFormatDate(this.props.purchase.purchaseExpirationAt);
          const dateLocal = this.getLocalFormatDate(this.props.purchase.purchaseExpirationAt);

          return (
            <React.Fragment>
              <Typography className='purchase_time_header'>Подача заявок до: </Typography>
              <Typography className='purchase_time_value'>{dateMsk} (по МСК)</Typography>
              {dateMsk != dateLocal ? <Typography className='purchase_time_value'>{dateLocal} (по местному)</Typography> : null}
              <Typography className='purchase_time_value'>{this.getDateDiff(this.props.purchase.purchaseExpirationAt)}</Typography>
            </React.Fragment>
          );
      case 2:
        return 'Работа комиссии';
      case 3:
        return 'Размещение завершено';
      case 4:
        return 'Размещение отменено';
      case 5:
        return 'Торги не состоялись';
      case 6: 
        return 'Торги запланированы';
      case 7: 
        return 'На исполнении';
      default:
        return 'Не определено';
    }
  }

  handleToggleTag = (newTagId) => {
    let updatedTags = [];
    if (this.state.tags.some(tag => tag.id === newTagId)) {
      updatedTags = [this.state.tags.filter(tag => tag.id !== newTagId)]
    } else {
      updatedTags = [...this.state.tags, { id: newTagId }]
    } 
    this.setState({ tags: updatedTags})
  } 

  render() {
		return (      
      <Card variant="outlined" sx={{ minWidth: 275, mt: 1, width: '100%' }}>
        <CardContent className='search_result_card'>
          <div className="zakupka_card card_tablet">
            <Grid>       
              {this.props.purchase.recommended == true 
                ? <div className='zakupka_recomended'>Рекомендовано Эксперт-Центром!</div>
                : null
              }
              <Link component={RouterLink} rel="noopener noreferrer" to={`/purchases/${this.props.purchase.id}`} underline="hover">
                <div className="highlighted zakupka_name">
                  {htmlParse(this.props.purchase.title ?? '<объект закупки не указан>')}
                </div>
              </Link>
              <Typography variant="caption" color="text.secondary" component="div">
                {[this.getLaw(this.props.purchase), this.props.purchase.methodType, this.props.purchase.number].filter(Boolean).join(', ')}
              </Typography>
              <Typography sx={{mt: 2, mb: 2}} component="div" className='zakupka_block_header_container'>
                <div className='zakupka_block_header'>
                  Площадка:
                </div> 
                {this.getSourcePlatform(this.props.purchase)}
              </Typography>

              {
                window.innerWidth <= 1024 ?
                    <Grid>
                      <Typography variant="caption" component="div" className='zakupka_block_header_container'>
                        <div className='zakupka_block_header'>
                          Заказчик:
                        </div>
                        {this.props.purchase.customerOrganizationName}
                      </Typography>
                      <Rating value={parseFloat(this.props.purchase.customerRating)} precision={0.1} readOnly />
                    </Grid> : null
              }
            </Grid>
            <Grid className='zakupka_aside'>
              <div className='zakupka_price'>
                {this.props.purchase.price ? this.props.purchase.price + ' ₽' : '—'}
              </div>
              {this.props.purchase.prepayment
              ?
                <div className='zakupka_prepayment'>
                  Аванс: {this.props.purchase.prepayment}%
                </div>
              :
                null
              }
              
              <Typography variant="subtitle1" component="div" sx={{mt: 2}}>
                {this.getStage(this.props.purchase)}
              </Typography>
              <div>
                {this.props.purchase.isInWork
                  ? <div className='zakupka_inwork'>Закупка уже в работе</div>
                  : null
                }
                {this.getLotInfo(this.props.purchase)}
              </div>
            </Grid>
          </div>
          {
            window.innerWidth > 1024 ?
                <Grid>
                  <Typography variant="caption" component="div">
                    <div className='zakupka_block_header'>
                      Заказчик:
                    </div>
                    {this.props.purchase.customerOrganizationName}
                  </Typography>
                  <Rating value={parseFloat(this.props.purchase.customerRating)} precision={0.1} readOnly />
                </Grid> : null
          }
          {this.props.purchase.documentsInText.length > 0 ? (
            <Grid sx={{mt: 2}}>
              <Grid>
                <div className='zakupka_block_header'>Найдено в документах:</div>
              </Grid>
              {this.props.purchase.documentsInText.map((doc, key) =>
                <Grid key={key}>
                  <Divider sx={{ mb: 2}} />
                  <Breadcrumbs aria-label="breadcrumb">
                      {doc.parentFiles.map((parentFile, key) => 
                        <Typography key={key}>{parentFile}</Typography>
                      )}
                      <Typography>{doc.fileName}</Typography>
                  </Breadcrumbs>
                  {doc.highlightedContent.map((highlightedText, key) => 
                    <Typography className="highlighted" variant='subtitle2' key={key}>
                      {htmlParse(`...${highlightedText}...`)}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          ):null
          }
          {this.props.purchase.highlightedLotNames.length > 0 ? (
            <Grid sx={{mt: 2}}>
              <Grid>
                <div className='zakupka_block_header'>Найдено в лотах:</div>
              </Grid>
              {this.props.purchase.highlightedLotNames.map((highlightedText, key) =>
                <Grid key={key}>
                  <Typography className="highlighted" variant='subtitle2' key={key}>
                    {htmlParse(`...${highlightedText}...`)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ):null
          }
          <Stack marginTop={'10px'} direction="row" justifyContent='space-between' spacing={1} className='purchase_buttons_container'>
            <Stack alignSelf={'start'} direction="row" spacing={1}>
              {this.state.purchase.tags
              ? this.state.purchase.tags.map((tag, key) =>
                  <Chip label={tag.name} key={key} />
              )
              :
              null
              }
            </Stack>
            <Stack direction="row" spacing={1} className='purchase_buttons_inner_container'>
              <Button 
                variant="outlined"
                onClick={() => this.props.onPurchaseTagAddCalled(this.state.purchase, this.props.enqueueSnackbar)}
              >
                Избранное
              </Button>
              <LiveChatEventButton
                title="Задать вопрос по закупке"
                eventName="Вопрос по закупке"
                eventProperties={{
                  'Наименование закупки': this.props.purchase.title,
                  'Ссылка на закупку': this.props.purchase.sourceUrl,
                  'Стоимость закупки': this.props.purchase.price
                }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
		);
  }
  
}

function mapStateToProps(state, ownProps) {
  //to rerender only one card with purchase id on tags changed
  if (state.selectTagDialogState.selectedPurchase.id === ownProps.purchase.id)
    return {
      tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
    };	
  else {
    return {
    }
  }

}

function mapDispatchToProps(dispatch) {
  return {
    onPurchaseTagRemoved: (purchaseId, tagId) => {
      purchaseTagsService.removePurchaseTag({purchaseId: purchaseId, tagId: tagId})
        .then(() => {
          const purchaseIds = [purchaseId];
          purchaseTagsService.getPurchaseTags(purchaseIds)
            .then(result => {
              const action = {
                type: 'REFRESH_PURCHASE_TAGS',
                purchase: result[0]
              };
              dispatch(action);
            })
        })
        .catch((error) => {
          const notificationAction = {
            type: 'SNACKBAR_NOTIFICATION_SENT',
            notification: {
              message: 'Не удалось удалить папку избранного',
              variant: 'error'
            }
          };
          dispatch(notificationAction);
        })
    },
    onPurchaseTagAddCalled: (purchase) => {
      purchaseTagsService.getTags().then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          tags: result
        };
        dispatch(action);
      })
      .catch((error) => {
        const notificationAction = {
          type: 'SNACKBAR_NOTIFICATION_SENT',
          notification: {
            message: 'Не удалось получить папки избранного',
            variant: 'error'
          }
        };
        dispatch(notificationAction);
      });
    },
    onPurchaseTagChangeCalled: (purchase, tagId) => {
      purchaseTagsService.getTags().then(result => {
        const action = {
          type: 'OPEN_TAG_DIALOG',
          selectedPurchase: purchase,
          selectedTagId: tagId,
          tags: result
        };
        dispatch(action);
      });
    }
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseCard)));
