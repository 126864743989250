import Service from "../service";

class CustomerService extends Service {
  async search(keyword) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify({
        keywords: keyword,
        size: 5,
        from: 0
      })
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5554';

    return this.authenticatedFetch(`${baseURL}/api/organizations/search`, requestOptions);
  }

  async getCustomer(customerOrgId) {
    if (customerOrgId)
    {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        }
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5561';
      return await this.authenticatedFetch(`${baseURL}/api/organizations/${customerOrgId}`, requestOptions);
    }
    return null;
  }

  async getOrganizationByInn(organizationInn){
    if (organizationInn) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        }
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_ORGANIZATIONS_API_URI : 'http://localhost:5561';
      return await this.authenticatedFetch(`${baseURL}/api/organizations/inn/${organizationInn}`, requestOptions);
    }
    return null;
  }
}

export default new CustomerService();
