import React, { useEffect, useState, useCallback } from "react"
import { Outlet } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import userManager from "../userManager";

import SelectCompanyModal from "./select-company-modal";
import { SignUpSecondStageModal } from "./authentication/sign-up-second-stage-modal";

import profileService from '../services/profileService';
import companiesService from '../services/companiesService';

export const CompanyOverlay = (props) => {
    const dispatch = useDispatch();
    const currentCompany = useSelector((state) => state.profileState.currentCompany);
    const currentUserInfo = useSelector((state) => state.profileState.currentUserInfo);
    const activeCompanies = useSelector((state) => state.profileState.activeCompanies);
    const selectedCompanyStorageName = useSelector((state) => state.profileState.selectedCompanyStorageName);

    const [userCompanies, setUserCompanies] = useState([]);
    const [showSelectCompanyModal, setShowSelectCompanyModal] = useState(false);
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);

    const selectCompany = useCallback(
        (selectedCompany) => {
        const action = {
            type: 'CURRENT_COMPANY_CHANGED',
            currentCompany: selectedCompany
        };
        dispatch(action);
    }, [dispatch])

    const handleCompanySelect = useCallback( (company) => {
        localStorage.setItem(selectedCompanyStorageName, JSON.stringify({Id: company.Id }));
        selectCompany(company);
        setRegistrationCompleted(true);
        setShowSelectCompanyModal(false);
    },[selectedCompanyStorageName, selectCompany]);

    const handleCompanyLinkCreate = async () => {
        setShowAddCompanyModal(false);
        //обновляем токен после выбора компании
        await userManager.signinSilent();

        //TODO refactor this, doubles code from auth-layout
        profileService.getAccountInfo()
            .then((user) => {
                if (user)
                {
                    const action = {
                        type: 'USER_INFO_OBTAINED',
                        currentUserInfo: {
                            phone_number: user.PhoneNumber,
                            phone_confirmed: user.PhoneNumberConfirmed,
                            email: user.Email,
                            email_confirmed: user.EmailConfirmed,
                            id: user.Id
                        }
                    };
                    dispatch(action);
                }
            })
            .catch((error) => {
                this.props.enqueueSnackbar(`Произошла ошибка при получении данных аккаунта. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })
            .finally(() => {
                setRegistrationCompleted(true);
            })

        companiesService.getUserCompanies()
            .then((result) => {
                const action = {
                    type: 'ACTIVE_COMPANIES_OBTAINED',
                    activeCompanies: result
                };
                dispatch(action);
            })
            .finally(() => {
                //Компанию ставим пустую чтобы сработал useEffect в company-overlay
                selectCompany({});
            })
    }

    //update selectedcompanycookie name in redux
    useEffect(() => {
        if (currentUserInfo && currentUserInfo.id)
        {
            const selectedCompanyStorageName = `selectedCompany_${currentUserInfo.id}`;
            const action = {
                type: 'SELECTED_COMPANY_COOKIE_NAME_CHANGED',
                selectedCompanyStorageName: selectedCompanyStorageName
            }
            dispatch(action);
        }
    }, [currentUserInfo, dispatch])

    //company selection logic
    useEffect(() => {
        //Если в авторизационном токене стоит claim UncompleteRegistration - показываем модалку c выбором компании
        const user = JSON.parse(localStorage.getItem(`oidc.user:${process.env.REACT_APP_PORTAL_IDENTITY_URI}:web_client`));
        if (user.profile.UncompleteRegistration)
        {
            setShowAddCompanyModal(true);
        }
        else {
            setRegistrationCompleted(true);
        }

        if (selectedCompanyStorageName)
        {
            const currentCompanyCookieValue = JSON.parse(localStorage.getItem(selectedCompanyStorageName));

            if (!currentCompanyCookieValue) {
                if (activeCompanies.length === 1){
                    handleCompanySelect(activeCompanies[0]);
                    return;
                }
                if (activeCompanies.length > 1) {
                    setUserCompanies(activeCompanies);
                    setShowSelectCompanyModal(true);
                }
            }
            else {
                if (activeCompanies.length > 0)
                {
                    const activeCompany = activeCompanies.find(company => company.Id === currentCompanyCookieValue.Id);
                    if (activeCompany){
                        selectCompany(activeCompany);
                    } else {
                        localStorage.removeItem(selectedCompanyStorageName);
                        selectCompany({});
                    }
                }
            }
        }
    },[currentCompany, activeCompanies, selectedCompanyStorageName, selectCompany, handleCompanySelect]);

    return (
        <React.Fragment>
            <SelectCompanyModal open={showSelectCompanyModal} onSelect={handleCompanySelect} companies={userCompanies} />
            <SignUpSecondStageModal open={showAddCompanyModal} onSelect={handleCompanyLinkCreate} />
            {registrationCompleted
             ?
             <Outlet/>
             :
             null
            }
            
        </React.Fragment>
    )
}