import React from "react"
import { MailingsList } from "./mailings-list"
import './mailing-mobile.css'

export const MailingsIndex = (props) => {
    return (
        <React.Fragment>
            <MailingsList />
        </React.Fragment>
    )
}
