import React from "react"
import { Modal, Button, Box, Typography, TextField, FormHelperText, Tooltip, IconButton } from '@mui/material';
import { HelpOutline } from "@mui/icons-material"
import { FindCompanyInput } from "../find-company-input";
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";

export const EditCompanyModal = (props) => {
    const handleEditCompany = (values) => {
        const submitValue = {
            AcvId: props.company.Id,
            Name: values.customCompanyName ? values.customCompanyName : values.company.Name,
            TaxPayerNumber: values.company.TaxPayerNumber,
            Comment: values.comment
        }
        props.onSubmit(submitValue);
    }

    const validationSchema = Yup.object({
        company: Yup.object()
            .shape({
                Id: Yup.string(),
                Name: Yup.string(),
                TaxPayerNumber: Yup.string()
            })
            .nullable()
            .required('Обязательно должна быть выбрана компания'),
    });
    
    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={props.onClose}
            >
                <Box className="modal_body">
                    <h2 className="modal_header">
                        Редактирование компании
                        <span>{props.company.Name} | ИНН {props.company.TaxPayerNumber}</span>
                    </h2>
                    <Formik 
                        initialValues={{
                            company: props.company, 
                            customCompanyName: props.company.Name, 
                            comment: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            handleEditCompany(values)
                        }}
                    >
                    { formik => (
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <Field name="company">
                                {({field, form, meta}) => (
                                    <>
                                        <FindCompanyInput 
                                            {...field}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    formik.setFieldValue("company", {
                                                        "Id": value.Id,
                                                        "Name": value.Name,
                                                        "TaxPayerNumber": value.TaxPayerNumber
                                                    });
                                                    formik.setFieldValue("customCompanyName", value.Name);
                                                }
                                                else {
                                                    formik.setFieldValue("company", null);
                                                }
                                            }}
                                        />
                                        <FormHelperText error={meta.touched && meta.error != null} > 
                                            {meta.touched && meta.error}
                                        </FormHelperText>
                                    </>
                                )}
                            </Field> 
                            <Field name="customCompanyName">
                                {({field, form, meta}) => (
                                    <TextField
                                        {...field}
                                        title="Свое название компании"
                                        label="Свое название компании"
                                        fullWidth
                                        sx={{mb: 2}}
                                    />
                                    )}
                            </Field>
                            {
                                !props.allowedToEdit 
                                ?
                                    <>
                                        <Field name="comment">
                                            {({field, form, meta}) => (
                                                <>
                                                    <Typography>Комментарий:</Typography>
                                                    <TextField
                                                        {...field}
                                                        multiline
                                                        rows={3}
                                                        title="Комментарий"
                                                        fullWidth
                                                        sx={{mb: 2}}
                                                    />
                                                </>
                                            )}
                                        </Field>
                                        <Button 
                                            variant="contained"
                                            type="submit"
                                        >
                                            Отправить
                                        </Button>
                                        <Tooltip 
                                            sx={{mr: 2}}
                                            enterTouchDelay={0}
                                            title="К выбранной компании прикреплен менеджер, изменение данных компании недоступно без предварительного согласования с ним"
                                        >
                                            <IconButton size="small">
                                                <HelpOutline fontSize="inherit" />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                :
                                <Button 
                                    variant="contained"
                                    type="submit"
                                    sx={{mr: 2}}
                                >
                                    Сохранить
                                </Button>
                            }
                            <Button 
                                onClick={props.onClose}
                                variant="outlined"
                                sx={{ml: 'auto'}}
                            >
                                Отмена
                            </Button>
                        </Form>
                    )}
                </Formik>
                </Box>
            </Modal>
        </React.Fragment>
    )
}