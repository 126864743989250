import { Box } from "@mui/system"
import React, { useEffect } from "react"
import { Pagination, Stack, CircularProgress, Typography, Select, MenuItem, Grid } from "@mui/material"
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";

import invoicesService from "../../services/invoicesService"
import { InvoiceItem } from "./invoice-item"
import noContentImg from "../../img/icons/no-invoices.png"

const InvoiceList = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        props.onLoadingPage(props, 0, enqueueSnackbar);
    }, [props.orderBy, props.asc, props.pageSize, props.currentCompany]);

    const handlePaginationChange = (e, newPage) => {
        props.onLoadingPage(props, newPage, enqueueSnackbar);
    }

    const sortingOptions = [
		{ title: 'Сначала новые', value: '{"orderBy":"Created","asc":false}' },
		{ title: 'Сначала старые', value: '{"orderBy":"Created","asc":true}'},
		{ title: 'Сначала меньший номер', value: '{"orderBy":"No","asc":true}'},
		{ title: 'Сначала больший номер', value: '{"orderBy":"No","asc":false}'},
	]
    
    return (
        <React.Fragment>
            {props.isLoading 
            ? 
                <CircularProgress />
            :
                <>
                {
                    props.invoices.length > 0
                    ?
                        <>
                            <Box className='invoices_container_header' >
                                <Grid container className='invoices_container_inner'>
                                    <Grid item xs={3}>
                                        <Typography>Сортировать по:</Typography>
                                        <Select 
                                            variant="standard" 
                                            size="small" 
                                            value={JSON.stringify({ orderBy: props.orderBy, asc: props.asc })}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                props.onChangeSorting(value);
                                            }}
                                        >
                                            { sortingOptions.map((option, key) => 
                                                <MenuItem value={option.value} key={key}>{option.title}</MenuItem>
                                            )}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>Счетов на странице:</Typography>
                                        <Select 
                                            variant="standard" 
                                            size="small" 
                                            value={props.pageSize}
                                            onChange={(event) => { props.onChangePageSize(event.target.value)}}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className="invoices_list">
                                {props.invoices.map((invoice, key) => <InvoiceItem key={key} invoice={invoice} />)}
                            </Box>
                            <Box sx={{ mt: 2 }}>
                            <Stack spacing={2}>
                                <Pagination page={props.currentPage} count={props.pagesCount} onChange={handlePaginationChange} />
                            </Stack>
                            </Box>
                        </>
                    :
                    <Box className="no_item_block">
                        <Box className="no_item_block_icon">
                            <img src={noContentImg}/>
                        </Box>
                        <Box className="no_item_block_text_main">
                            Упс... кажется на данный момент у вас нет ни одного выставленного счета.
                        </Box>
                        <Box className="no_item_block_text_second">
                            При заключении с нами договора вы сможете увидеть выставленные вам счета в этом разделе.
                        </Box>
                    </Box>
                }
                </>
            }
		</React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentPage: state.invoicesState.currentPage,
        pagesCount: state.invoicesState.pagesCount,
        pageSize: state.invoicesState.pageSize,
        orderBy: state.invoicesState.orderBy,
        asc: state.invoicesState.asc,
        invoices: state.invoicesState.invoices,
        isLoading: state.invoicesState.isLoading,
        currentCompany: state.profileState.currentCompany
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadingPage: (state, pageNumber, enqueueSnackbar) => {
            const pageAction = {
                type: 'CURRENT_PAGE_CHANGED',
                currentPage: 'invoices'
            };
            if (state.currentCompany) {
                dispatch(pageAction);
                const action = {
                    type: 'INVOICES_LOAD_STARTED'
                };
                dispatch(action);
                if (state.currentCompany.Id) {
                invoicesService.getInvoices(pageNumber, state.pageSize, state.orderBy, state.asc, state.currentCompany.Id)
                    .then((result) => {
                        const action = {
                            type: 'INVOICES_PAGE_LOADED', 
                            pagesCount: result.TotalPages,
                            currentPage: result.PageNumber,
                            invoices: result.Items
                        };
                        dispatch(action);
                    })
                    .catch((error) => {
                        enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                        const action = {
                            type: 'INVOICES_LOAD_ERROR'
                        };
                        dispatch(action);
                    });
                } else {
                    const action = {
                        type: 'INVOICES_PAGE_LOADED', 
                        pagesCount: 1,
                        currentPage: 0,
                        invoices: []
                    };
                    dispatch(action);
                }
            }
        },
        onChangeSorting: (value) => {
            const action = {
                type: 'INVOICES_SORTING_CHANGED',
                value: value
            };
            dispatch(action);
        },
        onChangePageSize: (value) => {
            const action = {
                type: 'INVOICES_PAGE_SIZE_CHANGED',
                value: value
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceList)
