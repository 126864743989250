import React from "react"
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from "notistack";
import { Select, MenuItem } from "@mui/material";

export const CompanySelect = (props) => {
    const dispatch = useDispatch();
    const currentCompany = useSelector((state) => state.profileState.currentCompany);
    const activeCompanies = useSelector((state) => state.profileState.activeCompanies);
    const selectedCompanyStorageName = useSelector((state) => state.profileState.selectedCompanyStorageName);

    const { enqueueSnackbar } = useSnackbar();

    if (activeCompanies.length !== 0 && currentCompany && currentCompany.Id) {
        let currentCompanyId = activeCompanies.map(company => company.Id).indexOf(currentCompany.Id);

        //если выбранная компания по какой-либо причине не выбрана автоматически выбирается первая в списке
        if (currentCompanyId === -1) {
            const action = {
                type: 'CURRENT_COMPANY_CHANGED',
                currentCompany: activeCompanies[0]
            };
            dispatch(action);
            localStorage.setItem(selectedCompanyStorageName, JSON.stringify({Id: activeCompanies[0].Id }));
        }

        return (
            <React.Fragment>
                <Select className="company_header_selector"
                    onChange={(event) => {
                        var index = event.target.value;
                        const action = {
                            type: 'CURRENT_COMPANY_CHANGED',
                            currentCompany: activeCompanies[index]
                        };
                        dispatch(action);
                        localStorage.setItem(selectedCompanyStorageName, JSON.stringify({Id: activeCompanies[index].Id }));
                        enqueueSnackbar('Компания успешно изменена', { variant: "success" });
                    }}
                    value={currentCompanyId}
                >
                    {activeCompanies.map((company, index) => (
                        <MenuItem value={index} key={index}>{company.Name}</MenuItem>
                    ))}
                </Select>         
            </React.Fragment>
        )
    }

    return null;
}
