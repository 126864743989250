import InvoiceList from "./invoice-list";
import './invoice-mobile.css'

const InvoiceIndex = (props) => {
  return <InvoiceList />
}

export default InvoiceIndex


