import Service from "../service";

class PurchaseTagsService extends Service {
  async getPurchaseTags(purchaseIds) {
    if (purchaseIds)
    {
      const requestOptions = {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json', 
          'Accept': 'application/json'
        },
        body: JSON.stringify(purchaseIds)
      };

      const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
      return await this.authenticatedFetch(`${baseURL}/api/tags/getByPurchases`, requestOptions);
    }
    return null;
  }

  async getTags() {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    return await this.authenticatedFetch(`${baseURL}/api/tags`, requestOptions);
  }

  async getTag(tagId) {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    return await this.authenticatedFetch(`${baseURL}/api/tags/${tagId}`, requestOptions);
  }

  async addTag(tag) {
    const requestOptions = {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify(tag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    var response = await fetch(`${baseURL}/api/tags`, requestOptions);
    var jsonResponse = await response.json();
    return jsonResponse;
  }

  async updateTag(tagId, tag) {
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify(tag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/${tagId}`, requestOptions);
    //TODO разобраться с этим
    if (response.status >= 400) {
      return await response.json();
    }
    
    return response;
  }

  async removeTag(tagId) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    await fetch(`${baseURL}/api/tags/${tagId}`, requestOptions);
  }

  async removePurchaseTag(purchaseTag) {
    const requestOptions = {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify(purchaseTag)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/purchases`, requestOptions);
    if (!response.ok){
      throw new Error("");
    }
  }

  async setPurchaseTag(purchase) {
    const requestOptions = {
      method: 'PUT',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      },
      body: JSON.stringify(purchase)
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    const response = await fetch(`${baseURL}/api/tags/set-purchase-tag`, requestOptions);
    if (!response.ok){
      throw new Error("");
    }
  }

  async getPurchasesForTag(tagId) {
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json', 
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    return await this.authenticatedFetch(`${baseURL}/api/tags/purchases/${tagId}`, requestOptions);
  }

  async getRelevantTagPurchases(count) {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };

    const baseURL = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    return await this.authenticatedFetch(`${baseURL}/api/tags/purchases/relevant?count=${count}`, requestOptions);
  }

  async removeTagsByPurchaseId(purchaseId) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': this.getAuthorizationToken()
      }
    };

    const baseUrl = process.env.REACT_APP_ENVIRONMENT === 'Production' ? process.env.REACT_APP_PURCHASE_TAGS_API_URI : 'http://localhost:5563';
    return await fetch(`${baseUrl}/api/tags/purchases/removeByPurchaseId?purchaseId=${purchaseId}`, requestOptions);
  }
}

export default new PurchaseTagsService();