import React from 'react';
import { useSelector } from 'react-redux';
import searchTipsImg from "../../img/icons/searchtips.png"

export const PurchaseSearchResultTips = () => {
  const MIN_RESULTS_TO_SHOW_TIPS = 10;

  const total = useSelector((state) => state.searchPurchasesState.total);
  const searchCompleted = useSelector((state) => state.searchPurchasesState.searchCompleted);
  const kladrCodes = useSelector((state) => state.searchPurchasesState.kladrCodesCurrentSearch);
  const priceFrom = useSelector((state) => state.searchPurchasesState.priceFromCurrentSearch);
  const shouldIncludeEmptyPrice = useSelector((state) => state.searchPurchasesState.shouldIncludeEmptyPriceCurrentSearch);

  //Закупки без НМЦ
  const needPriceTip = shouldIncludeEmptyPrice === false && priceFrom > 0;

  //Выбран населенный пункт, но не выбран регион
  const needRegionTip = kladrCodes.length > 0 && !kladrCodes.some(kladr => kladr.code.endsWith('00000000000') && kladrCodes.some(innerKladr => innerKladr.code == kladr.code.substring(0,2) + '00000000000'));

  if (searchCompleted === true && total < MIN_RESULTS_TO_SHOW_TIPS && 
    (needPriceTip || needRegionTip)) {
    return (
      <div className='search-results-tips-box'>
        <div className='search-results-tips-header'>
          <h4>Возможно, Вам помогут получить больше результатов следующие рекомендации:</h4>
        </div>
        <div className='search-results-tips-container'>
          <div className='search-results-tips-icon'>
              <img src={searchTipsImg} alt=""/>
          </div>
          <ul className='search-results-tips-list'>
            {
            needPriceTip
            ?
              <li>
                Попробуйте включить закупки без НМЦ
                <br/>
                <i>(Очень часто во многих закупках не указывают начальную цену)</i>
              </li>
            :
              null
            }
            {
              needRegionTip
              ?
                <li>
                  Попробуйте включить в поиск весь регион, а не только населенный пункт / город
                  <br/>
                  <i>(Не все поставщики корректно указывают место поставки)</i>
                </li>
              :
                null
            }
          </ul>
        </div>
        
      </div>
    )
  }

  return null;
}