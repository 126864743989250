import React, { useState } from "react"
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions, Backdrop, CircularProgress } from '@mui/material';

/*
Компонент добавляет к дочернему элементу (обычно кнопка) вызов диалога с подтверждением действия
Входные пропсы:
onConfirm: функция, вызываемая при подтверждении
confirmationText: текст в диалоговом окне при подтверждении
*/
export const ConfirmationDialog = (props) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [inProgress, setInProgress] = useState(false);

    return (
        <React.Fragment>
            <Dialog
                open={showConfirmationDialog}
                onClose={() => setShowConfirmationDialog(false)}
                aria-describedby="alert-dialog-description"
            >
                <Backdrop open={inProgress} sx={{ zIndex: 10}}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setShowConfirmationDialog(false)}>Нет</Button>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            setInProgress(true);
                            props.onConfirm()
                            .then(() => {
                                setShowConfirmationDialog(false);
                            })
                            .finally(() => {
                                setInProgress(false);
                            })
                        }} 
                        autoFocus
                    >
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
            { React.cloneElement( props.children, { onClick: () => setShowConfirmationDialog(true) } ) }
        </React.Fragment>
    )
}
