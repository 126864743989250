import React from "react"
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";

//props
//text - текст ссылки
export const LiveChatEventButton = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    
    return (
        <Button 
            variant="outlined"
            onClick={() => { 
                if (window.carrotquest) {
                    window.carrotquest.track(props.eventName, props.eventProperties);
                    enqueueSnackbar('Запрос отправлен, ожидайте ответного сообщения', { variant: 'success' });
                } else {
                    enqueueSnackbar('Чат не подключен либо блокируется браузером или расширением', { variant: 'error'});
                }
            } }
        >
            {props.title}
        </Button>
    )
}
