import { useState } from 'react';
import UserManager from '../../userManager';
import { useSnackbar } from 'notistack';

export const SignOutButton = () => {
    const [clicked, setClicked] = useState(false);
    const enqueueSnackbar = useSnackbar();

    const handleSignOut = async () => {
        if (clicked === false) {
            setClicked(true);
            const user = await UserManager.getUser();
            if (user) {
                UserManager.signoutRedirect()
                    .catch((error) => {
                        enqueueSnackbar(`Произошла ошибка при попытке выхода из учетной записи`, { variant: "error" });
                        setClicked(false);
                    })
            } else {
                UserManager.signinRedirect();
            }
        }
    }

    return (
        <div className="logout_btn" onClick={handleSignOut}>
            <span className="menu_icon exit_icon"></span> 
            Выход
        </div>
    )
}