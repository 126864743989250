import React, { useState } from "react"
import { Modal, Button, Box, FormGroup, TextField, FormControlLabel, Checkbox, Autocomplete, FormHelperText } from '@mui/material';
import { connect } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { FindCompanyInput } from "../find-company-input";
import { useSnackbar } from "notistack";

import companiesService from "../../services/companiesService";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    px: 4,
    pb: 2,
  };

export const SignUpSecondStageModal = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleCompanySelect = (company) => {
        let newCompany = { };
        if (company) {
            newCompany.Company = company;
        }

        companiesService.selectCompany(newCompany)
            .then(async ()=>{
                await props.onSelect();
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })     
    }

    const validationSchema = Yup.object({
        withoutCompanyFlag: Yup.boolean()
            .when('company', {
                is: (value) => value == null,
                then: (schema) => schema.oneOf([true], "Должна быть выбрана компания или отмечена галочка \"Без указанной компании\"")
            })
    });

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                //onClose={onClose}
            >
                <Box sx={{...style}}>
                    <h3 style={{marginBottom:'0px', textAlign:'center'}}>Для продолжения работы необходимо добавить информацию о вашей компании</h3>
                    <Formik 
                        initialValues={{company: null, withoutCompanyFlag: false}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            handleCompanySelect(values.company)
                            //.then(() => {
                            //    setSubmitting(false);
                            //});
                        }}
                    >
                    { formik => (
                        <Form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
                            <Field name="company">
                                {({field, form, meta}) => (
                                    <FindCompanyInput 
                                        showName={true}
                                        onChange={(event, value) => {
                                        if (value) {
                                            formik.setFieldValue("company", {
                                                "Name": value.Name,
                                                "TaxPayerNumber": value.TaxPayerNumber
                                            });
                                        }
                                        else {
                                            formik.setFieldValue("company", null);
                                        }
                                    }}
                                    />
                                )}
                            </Field> 
                            <Field name="withoutCompanyFlag">
                                {({field, form, meta}) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Нет компании"
                                            name="withoutCompanyFlag"
                                            className="no_company_check"
                                            control={
                                                <Checkbox
                                                    id="withoutCompanyFlag"
                                                    name="withoutCompanyFlag"
                                                />
                                            }
                                            onChange={(event, value) =>{
                                                formik.setFieldValue('withoutCompanyFlag', value);
                                            }}
                                        >
                                        </FormControlLabel>
                                        <FormHelperText error={meta.touched && meta.error != null}>
                                            {meta.touched && meta.error}
                                        </FormHelperText>
                                    </FormGroup>
                                )}
                            </Field>
                            <Box className="find_company_info_block">
                                Информация о компании нужна для вашего удобства работы с личным кабинетом.
                                При добавлении компании вы получите больше функциональных возможностей в своем аккаунте.
                                Если у вас нет компании, то вы можете выбрать галочку "без компании". Вы всегда сможете заполнить информацию о компании позже в разделе настроек.
                            </Box>
                            <Box style={{display:'flex', justifyContent:'center', marginTop:'15px'}}>
                                <Button 
                                    variant="contained"
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                >
                                    Сохранить
                                </Button>
                            </Box>
                        </Form>
                    )}
                    </Formik>
                </Box>
            </Modal>
        </React.Fragment>
    )
}