import React, { useEffect, useState } from "react"
import { Box, Link } from '@mui/material';
import { NumberFormatCustom } from '../number-format-custom';
import { OpenChatLink } from '../open-chat-link';

export const MainPageCompaniesSummary = (props) => {
    return (
        <React.Fragment>
            <h4 className="main_page_header">Компании</h4>
            {props.companies && props.companies.length > 0
            ?
                <div className="main_page_inner_container">
                    {
                        props.companies.map((company, key) =>
                            <div key={key} className="main_company_card">
                                <div className="main_company_primary_info">
                                    <div className="main_company_header">
                                        <span>{company.Name}</span>
                                        <span>, ИНН: {company.TaxPayerNumber && company.TaxPayerNumber.HasValue === true ? company.TaxPayerNumber.Number : null}</span>
                                    </div>
                                    <div className="main_company_manager">{company.ManagerFullName ? `Менеджер:${company.ManagerFullName}` : null}</div>
                                </div>
                                <div className="main_company_additional_info">
                                    <div className="main_company_additional_info_inner">
                                        <div className="main_page_small_block_header">Закупок в работе:</div>
                                        <div>{company.PurchasesInWorkCount}</div>
                                    </div>
                                    <div className="main_company_additional_info_inner">
                                        <div className="main_page_small_block_header">Закупок ожидающих приложенных документов:</div>
                                        <div>{company.PurchasesAwaitingDocumentsCount}</div>
                                    </div>
                                    <div className="main_company_additional_info_inner">
                                        <div className="main_page_small_block_header">Неоплаченных счетов:</div>
                                        <div>{company.UnpaidInvoicesCount}</div>
                                    </div>
                                    <div className="main_company_payment_info">
                                        {company.GuaranteeAmountLeftToWin
                                            ?
                                            <div className="main_company_additional_info_inner">
                                                <div className="main_page_small_block_header">Осталось выиграть(закупок):</div>
                                                <div>{company.GuaranteeAmountLeftToWin}</div>
                                            </div>
                                            :
                                            null
                                        }
                                        {company.GuaranteeMoneyLeftToWin
                                            ?
                                            <div className="main_company_card_total_price">
                                                <div className="main_page_small_block_header">Осталось выиграть(сумма):</div>
                                                <div className="main_company_card_total_price_count">
                                                    <NumberFormatCustom value={company.GuaranteeMoneyLeftToWin} displayType="text" /> ₽
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            :
                <Box sx={{marginBottom:'10px'}} className="main_page_small_block">
                    <Box className="main_page_small_block_header">
                        <Box sx={{padding:'5px', fontSize:'15px'}}>
                            <Box className="companies_grid_no_companies_title">На данный момент у вас нет ни одной компании.</Box>
                            <Box className="text_second">
                                Если у вас нет своего ИП или ЮО, то мы с радостью готовы <OpenChatLink text="помочь"/> вам в этом вопросе.
                                Если у вас уже есть своя компания, то вы можете самостоятельно добавить информацию о ней в настройках.
                                Это позволит получить доступ к большему функционалу в вашем личном кабинете.
                                <br/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}
