import React, { useState } from "react"
import { Button } from '@mui/material';

/*
Компонент добавляет к дочернему элементу (обычно кнопка) вызов диалога с подтверждением действия
Входные пропсы:
onConfirm: функция, вызываемая при подтверждении и возвращающая promise
confirmationText: текст в диалоговом окне при подтверждении
*/
export const ButtonWithWaitForCompletion = (props) => {
    const [inProgress, setInProgress] = useState(false);

    return (
        <React.Fragment>
            <Button 
                variant={props.variant}
                type={props.type}
                autoFocus={props.autoFocus}
                onClick={() => { 
                    setInProgress(true);
                    props.onClick()
                        .finally(() => {
                            setInProgress(false);
                        })
                }} 
                disabled = {inProgress}
            >
                {props.children}
            </Button>
        </React.Fragment>
    )
}
