//TODO алгоритм можно переписать получше
export function FindTopCheckedItems(checkedKeys, treeItems) {
  const markedSet = new Set(checkedKeys);
  let topCheckedKeys = new Set();

  function areAllChildrenMarked(node) {
    if (!node.children || node.children.length === 0) {
      if (markedSet.has(node.key)) {
        topCheckedKeys.add(node);
        return true;
      }
      return false;
    }

    let iterator = 0;
    let returnValue = true;
    //if one of childs not checked then uncheck this node
    while (node.children[iterator]) {
      const childResult = areAllChildrenMarked(node.children[iterator]);
      if (childResult === false) {
        returnValue = false;
      }

      iterator++;
    }

    if (returnValue === false) {
      return false;
    }

    //remove childrens if they all checked to remain only top checked nodes
    let iterator2 = 0;
    while (node.children[iterator2]) {
      let updatedTopCheckedKeys = new Set(topCheckedKeys);
      for (let i = 0; i < topCheckedKeys.length; i++) {
        if (topCheckedKeys[i].key === node.children[iterator2]) {
          updatedTopCheckedKeys.delete(i);
        }
      }
      updatedTopCheckedKeys.delete(node.children[iterator2]);

      topCheckedKeys = updatedTopCheckedKeys;

      iterator2++;
    }

    topCheckedKeys.add(node); 
    return true;
  }

  for (const node of treeItems) {
    areAllChildrenMarked(node);
  }

  return Array.from(topCheckedKeys);
}