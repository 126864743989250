import React from "react"
import { Button, Typography, Box, TextField } from "@mui/material"
import { useSnackbar } from "notistack";
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import { password_validation_rule } from "../../constants/validation_rules";

import settingsService from "../../services/settingsService";

export const UpdatePassword = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleUpdatePassword = async (formValues) => {
        const request = {
            password: formValues.new_password,
            oldPassword: formValues.old_password,
        };
        return settingsService.sendUpdatePassword(request)
            .then(() => {
                enqueueSnackbar('Пароль успешно обновлен', { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            });
    }

    const validationSchema = Yup.object({
        old_password: password_validation_rule
            .required('Обязательное поле'),
        new_password: password_validation_rule
            .required('Обязательное поле'),
        confirm_new_password: password_validation_rule
            .oneOf([Yup.ref('new_password')], 'Введенные пароли не совпадают')
            .required('Обязательное поле')
    });

    return (
        <React.Fragment>
            <Box sx={{mt: 3}}>
                <Typography className="heading" component="h1" variant="h5">
                    Смена пароля
                </Typography>
                <Formik 
                    initialValues={{old_password: '', new_password: '', confirm_new_password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        console.log(values);
                        handleUpdatePassword(values)
                        .finally(() => {
                            setSubmitting(false);
                            resetForm();
                        });
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit} className="settings_block settings_form pt20">
                        <Field name="old_password">
                            {({field, form, meta}) => (
                                <TextField
                                    className="settings_form_input"
                                    margin="normal"
                                    name="old_password"
                                    type="password"
                                    label="Старый пароль"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('old_password')}
                                    onBlur={(event) => {
                                        //set touched only if field value changed
                                        if (meta.value !== meta.initialValue)
                                        {
                                            formik.handleBlur(event);
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                    fullWidth
                                />
                            )}
                        </Field>
                        <Field name="new_password">
                            {({field, form, meta}) => (
                                <TextField
                                    className="settings_form_input"
                                    margin="normal"
                                    name="new_password"
                                    type="password"
                                    label="Новый пароль"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('new_password')}
                                    onBlur={(event) => {
                                        //set touched only if field value changed
                                        if (meta.value !== meta.initialValue)
                                        {
                                            formik.handleBlur(event);
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                    fullWidth
                                />
                            )}
                        </Field>
                        <Field name="confirm_new_password">
                            {({field, form, meta}) => (
                                <TextField
                                className="settings_form_input"
                                    margin="normal"
                                    name="confirm_new_password"
                                    type="password"
                                    label="Подтверждение нового пароля"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('confirm_new_password')}
                                    onBlur={(event) => {
                                        //set touched only if field value changed
                                        if (meta.value !== meta.initialValue)
                                        {
                                            formik.handleBlur(event);
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                    fullWidth
                                />
                            )}
                        </Field>

                        <Button
                            type="submit"
                            variant="contained"
                            className="settings_form_submit"
                            disabled={formik.isSubmitting}
                        >
                                Сменить пароль
                        </Button>
                    </Form>
                )}
            </Formik>
            </Box>
        </React.Fragment>
    )
}
