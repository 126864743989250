import React from "react"
import { Stack } from "@mui/material"
import { LiveChatEventButton } from "../live-chat/live-chat-event-button";

export const ManagerContacts = (props) => {
    let manager = props.manager;

    const whatsappLink = `https://wa.me/${manager.WhatsappId}`;

    return (
        <React.Fragment>
            <div className="card_contacts">
                <div className="manager_contacts">
                    <Stack direction="row" alignItems="center" className="contact_card">                        
                        <span className="contact_icon user_black"></span>
                        <span>
                            {manager.LastName} {manager.FirstName}
                        </span>
                    </Stack>
                
                    {
                        manager.Phone
                        ?
                            <Stack direction="row" alignItems="center" className="contact_card">                        
                                <span className="contact_icon phone_black"></span>
                                <span>
                                    <a href={`tel:${manager.Phone}`}>{manager.Phone}</a>
                                </span>
                            </Stack>
                        :
                            null
                    }
                    
                
                    {
                        manager.WhatsappId 
                        ?
                            <Stack direction="row" alignItems="center" className="contact_card">                        
                                <span className="contact_icon whatsup_black"></span>
                                <span>
                                    <a href={whatsappLink} target="_blank">{manager.WhatsappId}</a>
                                </span> 
                            </Stack>
                        :
                            null
                    }
                </div>
                {
                    props.chatAvailable === true 
                    ?
                    <LiveChatEventButton
                        title="Написать менеджеру"
                        eventName="Вопрос по закупке в работе"
                        eventProperties={{
                            'Фамилия менеджера': manager.LastName,
                            'Имя менеджера': manager.FirstName,
                            'Ссылка на закупку': props.purchaseUrl
                        }}
                    />
                    :
                    null
                }
            </div>
		</React.Fragment>
    )
}
