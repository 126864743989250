import React, {Component} from 'react';
import PurchaseService from '../../services/search-purchases-services/PurchaseService';
import purchasesInWorkService from '../../services/purchasesInWorkService';
import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import {DateTime} from 'luxon';
import CustomerDetails from "./CustomerDetails";
import CustomerReviewsPanel from "./CustomerReviewsPanel";
import PurchaseLotDetails from "./PurchaseLotDetails";
import {withRouter} from '../../withRouter';
import {connect} from 'react-redux';
import {withSnackbar} from 'notistack';
import { NumberFormatCustom } from '../number-format-custom';

// MUI:
import {
    Box,
    Button,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    IconButton,
    Link,
    Stack,
    Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TakePurchaseInWorkButton from './take-purchase-in-work-button';
import SelectTagDialog from './SelectTagDialog';

class PurchaseDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            purchase: null,
            isLoading: false
        };
    }

    componentDidUpdate(prevProps) {
        //rerender page if selected company changed
        if (prevProps.currentCompany.Id && this.props.currentCompany !== prevProps.currentCompany) {
            this.setState({purchase: null});
            this.updatePurchaseData(this.props.params.purchaseId);
        }

        //rerender when purchase tags changed
        if (this.props.tagDialogSelectedPurchase?.id && this.props.tagDialogSelectedPurchase.id === this.state.purchase?.id && this.props.tagDialogSelectedPurchase.tags !== this.state.purchase.tags) {
            this.setState({purchase: {...this.state.purchase, tags: this.props.tagDialogSelectedPurchase.tags}})
        }
    }

    componentDidMount() {
        this.updatePurchaseData(this.props.params.purchaseId);
    }

    updatePurchaseData = (purchaseId) => {
        this.setState({isLoading: true});
        PurchaseService.get(purchaseId)
            .then(purchase => {

                PurchaseTagsService.getPurchaseTags([purchaseId])
                    .then((result) => {
                        result.forEach((resultPurchase) => {
                            if (resultPurchase.id === purchaseId) {
                                purchase.tags = resultPurchase.tags;
                            }
                        })
                        this.setState({purchase: {...purchase}, isLoading: false});
                    })
                    .catch(() => {

                    })
                if (this.props.currentCompany.Id && this.props.currentCompany.Company) {
                    purchasesInWorkService.checkIfPurchasesInWork(this.props.currentCompany.Id, ([purchase]))
                        .then((result) => {
                            if (result.some(p => p.PurchaseNumber === purchase.number)) {
                                purchase.isInWork = true;
                            } else {
                                purchase.isInWork = false;
                            }
                            this.setState({purchase: {...purchase}, isLoading: false});
                        })
                        .catch((result) => {
                            this.setState({purchase: {...purchase}, isLoading: false})
                        })
                } else {
                    this.setState({purchase: {...purchase}, isLoading: false});
                }
            })
            .catch(error => {
                this.props.enqueueSnackbar(
                    'Произошла ошибка при получении закупки',
                    {
                        variant: 'error'
                    });
                this.setState({isLoading: false});
            })
    }

    getSource = () => {
        if (this.state.purchase.sourceUrl)
            return <a class="link_to_source" rel="noopener noreferrer" target="_blank"
                      href={this.state.purchase.sourceUrl}>Ссылка на источник</a>
        else
            return null
    }

    //временно отключено
    getDocumentsBlock = () => {
        if (this.state.purchase.documents.length > 0) {
            return (
                <div className='detail_block'>
                    <CardHeader
                        title="Документы"
                    />
                    <CardContent>
                        <Stack direction="row" alignItems="center">
                            <Tooltip title="Ссылка на сторонний ресурс">
                                <IconButton sx={{ml: 1}} size="small">
                                    <OpenInNewIcon fontSize='inherit'></OpenInNewIcon>
                                </IconButton>
                            </Tooltip>
                            <Link rel="noopener noreferrer" target="_blank" href={this.state.purchase.sourceUrl}
                                  underline="hover">
                                Скачать на площадке
                            </Link>
                        </Stack>
                    </CardContent>
                </div>
            );
        } else {
            return null;
        }
    }

    getFormatDate = (inputDate) => {
        if (!inputDate) {
            return '-';
        }

        const date = DateTime.fromISO(inputDate);
        const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

        return formattedDate;
    }

    getLaw = (law) => {
        switch (law) {
            case 1:
                return '44-ФЗ';
            case 2:
                return '223-ФЗ';
            case 3:
                return 'Коммерческие';
            default:
                return 'Не определено';
        }
    }

    getCurrency = (currencyId) => {
        switch (currencyId) {
            case 1:
                return '₽';
            default:
                return '';
        }
    }

    render() {
        if (this.state.purchase) {
            return (
                <React.Fragment>
                    <SelectTagDialog/>
                    <div className='detail_block main_block'>
                        <div className='detail_block_section'>
                            <div className='detail_header'>
                                {this.state.purchase.title}
                            </div>
                            <div className='flx info_flx'>
                                <div className='card_content card_purchase_id'>
                                    <div className='card_title'>
                                        Закупка
                                    </div>
                                    <div className='card_info'>
                                        № {this.state.purchase.number}
                                    </div>
                                </div>

                                <div className='card_content card_law'>
                                    <div className='card_title'>
                                        Закон
                                    </div>
                                    <div className='card_info'>
                                        {this.getLaw(this.state.purchase.law)}
                                    </div>
                                </div>

                                <div className='card_content card_method'>
                                    <div className='card_title'>
                                        Способ проведения
                                    </div>
                                    <div className='card_info' title={this.state.purchase.methodType}>
                                        {this.state.purchase.methodType}
                                    </div>
                                </div>
                            </div>

                            <div className='card_content card_adress'>
                                <div className='card_title'>
                                    Место поставки товара, выполнения работы или оказания услуги
                                </div>
                                <div className='card_info'>
                                    {this.state.purchase.sourceDeliveryAddress ?
                                        <>
                                            {this.state.purchase.sourceDeliveryAddress}
                                        </>
                                        :
                                        <Tooltip title="Информация отсутствует">
                                            <IconButton size="small">
                                                <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            </div>
                            <div className='flx info_flex'>
                                <div className='card_content card_platform'>
                                    <div className='card_title'>
                                        Площадка
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.sourcePlatform ?
                                            <>
                                                {this.state.purchase.sourcePlatform.name}
                                            </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Stack direction="row" justifyContent='space-between' spacing={1}
                                   className='purchase_stack_container'>
                                <Stack direction="row" justifyContent='flex-start' spacing={1}
                                       className='purchase_stack_button_container'>
                                    <TakePurchaseInWorkButton purchase={this.state.purchase}
                                                              updatePurchaseData={this.updatePurchaseData}/>
                                    <Button
                                        variant="outlined"
                                        onClick={() => this.props.onPurchaseTagAddCalled(this.state.purchase, this.props.enqueueSnackbar)}
                                    >
                                        Избранное
                                    </Button>
                                </Stack>
                                <Stack marginTop={'10px'} direction="row" justifyContent='space-between' spacing={1}
                                       className='purchase_stack'>
                                    {this.state.purchase.tags
                                        ? this.state.purchase.tags.map((tag, key) =>
                                            <Chip label={tag.name} key={key}/>
                                        )
                                        :
                                        null
                                    }
                                </Stack>
                            </Stack>
                        </div>

                        <div className='detail_block_section'>
                            <div className='card_content' style={{display: 'flex'}}>
                                <div className='card_purchase_desc'>
                                    <div className='card_title'>
                                        Цена
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.price ?
                                            <>
                                                {this.state.purchase.price} {this.getCurrency(this.state.purchase.currency)}
                                            </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                {
                                    this.props.currentPage
                                        ?
                                        <Tooltip title="Вернуться назад">
                                            <IconButton sx={{ml: 1}} size="small"
                                                        onClick={() => this.props.navigate(-1)}>
                                                <KeyboardReturnIcon fontSize='inherit'></KeyboardReturnIcon>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        null
                                }
                            </div>
                            <div className='price_grid'>
                                <div className='card_content'>
                                    <div className='card_title'>
                                        Предоплата
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.prepayment > 0 ?
                                            <>
                                                {this.state.purchase.prepayment} %
                                            </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <div className='card_content'>
                                    <div className='card_title'>
                                        Обеспечение заявки
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.provisionRequest ?
                                            <>
					                          <NumberFormatCustom value={this.state.purchase.provisionRequest} displayType="text" decimalScale={2} />
					                        </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <div className='card_content'>
                                    <div className='card_title'>
                                        Обеспечение контракта
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.provisionContract ?
                                            <>
					                          <NumberFormatCustom value={this.state.purchase.provisionContract} displayType="text" decimalScale={2} />
					                        </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <div className='card_content'>
                                    <div className='card_title'>
                                        Обеспечение гарантийных обязательств
                                    </div>
                                    <div className='card_info'>
                                        {this.state.purchase.provisionWarranty ?
                                            <>
					                          <NumberFormatCustom value={this.state.purchase.provisionWarranty} displayType="text" decimalScale={2} />
					                        </>
                                            :
                                            <Tooltip title="Информация отсутствует">
                                                <IconButton size="small">
                                                    <HorizontalRuleIcon fontSize='inherit'></HorizontalRuleIcon>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='flx dates'>
                                <div className='card_content card_data'>
                                    <div className='card_title'>
                                        Опубликован
                                    </div>
                                    <div className='card_info'>
                                        {this.getFormatDate(this.state.purchase.purchaseCreateAt)}
                                    </div>
                                </div>
                                <div className='card_content card_data'>
                                    <div className='card_title'>
                                        Окончание подачи
                                    </div>
                                    <div className='card_info'>
                                        {this.getFormatDate(this.state.purchase.purchaseExpirationAt)}
                                    </div>
                                </div>
                                {this.state.purchase.biddingDate ?
                                    <div className='card_content card_data'>
                                        <div className='card_title'>
                                            Дата торгов
                                        </div>
                                        <div className='card_info'>
                                            {this.getFormatDate(this.state.purchase.biddingDate)}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className='card_disclamer'>
                                Дата и время московское
                            </div>
                        </div>
                    </div>

                    <CustomerDetails customerOrganizationId={this.state.purchase.customerOrganizationId}/>
                    <PurchaseLotDetails lots={this.state.purchase.lots}/>

                    <div className='detail_block'>
                        <CardContent>
                            <Stack direction="row" alignItems="center" className='purchase_from_block'>

                                Закупка получена из
                                <Link rel="noopener noreferrer" target="_blank" href={this.state.purchase.sourceUrl}
                                      underline="hover">
                                    <IconButton sx={{ml: 1}} size="small">
                                        <OpenInNewIcon fontSize='inherit'></OpenInNewIcon>
                                    </IconButton>
                                    источника
                                </Link>
                            </Stack>
                        </CardContent>
                    </div>

                    <CustomerReviewsPanel customerOrganizationId={this.state.purchase.customerOrganizationId}/>
                </React.Fragment>
            )
        } else if (this.state.isLoading) {
            return (
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box>
            );
        }
    }
}

function mapStateToProps(state, ownProps) {
    return {
        currentCompany: state.profileState.currentCompany,
        currentPage: state.pagesState.currentPage,
        tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onPurchaseTagAddCalled: (purchase) => {
            PurchaseTagsService.getTags().then(result => {
                const action = {
                    type: 'OPEN_TAG_DIALOG',
                    selectedPurchase: purchase,
                    tags: result
                };
                dispatch(action);
            })
                .catch((error) => {
                    //todo
                });
        },
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(PurchaseDetails)));

