import React from 'react'
import { Box, Typography, Tooltip, IconButton, Link } from '@mui/material';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import settingsService from "../../services/settingsService"

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';

export const UserInfo = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const currentUserInfo = useSelector((state) => state.profileState.currentUserInfo);

    const handleSendConfirmationButton = () => {
        settingsService.sendEmailConfirmation()
            .then(() => {
                enqueueSnackbar('На указанный email отправлено письмо для подтверждения', { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            });
    }

    return (
        <React.Fragment>
            <Box>
                <Typography className='heading' component="h1" variant="h5">
                    Информация об аккаунте
                </Typography>
                <div className="settings_block settings_form_info settings_form">
                    <div className="settings_user_info_row">
                        <div className='settings_user_info_label'>
                            <span>
                                {currentUserInfo.phone_confirmed === true
                                ?
                                    <Tooltip title="Номер телефона подтвержден">
                                        <IconButton size="small">
                                            <CheckCircleOutlineOutlinedIcon className='confirmed_icon' fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                :
                                    <Tooltip title="Номер телефона не подтвержден">
                                        <IconButton size="small">
                                            <UnpublishedOutlinedIcon className='notconfirmed_icon' fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </span>
                            <span>
                                Номер телефона:
                            </span>
                        </div>
                        <div className='settings_user_info_block'>
                            {currentUserInfo.phone_number}
                        </div>
                    </div>
                    <div className="settings_user_info_row">
                        <div className='settings_user_info_label'>
                            <span>
                                {currentUserInfo.email_confirmed === true
                                ?
                                    <Tooltip title="Email подтвержден">
                                        <IconButton size="small">
                                            <CheckCircleOutlineOutlinedIcon className='confirmed_icon' fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                :
                                    <Tooltip title="Email не подтвержден">
                                        <IconButton size="small" onClick={handleSendConfirmationButton}>
                                            <UnpublishedOutlinedIcon className='notconfirmed_icon' fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </span>
                            <span>
                                E-mail адрес:
                            </span>
                            {currentUserInfo.email_confirmed === false
                                ?
                                    <Tooltip title="Отправить ссылку для подтверждения на указанный E-mail" className='confirm-email-button'>
                                        <Link style={{ textDecoration: 'none' }} href="#" variant="body2" onClick={handleSendConfirmationButton}>подтвердить</Link>
                                    </Tooltip>
                                :
                                    <></>
                            }
                        </div>
                        <div className='settings_user_info_block'>
                            {currentUserInfo.email}
                        </div>
                    </div>
                </div>
            </Box>
        </React.Fragment>
    )
}
