import Service from "./service"

class PurchasesInWorkService extends Service {
    async getPurchasesInWork(offset, count, orderBy, asc, acvId) {
      let formBody = [];
      if (offset) { formBody.push(`offset=${offset}`); }
      if (count) { formBody.push(`count=${count}`); }
      if (orderBy) { formBody.push(`orderBy=${orderBy}`); }
      if (asc) { formBody.push(`asc=${asc}`); }
      if (acvId) { formBody.push(`acvId=${acvId}`); }
      formBody = formBody.join("&");

      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions?${formBody}`, requestOptions);
    }

    async getSinglePurchaseInWork(id) {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions/${id}`, requestOptions);
    }

    async createPurchaseInWork(purchase){
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(purchase)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions`, requestOptions);
    }

    async getActivePurchasesInWork(currentCompanyId) {
      let formBody = [];
      formBody.push(`query.isWithoutStageSelected=true`);
      formBody.push(`query.stages=100`);
      formBody.push(`query.stages=200`);
      formBody.push(`query.stages=300`);
      formBody.push(`query.stages=400`);
      formBody.push(`query.stages=450`);
      formBody.push(`query.stages=500`);
      formBody.push(`query.stages=600`);
      if (currentCompanyId) {
        formBody.push(`query.acvId=${currentCompanyId}`);
      }
      formBody = formBody.join("&");

      const requestOptions = {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions/Count?${formBody}`, requestOptions);
    }

    async checkIfPurchasesInWork(acvId, purchaseNumbers){
      var inWorkCheckModel = {
        AcvId: acvId,
        Purchases: purchaseNumbers.map(c => ({ PurchaseNumber: c.number }))
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(inWorkCheckModel)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions/Check`, requestOptions);
    }

    async cancelPurchaseInWork(cancelRequestModel) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(cancelRequestModel)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/PurchaseWorkSessions/Cancel`, requestOptions);
    }
}

export default new PurchasesInWorkService();