import React, { useEffect } from "react"
import { connect } from 'react-redux';
import { useSnackbar } from "notistack";
import purchasesInWorkService from "../../services/purchasesInWorkService";
import { PurchaseInWorkItem } from "./purchase-in-work-item";
import noContentImg from "../../img/icons/no-purchases.png"

import { Box, Stack, Pagination, CircularProgress, Grid, Typography, Select, MenuItem } from '@mui/material';

const PurchaseInWorkList = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        props.onLoadingPage(props, props.currentPage, enqueueSnackbar);
    }, [props.orderBy, props.asc, props.pageSize, props.currentCompany]);
    
    const handlePaginationChange = (e, newPage) => {
        props.onLoadingPage(props, newPage, enqueueSnackbar);
    }    

    const sortingOptions = [
		{ title: 'Недавно взятые в работу', value: '{"orderBy":"CreatedAtUtc","asc":false}' },
		{ title: 'Давно взятые в работу', value: '{"orderBy":"CreatedAtUtc","asc":true}'},
		{ title: 'Сначала начальные этапы', value: '{"orderBy":"Stage","asc":true}'},
		{ title: 'Сначала конечные этапы', value: '{"orderBy":"Stage","asc":false}'},
		{ title: 'Скоро закончатся торги', value: '{"orderBy":"BiddingEndDateTimeUtc","asc":false}'},
		{ title: 'Не скоро закончатся торги', value: '{"orderBy":"BiddingEndDateTimeUtc","asc":true}'}
	]

    return (
        <React.Fragment>
            {props.isLoading 
            ? 
                <CircularProgress />
            :
                <>
                {
                    props.purchasesInWork.length > 0
                    ?
                    <>
                        <Box sx={{ mb: 2 }} className='purchase_container_header invoices_container_header'>
                            <Grid container className='purchase_container_inner'>
                                <Grid item xs={3}>
                                    <Typography>Сортировать по:</Typography>
                                    <Select 
                                        variant="standard" 
                                        size="small" 
                                        value={JSON.stringify({ orderBy: props.orderBy, asc: props.asc })}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            props.onChangeSorting(value);
                                        }}
                                    >
                                        { sortingOptions.map((option, key) => 
                                            <MenuItem value={option.value} key={key}>{option.title}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography>Закупок на странице:</Typography>
                                    <Select 
                                        variant="standard" 
                                        size="small" 
                                        value={props.pageSize}
                                        onChange={(event) => { props.onChangePageSize(event.target.value)}}
                                    >
                                        <MenuItem value={5}>5</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box className="purchase_container_items" sx={{ width: '100%' }}>
                        {
                            props.purchasesInWork.map((purchase, key) => 
                                <PurchaseInWorkItem purchaseInWork={purchase} key={key} />
                            )
                        }
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Stack spacing={2}>
                                <Pagination page={props.currentPage} count={props.pagesCount} onChange={handlePaginationChange} />
                            </Stack>
                        </Box>
                    </>
                    :
                    <Box className="no_item_block">
                        <Box className="no_item_block_icon">
                            <img src={noContentImg} alt=""/>
                        </Box>
                        <Box className="no_item_block_text_main" sx={{mt:3}}>
                            Упс... кажется закупки в работе отсутствуют.
                        </Box>
                        <Box className="no_item_block_text_second">
                            При заключении с нами договора вы сможете выбирать и добавлять закупки в совместную работу.
                            <br/>
                            Закупки в работе будут отображаться в этом разделе.
                        </Box>
                    </Box>
                }
                </>
            }
		</React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentPage: state.purchasesInWorkState.currentPage,
        pagesCount: state.purchasesInWorkState.pagesCount,
        pageSize: state.purchasesInWorkState.pageSize,
        orderBy: state.purchasesInWorkState.orderBy,
        asc: state.purchasesInWorkState.asc,
        purchasesInWork: state.purchasesInWorkState.purchasesInWork,
        isLoading: state.purchasesInWorkState.isLoading,
        currentCompany: state.profileState.currentCompany
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onLoadingPage: (state, pageNumber, enqueueSnackbar) => {
            const pageAction = {
                type: 'CURRENT_PAGE_CHANGED',
                currentPage: 'purchases-in-work'
            };
            dispatch(pageAction);
            if (state.currentCompany) {
                const action = {
                    type: 'PURCHASE_IN_WORK_LOAD_STARTED'
                };
                dispatch(action);
                if (state.currentCompany.Id) {
                    purchasesInWorkService.getPurchasesInWork(pageNumber, state.pageSize, state.orderBy, state.asc, state.currentCompany.Id)
                        .then((result) => {
                            const action = {
                                type: 'PURCHASE_IN_WORK_PAGE_LOADED', 
                                pagesCount: result.TotalPages,
                                currentPage: result.PageNumber,
                                purchasesInWork: result.Items
                            };
                            dispatch(action);
                        })
                        .catch((error) => {
                            enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                            const action = {
                                type: 'PURCHASE_IN_WORK_LOAD_ERROR'
                            };
                            dispatch(action);
                        });
                } else {
                    const action = {
                        type: 'PURCHASE_IN_WORK_PAGE_LOADED', 
                        pagesCount: 1,
                        currentPage: 0,
                        purchasesInWork: []
                    };
                    dispatch(action);
                }
            }
        },
        onChangeSorting: (value) => {
            const action = {
                type: 'PURCHASE_IN_WORK_SORTING_CHANGED',
                value: value
            };
            dispatch(action);
        },
        onChangePageSize: (value) => {
            const action = {
                type: 'PURCHASE_IN_WORK_PAGE_SIZE_CHANGED',
                value: value
            };
            dispatch(action);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInWorkList)
