import React, {Component} from 'react';
import {connect} from 'react-redux';
import TemplateService from '../../services/search-purchases-services/TemplateService.js';
import {withSnackbar} from 'notistack';

// MUI:
import {Box, Modal, TextField} from '@mui/material';
import {ButtonWithWaitForCompletion} from '../button-with-wait-for-completion.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

class SaveTemplateModal extends Component {

    render() {
        //const errorDivs = this.props.newTagValidationErrors.map((err, key) => <Grid item xs={12} sx={{color:'red'}} key={key}>{err}</Grid>);
        return (
            <React.Fragment>
                <Modal
                    open={this.props.openSaveTemplateDialog}
                    onClose={this.props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal_body">
                        <h2 className='modal_header'>
                            Название шаблона
                        </h2>
                        <TextField
                            id="templateName"
                            variant="outlined"
                            fullWidth
                            sx={{mt: 1}}
                            onChange={(event) => {
                                var name = event.target.value;
                                this.setState({name: name});
                            }}
                        />
                        <ButtonWithWaitForCompletion
                            sx={{mt: 2}}
                            onClick={() => {
                                return this.props.onSaveTemplate({
                                    name: this.state.name,
                                    keywords: this.props.keywords,
                                    ignoreKeywords: this.props.ignoreKeywords,
                                    shouldSearchInDocuments: this.props.shouldSearchInDocuments,
                                    havePrepayment: this.props.havePrepayment,
                                    haveRecommendation: this.props.haveRecommendation,
                                    laws: this.props.laws,
                                    datePublicationFrom: this.props.datePublicationFrom,
                                    datePublicationTo: this.props.datePublicationTo,
                                    dateExpirationFrom: this.props.dateExpirationFrom,
                                    priceFrom: this.props.priceFrom,
                                    priceTo: this.props.priceTo,
                                    stages: this.props.stages,
                                    kladrCodes: this.props.kladrCodes,
                                    auctionTypes: this.props.auctionTypes,
                                    okpdCodes: this.props.okpdCodes.map((code) => {
                                        return {key: code.key, children: code.children.flatMap((child) => child.key)}
                                    }),
                                    industryCodes: this.props.industryCodes,
                                    customers: this.props.customers,
                                }, this.props.enqueueSnackbar)
                            }}
                            variant="outlined"
                        >
                            Сохранить
                        </ButtonWithWaitForCompletion>
                    </Box>
                </Modal>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        openSaveTemplateDialog: state.saveTemplateModalState.openSaveTemplateDialog,
        keywords: state.searchPurchasesState.keywords,
        ignoreKeywords: state.searchPurchasesState.ignoreKeywords,
        shouldSearchInDocuments: state.searchPurchasesState.shouldSearchInDocuments,
        havePrepayment: state.searchPurchasesState.havePrepayment,
        haveRecommendation: state.searchPurchasesState.haveRecommendation,
        laws: state.searchPurchasesState.laws,
        datePublicationFrom: state.searchPurchasesState.datePublicationFrom,
        datePublicationTo: state.searchPurchasesState.datePublicationTo,
        dateExpirationFrom: state.searchPurchasesState.dateExpirationFrom,
        priceFrom: state.searchPurchasesState.priceFrom,
        priceTo: state.searchPurchasesState.priceTo,
        stages: state.searchPurchasesState.stages,
        kladrCodes: state.searchPurchasesState.kladrCodes,
        auctionTypes: state.searchPurchasesState.auctionTypes,
        okpdCodes: state.searchPurchasesState.okpdCodes,
        industryCodes: state.searchPurchasesState.industryCodes,
        customers: state.searchPurchasesState.customers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onClose: () => {
            const action = {
                type: 'CLOSE_SAVE_TEMPLATE_DIALOG'
            };
            dispatch(action);
        },
        onSaveTemplate: async (template, enqueueSnackbar) => {
            return TemplateService.add(template).then(result => {
                enqueueSnackbar(
                    'Шаблон успешно сохранен',
                    {
                        variant: 'success'
                    });
                TemplateService.getAll()
                    .then(templates => {
                        const reset_action = {
                            type: 'RESET_TEMPLATES'
                        };
                        dispatch(reset_action);

                        const action = {
                            type: 'GET_TEMPLATES',
                            templates: templates
                        };
                        dispatch(action);
                    })
                    .catch(error => {
                        enqueueSnackbar(
                            'Произошла ошибка при получении списка шаблонов',
                            {
                                variant: 'error'
                            });
                    });
                const action = {
                    type: 'CLOSE_SAVE_TEMPLATE_DIALOG'
                };
                dispatch(action);
            })
                .catch(error => {
                    enqueueSnackbar(
                        'Произошла ошибка при добавлении шаблона',
                        {
                            variant: 'error'
                        });
                })
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(SaveTemplateModal));
