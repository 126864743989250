import React, { useEffect, useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import mailingsService from "../../services/mailingsService"
import { CircularProgress, Button, Chip, FormControlLabel, TextField, Checkbox, Box, Tooltip, IconButton, InputAdornment } from "@mui/material"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSnackbar } from 'notistack';
import { OkpdSelection } from "../okpd-selection-static";
import { SettlementsSelection } from "../settlements-selection";
import { FindTopCheckedItems } from '../../helpers/tree-helper';
import { NumberFormatCustom } from '../number-format-custom';
import okpdCodesFromJson from '../../json/code3.json'
import { MapKladrCodeFromSpToCrm, MapKladrCodeFromCrmToSp } from '../../mappers/kladr-code-items-mapper';
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export const MailingsEdit = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [currentMailing, setCurrentMailing] = useState(null);
    const [currentMailingKladrCodes, setCurrentMailingKladrCodes] = useState([]);
    const [openOkpdSelectionModal, setOpenOkpdSelectionModal] = useState(false);
    const [topCheckedOkpdCodes, setTopCheckedOkpdCodes] = useState([]);
    const [checkedOkpdCodes, setCheckedOkpdCodes] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [expandStage, setExpandStage] = useState(true)
    const [expandSourcingMethod, setExpandSourcingMethod] = useState(true)
    const [expandBiddingType, setExpandBiddingType] = useState(true)

    const currentCompany = useSelector((state) => state.profileState.currentCompany);

    useEffect(() => {
        setCurrentMailing({ ...props.mailing });
        setCurrentMailingKladrCodes(MapKladrCodeFromCrmToSp(props.mailing.KladrCodeItems));
        setCheckedOkpdCodes(props.mailing.Okpd2Codes.map((code) => { return code.Code;}));
    }, [props.mailing])

    useEffect(() => {
        if (okpdCodesFromJson && okpdCodesFromJson.length > 0 && checkedOkpdCodes) {
            const topCheckedOkpdCodes = FindTopCheckedItems(checkedOkpdCodes, okpdCodesFromJson);
            setTopCheckedOkpdCodes(topCheckedOkpdCodes);
        }
    }, [checkedOkpdCodes])

    const handleSaveMailing = () => {
        setIsSaving(true);
        let updatedMailing = { 
            ...currentMailing, 
            KladrCodeItems: MapKladrCodeFromSpToCrm(currentMailingKladrCodes)
        };
        if (currentMailing.Id) {
            mailingsService.update(currentCompany.Id, updatedMailing)
            .then(() => {
                enqueueSnackbar('Рассылка успешно обновлена', { variant: "success" });
                props.onSave();
            })
            .catch(() => {
                enqueueSnackbar('При обновлении рассылки возникла ошибка', { variant: "error" });
            })
            .finally(() => {
                setIsSaving(false);
            })
        } else {
            mailingsService.add(currentCompany.Id, updatedMailing)
            .then(() => {
                enqueueSnackbar('Рассылка успешно добавлена', { variant: "success" });
                props.onSave();
            })
            .catch(() => {
                enqueueSnackbar('При добавлении рассылки возникла ошибка', { variant: "error" });
            })
            .finally(() => {
                setIsSaving(false);
            })
        }
    }

    const handleCloseOkpdSelectionModal = () => {
        setOpenOkpdSelectionModal(false);
    }

    const handleSaveOkpdSelectionModal = (checkedKeys) => {
        setCheckedOkpdCodes(checkedKeys);
        setCurrentMailing({...currentMailing, Okpd2Codes: checkedKeys.map(checkedKey => { return {Code: checkedKey}}) })
        setOpenOkpdSelectionModal(false);
    }

    const handleMailingChange = useCallback((mailing) => {
        if (props.mailing.AllowEditToClient) {
            setCurrentMailing({...mailing});
        }
    }, [props.mailing.AllowEditToClient]);

    const handleRedirectToSearch = (mailing, topOkpdCodes) => {
        const action = {
            type: 'MAILING_REDIRECTED_TO_SEARCH',
            mailing: { ...mailing, topOkpdCodes: topOkpdCodes }
        };
        dispatch(action);
        navigate("/search");
    };

    return (
        <React.Fragment>
            {
                currentMailing
                ?
                <form onSubmit={(event) => {
                    event.preventDefault();
                    handleSaveMailing();
                }}>
                    <OkpdSelection open={openOkpdSelectionModal} onClose={handleCloseOkpdSelectionModal} onSave={handleSaveOkpdSelectionModal} checkedKeys={checkedOkpdCodes} />
                    <div style={{ display: "block" }}>
                        <div className="filter_grid filter_mailing">
                            <div className='filter_block'>
                                <div className='filter_block_header'>
                                    Название рассылки
                                </div>
                                <TextField
                                    inputProps={{ readOnly: !props.mailing.AllowEditToClient }}
                                    id="name"
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event) => handleMailingChange({...currentMailing, Name: event.target.value})}
                                    value={currentMailing.Name}
                                    required
                                    placeholder="Введите название рассылки"
                                />
                            </div>

                            <div className='filter_grid1'>
                                <div className='filter_block filter_keywords'>
                                    <div className='filter_block_header'>
                                        Ключевые слова
                                    </div>
                                    <TextField
                                        inputProps={{ 
                                            readOnly: !props.mailing.AllowEditToClient,
                                            style: { 
                                                padding: 0
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="start">
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    title={
                                                    <div style={{ whiteSpace: 'pre-line' }}>
                                                    { 
                                                        "Чтобы найти все закупки по нескольким ключевым словам, необходимо указать их через пробел или запятую.\r\n "+
                                                        "Пробел — найдет закупки, которые содержат словосочетание в пределах 3-4 слов. (пример: строительные материалы — результат: материалы и конструкции строительные)\r\n \r\n "+
                                                        "Запятая — найдет закупки, которые содержат одно из ключевых слов. Работает как «или». (пример: лес, дерево — результат: закупки где содержится ИЛИ лес ИЛИ дерево)\r\n \r\n "+ 
                                                        "Дополнительно:\r\n * (символ звёздочки) заменит любое количество любых символов\r\n (пример: газ* — результат: газообразный, газель; пример: запрос бе*ый — белый, бешеный)\r\n \r\n "+ 
                                                        "? (знак вопроса) заменит один символ (пример: ию?ь — вернет июль, июнь; пример: ?аска вернет маска, ласка)\r\n \r\n "+
                                                        "Для ввода дробных чисел использовать символ точки вместо запятой.\r\n \r\n неправильно: 0,4 кВ \r\n правильно: 0.4 кВ \r\n \r\n Все символы (, ? *) можно комбинировать друг с другом."
                                                    }
                                                    </div>
                                                  }>
                                                  <IconButton size="small">
                                                    <HelpOutlineIcon fontSize="inherit" />
                                                  </IconButton>
                                                </Tooltip>
                                              </InputAdornment>
                                            ),
                                        }}
                                        id="keywords"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onChange={(event) => handleMailingChange({...currentMailing, Keywords: event.target.value})}
                                        value={currentMailing.Keywords ?? ''}
                                        placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                    />
                                </div>
                                <div className='filter_block filter_stopwords'>
                                    <div className='filter_block_header'>
                                        Исключать слова
                                    </div>
                                    <TextField
                                        inputProps={{ 
                                            readOnly: !props.mailing.AllowEditToClient,
                                            style: { 
                                                padding: 0
                                            }
                                        }}
                                        id="exclude_keywords"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        onChange={(event) => handleMailingChange({...currentMailing, ExcludeKeywords: event.target.value})}
                                        value={currentMailing.ExcludeKeywords ?? ''}
                                        placeholder="Например: лыжи, лыжные палки, 36.40.11.133"
                                    />
                                </div>
                                <div className="fiter_keywords_buttons">
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        className='filter_check lonely_check'
                                        label="Искать в приложенных документах"
                                        onChange={(event) => handleMailingChange({...currentMailing, SearchKeywordsInDocuments: event.target.checked })}
                                        checked={currentMailing.SearchKeywordsInDocuments}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        className='filter_check lonely_check'
                                        label="Искать в наименованиях ОКПД2"
                                        onChange={(event) => handleMailingChange({...currentMailing, SearchKeywordsInOkpdNames: event.target.checked })}
                                        checked={currentMailing.SearchKeywordsInOkpdNames}
                                    />
                                </div>
                            </div>
            
                            <div className='filter_block filter_block_region'>
                                <div className='filter_block_header'>
                                    Регион поставки
                                </div>
                                <SettlementsSelection kladrCodes={currentMailingKladrCodes} readOnly={!props.mailing.AllowEditToClient} onKladrCodesChanges={(settlements) => {
                                    setCurrentMailingKladrCodes(settlements);
                                }
                                 } />
                            </div>

                            <div className='filter_grid4'>
                                <div className='filter_block'>
                                    <div className='filter_block_header'>
                                        Начальная цена
                                    </div>
                                    <div className='filter_flex_block'>
                                        <TextField
                                            value={currentMailing.MinContractPrice}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MinContractPrice: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="min_contract_price"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            –
                                        </Box>
                                        <TextField
                                            value={currentMailing.MaxContractPrice}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MaxContractPrice: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="max_contract_price"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            ₽
                                        </Box>
                                    </div>
                                </div>
                                <div className='filter_block'>
                                    <div className='filter_block_header'>
                                        Обеспечение договора
                                    </div>
                                    <div className='filter_flex_block'>
                                        <TextField
                                            value={currentMailing.MinContractGuarantee}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MinContractGuarantee: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="min_contract_guarantee"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            –
                                        </Box>
                                        <TextField
                                            value={currentMailing.MaxContractGuarantee}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MaxContractGuarantee: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="max_contract_guarantee"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            ₽
                                        </Box>
                                    </div>
                                </div>
                                <div className='filter_block'>
                                    <div className='filter_block_header'>
                                        Обеспечение заявки
                                    </div>
                                    <div className='filter_flex_block'>
                                        <TextField
                                            value={currentMailing.MinApplicationGuarantee}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MinApplicationGuarantee: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="min_application_guarantee"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            –
                                        </Box>
                                        <TextField
                                            value={currentMailing.MaxApplicationGuarantee}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, MaxApplicationGuarantee: event.target.value });
                                            }}
                                            name="numberformat"
                                            placeholder="0"
                                            id="max_application_guarantee"
                                            InputProps={{
                                                readOnly: !props.mailing.AllowEditToClient,
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                        />
                                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                                            ₽
                                        </Box>
                                    </div>
                                </div>
                            </div>

                            <div className='filter_grid4'>
                                <div className='filter_block'>
                                    <div className='filter_block_header'>
                                        До конца подачи дней не меньше чем:
                                    </div>
                                    <div className='filter_flex_block'>
                                        { <TextField
                                            value={currentMailing.DaysLeftTillCollectingEnd ? currentMailing.DaysLeftTillCollectingEnd : ''}
                                            onChange={(event) => {
                                                handleMailingChange({...currentMailing, DaysLeftTillCollectingEnd: event.target.value });
                                            }}
                                            name="numberformat"
                                            id="days_left_till_collecting_end"
                                            variant="outlined"
                                            type="number"
                                            inputProps={{ 
                                                readOnly: !props.mailing.AllowEditToClient,
                                                min: 1, 
                                                max: 365
                                            }}
                                            placeholder="Кол-во дней"
                                        /> }
                                    </div>
                                </div>
                                <div className="filter_block filter_block_checkboxes">
                                    <div>
                                        <Tooltip title="Искать закупки в которых не указана цена контракта">
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                className='filter_check lonely_check'
                                                label="Включая закупки без НМЦ"
                                                onChange={(event) => handleMailingChange({...currentMailing, IncludeWithoutContractPrice: event.target.checked })}
                                                checked={currentMailing.IncludeWithoutContractPrice}
                                            />
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <FormControlLabel
                                        control={<Checkbox />}
                                        className='filter_check lonely_check'
                                        label="Наличие аванса"
                                        onChange={(event) => handleMailingChange({...currentMailing, HasPrepayment: event.target.checked })}
                                        checked={currentMailing.HasPrepayment}
                                        />
                                    </div>
                                </div>
                            </div>

                            {window.innerWidth < 1024 ?
                                <div className='filter_grid2'>
                                    <div className='filter_block filter_block_etap'>
                                        <div className='filter_block_header filter_block_header_mobile' onClick={() => setExpandStage(prev => !prev)}>
                                            Этап
                                            {expandStage ? <ExpandLess style={{width: "18px", height: "18px"}}/> : <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                        </div>
                                        {expandStage ? <div className='filter_check_grid'>
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Подача заявок"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusStarted: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusStarted}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Работа комиссии"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusInProgress: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusInProgress}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Закупка завершена"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusCompleted: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusCompleted}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Закупка отменена"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusCancelled: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusCancelled}
                                            />
                                        </div> : null}
                                    </div>
                                    <div className='filter_block filter_block_method'>
                                        <div className='filter_block_header filter_block_header_mobile' onClick={() => setExpandSourcingMethod(prev => !prev)}>
                                            Метод определения поставщика
                                            {expandSourcingMethod ? <ExpandLess style={{width: "18px", height: "18px"}}/> : <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                        </div>
                                        {expandSourcingMethod ?
                                            <div className='filter_check_grid'>
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Электронный аукцион"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, EaAuctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.EaAuctions}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Запрос котировок"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, ZkAuctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.ZkAuctions}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Конкурс"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, KoAuctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.KoAuctions}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Закупка у единственного поставщика"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, EpAuctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.EpAuctions}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Электронный аукцион (ПП РФ 615)"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, Ea615Auctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.Ea615Auctions}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Другие способы"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, OtherAuctions: event.target.checked });
                                                    }}
                                                    checked={currentMailing.OtherAuctions}
                                                />
                                            </div>
                                            : null}

                                    </div>
                                    <div className='filter_block filter_block_type'>
                                        <div className='filter_block_header filter_block_header_mobile' onClick={() => setExpandBiddingType(prev => !prev)}>
                                            Тип торгов
                                            {expandBiddingType ? <ExpandLess style={{width: "18px", height: "18px"}}/> : <ExpandMore style={{width: "18px", height: "18px"}}/>}
                                        </div>
                                        {expandBiddingType ?
                                            <div className='filter_check_grid'>
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="44-ФЗ"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, Purchases44: event.target.checked });
                                                    }}
                                                    checked={currentMailing.Purchases44}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="223-ФЗ"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, Purchases223: event.target.checked });
                                                    }}
                                                    checked={currentMailing.Purchases223}
                                                />
                                                <FormControlLabel
                                                    className='filter_check'
                                                    control={<Checkbox />}
                                                    label="Коммерческие"
                                                    onChange={(event) => {
                                                        handleMailingChange({...currentMailing, Commercial: event.target.checked });
                                                    }}
                                                    checked={currentMailing.Commercial}
                                                />
                                            </div>
                                            : null}
                                    </div>
                                </div> :
                                <div className='filter_grid2'>
                                    <div className='filter_block filter_block_etap'>
                                        <div className='filter_block_header'>
                                            Этап
                                        </div>
                                        <div className='filter_check_grid'>
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Подача заявок"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusStarted: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusStarted}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Работа комиссии"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusInProgress: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusInProgress}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Закупка завершена"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusCompleted: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusCompleted}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Закупка отменена"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, PurchaseStatusCancelled: event.target.checked });
                                                }}
                                                checked={currentMailing.PurchaseStatusCancelled}
                                            />
                                        </div>
                                    </div>
                                    <div className='filter_block filter_block_method'>
                                        <div className='filter_block_header'>
                                            Метод определения поставщика
                                        </div>
                                        <div className='filter_check_grid'>
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Электронный аукцион"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, EaAuctions: event.target.checked });
                                                }}
                                                checked={currentMailing.EaAuctions}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Запрос котировок"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, ZkAuctions: event.target.checked });
                                                }}
                                                checked={currentMailing.ZkAuctions}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Конкурс"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, KoAuctions: event.target.checked });
                                                }}
                                                checked={currentMailing.KoAuctions}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Закупка у единственного поставщика"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, EpAuctions: event.target.checked });
                                                }}
                                                checked={currentMailing.EpAuctions}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Электронный аукцион (ПП РФ 615)"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, Ea615Auctions: event.target.checked });
                                                }}
                                                checked={currentMailing.Ea615Auctions}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Другие способы"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, OtherAuctions: event.target.checked });
                                                }}
                                                checked={currentMailing.OtherAuctions}
                                            />
                                        </div>
                                    </div>
                                    <div className='filter_block filter_block_type'>
                                        <div className='filter_block_header'>
                                            Тип торгов
                                        </div>
                                        <div className='filter_check_grid'>
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="44-ФЗ"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, Purchases44: event.target.checked });
                                                }}
                                                checked={currentMailing.Purchases44}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="223-ФЗ"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, Purchases223: event.target.checked });
                                                }}
                                                checked={currentMailing.Purchases223}
                                            />
                                            <FormControlLabel
                                                className='filter_check'
                                                control={<Checkbox />}
                                                label="Коммерческие"
                                                onChange={(event) => {
                                                    handleMailingChange({...currentMailing, Commercial: event.target.checked });
                                                }}
                                                checked={currentMailing.Commercial}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="mailing_edit_buttons_block">
                                <div className="mailing_edit_okpd_block">
                                    {
                                        !props.mailing.AllowEditToClient 
                                        ?
                                        <div className="mailings_readonly_okpd2_title">
                                            Выбранные ОКПД2:
                                        </div>
                                        :
                                        <div>
                                            <Button type="button" variant="outlined" onClick={() => setOpenOkpdSelectionModal(true)}>Выбор ОКПД2</Button>
                                        </div>
                                    }
                                    <div>
                                    { 
                                        topCheckedOkpdCodes.map((okpd, key) =>
                                            <Chip sx={{marginLeft:'5px'}} label={okpd.key} key={key} />
                                        )
                                    }
                                    </div>
                                </div>
                                <div>
                                    <Button type="button" color="info" style={{ float: "right"}} onClick={props.onClose}>Закрыть</Button>
                                    {isSaving 
                                    ?
                                        <Button color="success" style={{ float: "right"}} disabled>Идет сохранение...</Button>
                                    :
                                        props.mailing.AllowEditToClient
                                        ?
                                            <Button type="submit" color="success" style={{ float: "right"}}>Сохранить</Button>
                                        :
                                            null
                                    }
                                    {
                                        props.mailing.Id 
                                        ?
                                            <Button type="button" color="info" onClick={() => handleRedirectToSearch(currentMailing, topCheckedOkpdCodes)}>Перейти в поисковик</Button>
                                        :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
                :
                <CircularProgress />
            }
        </React.Fragment>
    )
}