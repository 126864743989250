import React from "react"
import { Link } from '@mui/material'; 
import { useSnackbar } from "notistack";

//props
//text - текст ссылки
export const OpenChatLink = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    return (
        <Link 
            href="#" 
            rel="noopener noreferrer" 
            underline="hover" 
            onClick={(event) => {
                event.preventDefault();
                if (window.carrotquest){
                    window.carrotquest.open();
                } else {
                    enqueueSnackbar('Чат не подключен либо блокируется браузером или расширением', { variant: 'error'});
                }
            }} 
        >
            {props.text}
        </Link>
    )
}
