import React, {useState} from 'react'
import {Autocomplete, Chip, TextField} from "@mui/material";
import CustomerService from "../services/search-purchases-services/CustomerService";
import {useSnackbar} from "notistack";

export const CustomerSelection = (props) => {

    const [customers, setCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customersInputTimeout, setCustomersInputTimeout] = useState(null);

    const {enqueueSnackbar} = useSnackbar();

    return (
        <Autocomplete
            readOnly={props.readOnly}
            multiple
            options={customers}
            freeSolo
            clearOnBlur
            forcePopupIcon={false}
            autoHighlight
            loading={isLoading}
            getOptionLabel={(option) => `${option.name} ${option.inn}`}
            renderOption={(params, option) => {
                if (!props.customers.some(val => val?.id === option.id)) {
                    return (
                        <li {...params} key={option.id}
                            style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                            {option.name}
                            {option.inn ?
                                <span style={{color: "#aeb3b7"}}>
                                ИНН: {option.inn}
                            </span> : null}
                        </li>
                    )
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Наименование или ИНН заказчика"
                    onChange={(e) => {
                        const value = e.target.value

                        if (value) {
                            if (customersInputTimeout !== null) {
                                clearTimeout(customersInputTimeout);
                            }
                            setCustomersInputTimeout(setTimeout(() => {
                                setIsLoading(true)
                                CustomerService.search(value)
                                    .then(data => {
                                        if (data?.collections) {
                                            setCustomers(data?.collections)
                                        }

                                    })
                                    .catch(e => {
                                        enqueueSnackbar(
                                            'Произошла ошибка при получении списка заказчиков',
                                            {
                                                variant: 'error'
                                            });
                                    })
                                    .finally(() => setIsLoading(false))
                            }, 500))
                        } else {
                            clearTimeout(customersInputTimeout);
                            setCustomers([])
                        }


                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && customers.length === 0) {
                            event.stopPropagation();
                        }
                    }}
                />
            )}
            renderTags={(values) =>
                values.map((value, key) =>
                    <Chip
                        key={value.id}
                        onDelete={() => {
                            const updatedCustomers = [...values];
                            updatedCustomers.splice(key, 1);
                            props.onCustomersChange(updatedCustomers)
                        }}
                        label={value.name}
                    />
                )
            }

            onChange={(e, options) => {
                props.onCustomersChange(options)
            }}
            onClose={() => {
                setCustomers([])
            }}

            onKeyDown={(event) => {
                if (event.key === 'Enter' && customers.length === 0) {
                    event.stopPropagation();
                }
            }}
            value={props.customers}
        />

    )
}
