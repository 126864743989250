import React, { useState } from "react"
import { FormGroup, Autocomplete, TextField } from "@mui/material"
import { useSnackbar } from "notistack";

import companiesService from "../services/companiesService";

export const FindCompanyInput = (props) => {
    const [companies, setCompanies] = useState([]);
    const [companySearchLoading, setCompanySearchLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [ valueSelected, setValueSelected ] = useState(props.value ? true : false);

    return (
        <React.Fragment>
            <FormGroup>
                <Autocomplete
                    freeSolo
                    clearOnBlur
                    options={companies}
                    filterOptions={(options) => options}
                    renderOption={(props, option) => (
                        <li {...props}>
                            {option.Name}
                        </li>
                    )}
                    getOptionLabel={(option) => {
                        if (props.showName === true) {
                            return option.Name;
                        }
                        else {
                            return option.TaxPayerNumber;
                        }
                    } }
                    getOptionDisabled={(option) => 
                        option.TaxPayerNumber == null
                    }
                    loading = {companySearchLoading}
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        margin="normal"
                        inputProps={{ ...params.inputProps, readOnly: valueSelected }}
                        placeholder="Введите ИНН компании и выберите ее из списка"
                        onChange={(event) => {
                            const value = event.target.value;
                            if (value.length === 10 || value.length === 12) {      
                                setCompanies([]);
                                setCompanySearchLoading(true);  
                                companiesService.getCompany(value)
                                    .then((result) => {
                                        if (result.length > 0){
                                            setCompanies(result);
                                        }
                                        else {
                                            setCompanies([{"Name": "Не найдено компании с таким ИНН"}])
                                        }
                                        setCompanySearchLoading(false);
                                    })
                                    .catch((error) => {
                                        enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                                    })           
                            }
                            else if (value.length !== 10 && value.length !== 12) {
                                setCompanies([{"Name": "Введен некорректный ИНН"}]);
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && companies.length === 0) {
                                event.stopPropagation();
                            }
                        }}
                        />
                    )}
                    onChange={(event, value) => {
                        if (value) {
                            setValueSelected(true);
                        } else {
                            setValueSelected(false);
                        }
                        props.onChange(event, value);
                    }}
                    onClose={(event, reason) => {
                        setCompanies([]);
                    }}
                    value={props.value}
                />
            </FormGroup>
		</React.Fragment>
    )
}