import Service from "./service"

class SettingsService extends Service {
    async sendEmailConfirmation() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      }

      await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Confirm/Email`, requestOptions);
    }

    async sendUpdateRequest(formValues) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      }

      return this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Update/Request`, requestOptions);
    }

    async sendUpdatePassword(formValues) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      }

      return this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Update/Password`, requestOptions);
    }

    async sendUpdateEmail(formValues) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      }
      
      return this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Update/Email`, requestOptions);
    }

    async sendUpdatePhoneNumber(formValues) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      }

      return this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Update/Phone`, requestOptions);
    }

    async checkIsAllowedToUpdateInfo() {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': this.getAuthorizationToken()
        }
      }

      return this.authenticatedFetchWithBooleanResult(`${process.env.REACT_APP_PORTAL_API_URI}/Account/IsAllowedToUpdateInfo`, requestOptions);
    }

    async sendPhoneConfirmation(phoneNumber, type) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Accept': 'application/json'
        }
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Phone/Token/${encodeURIComponent(phoneNumber)}/${type}`, requestOptions); 
    }

    async sendPhoneConfirmationAuthorized(type) {
      const requestOptions = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Accept': 'application/json'
        }
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Phone/Token/${type}`, requestOptions); 
    }

    async confirmEmail(form) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(form)
      };

      return await this.authenticatedFetch(`${process.env.REACT_APP_PORTAL_API_URI}/Account/Confirm/Email`, requestOptions);
    }

    async checkPhoneAvailability(phone) {
      const requestOptions = {
        method: 'GET'
      };

      return fetch(`${process.env.REACT_APP_PORTAL_IDENTITY_URI}/api/account/availability/phone?PhoneNumber=${encodeURIComponent(phone)}`, requestOptions)
        .then(res => res.json());
    }
}

export default new SettingsService();