import React, { useEffect, useState } from "react"
import { Button, Typography, Box, Link, CircularProgress } from "@mui/material"
import { connect } from 'react-redux';
import CompanyItem from "./company-item";
import { AddCompanyModal } from "./add-company-modal";
import { useSnackbar } from "notistack";
import { OpenChatLink } from '../open-chat-link';

import companiesService from "../../services/companiesService";

const CompaniesList = (props) => {
    const [companies, setCompanies] = useState([]);
    const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        refreshUserCompanies();
    },[]);

    const refreshUserCompanies = async () => {
        setIsLoading(true);
        companiesService.getUserCompanies()
        .then((result) => {
            setIsLoading(false);
            setCompanies(result);
            props.onReloadingCompanies(result);
        })
        .catch((error) => {
            enqueueSnackbar(`Произошла ошибка при попытке получить список компаний`, { variant: "error" });
        })
    }

    const handleAddCompanyCreate = () => {
        setShowAddCompanyModal(false);
        refreshUserCompanies();
    }

    const handleAddCompanyModalClose = () => {
        setShowAddCompanyModal(false);
    }

    const handleCompanyEdit = (companyModel, isAllowedToEdit) => {
        if (isAllowedToEdit === true) {
            companiesService.editCompany(companyModel)
                .then(() => {
                    enqueueSnackbar('Данные о компании успешно обновлены', { variant: "success" });
                })
                .catch((error) => {
                    enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                })
                .finally(() => {
                    refreshUserCompanies();
                })
        }
        else {
            companiesService.editCompanyRequest(companyModel)
                .then(() => {
                    enqueueSnackbar('Запрос на обновление данных о компании успешно отправлен', { variant: "success" });
                })
                .catch((error) => {
                    enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                })
                .finally(() => {
                    refreshUserCompanies();
                })
        }
    }

    const handleCompanyRemove = (companyModel, isAllowedToRemove) => {
        if (isAllowedToRemove === true) {
            companiesService.removeCompany(companyModel)
                .then(() => {
                    enqueueSnackbar('Данные о компании успешно удалены', { variant: "success" });
                })
                .catch((error) => {
                    enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                })
                .finally(() => {
                    //TODO хочу переделать
                    //Компанию ставим null чтобы сработал useEffect в company-overlay
                    if (companies.length === 1 || companyModel.Id === props.currentCompany.Id) {
                        props.onSelectingCurrentCompany(null);
                    }
                    refreshUserCompanies();
                })
        }
        else {
            companiesService.removeCompanyRequest(companyModel)
                .then(() => {
                    enqueueSnackbar('Запрос на удаление данных о компании успешно отправлен', { variant: "success" });
                })
                .catch((error) => {
                    enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
                })
                .finally(() => {
                    refreshUserCompanies();
                })
        }
    }

    return (
        <React.Fragment>
            <AddCompanyModal open={showAddCompanyModal} onSelect={handleAddCompanyCreate} onClose={handleAddCompanyModalClose} />
            <Box>
                <Typography className="heading" component="h1" variant="h5">
                    Компании
                </Typography>
            </Box>
            <Box className="settings_block settings_form">
                {
                    isLoading 
                    ? 
                        <CircularProgress />
                    :
                        companies.length > 0
                        ? 
                            <>
                                <Box className="companies_grid">
                                    {
                                        companies.map((company, key) => {
                                            return <CompanyItem company={company} key={key} onRemove={handleCompanyRemove} onEdit={handleCompanyEdit} />
                                        })
                                    }
                                </Box>
                                <Button
                                    variant="contained"
                                    className="settings_form_submit"
                                    onClick={() => {setShowAddCompanyModal(true)}}
                                >
                                    Добавить компанию
                                </Button>
                            </>
                        :
                            <Box className="companies_grid_no_companies">
                                <Box className="companies_grid_no_companies_title">На данный момент у вас нет ни одной компании.</Box>
                                Если у вас нет своего ИП или ЮО, то мы с радостью готовы <OpenChatLink text="помочь" /> вам в этом вопросе.
                                <br/>
                                Если у вас уже есть своя компания, то вы можете самостоятельно <Link href="#" onClick={() => {setShowAddCompanyModal(true)}}>добавить</Link> о ней информацию. Это позволит получить доступ к большему функционалу в вашем личном кабинете.
                                <br/>
                            </Box>
                }
            </Box>
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentCompany: state.profileState.currentCompany
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSelectingCurrentCompany: (selectedCompany) => {
            const action = {
                type: 'CURRENT_COMPANY_CHANGED',
                currentCompany: selectedCompany
            };
            dispatch(action);
        },
        onReloadingCompanies: (companies) => {
            const action = {
                type: 'ACTIVE_COMPANIES_OBTAINED',
                activeCompanies: companies
            };
            dispatch(action);
        }
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesList)