import React from "react"
import { Button, CircularProgress } from "@mui/material"
import { DateTime, FixedOffsetZone } from 'luxon';
import purchasesInWorkService from '../../services/purchasesInWorkService';
import purchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CheckIsCompanyWithContractWrapper } from "../check-is-company-with-contract-wrapper";
import { ConfirmationDialog } from "../confirmation-dialog";

const TakePurchaseInWorkButton = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    //маппинг law из поисковика в Type из срм
    const getPurchaseType = (law) => {
      //44 и 615
      if (law === 1 || law === 4) {
        return 0;
      }
      //223
      if (law === 2) {
        return 1;
      }
      //коммерческие
      if (law === 3) {
        return 2;
      }

      enqueueSnackbar(`Произошла ошибка определения типа торгов`, { variant: "error" });
    }

    const getDateWithOffset = (inputDate, offsetInHours) => {
      if (!offsetInHours) {
        return inputDate;
      }

      const date = DateTime.fromISO(inputDate);
      const formattedDate = date.plus({hours:offsetInHours}).toUTC().toISO();
  
      return formattedDate;
    }

    const handleCreatePurchaseInWork = () => {
      const purchaseToCreate = {
        PurchaseNumber: props.purchase.number,
        Type: getPurchaseType(props.purchase.law),
        PurchaseObject: props.purchase.title,
        PurchaseUrl: props.purchase.sourceUrl,
        //TODO replace переделать?
        Price: props.purchase.price ? parseFloat(props.purchase.price.replaceAll(' ','')) : 0,
        BiddingEndDateTime: getDateWithOffset(props.purchase.purchaseExpirationAt, props?.purchase?.deliveryAddress?.timezoneOffset),
        CompanyId: props.currentCompany.Company.Id
      }
      return props.onPurchaseTakedInWork(purchaseToCreate, props.purchase.id, props.currentCompany, enqueueSnackbar)
      .then(() => {
        props.updatePurchaseData(props.purchase.id);
      })
    }

    return (
        <React.Fragment>
            { 
            props.purchase.isInWork === null || props.purchase.isInWork === undefined
            ?
              <CircularProgress size={30}/>
            :
              props.purchase.isInWork === true
                ?
                  <Button className="in_work_btn" variant="contained" color="success" disabled>Закупка уже в работе</Button> 
                :
                  <>
                    <ConfirmationDialog 
                      onConfirm={() => {
                        return handleCreatePurchaseInWork();
                      }} 
                      confirmationText="Вы действительно хотите взять эту закупку в работу?"
                    >
                      <Button className="in_work_btn" variant='contained'>
                        Взять в работу
                      </Button>
                    </ConfirmationDialog>
                  </>
            }
		    </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        currentCompany: state.profileState.currentCompany
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      onPurchaseTakedInWork: (purchase, purchaseId, currentCompany, enqueueSnackbar) => {
        return purchasesInWorkService.createPurchaseInWork(purchase).then(() => {
            enqueueSnackbar('Закупка успешно взята в работу', { variant: "success" });

            purchasesInWorkService.getActivePurchasesInWork(currentCompany.Id)
            .then((result) => {
                const action = {
                    type: 'ACTIVE_PURCHASES_IN_WORK_LOADED',
                    activePurchasesInWorkCount: result
                };
                dispatch(action);
            });

            purchaseTagsService.removeTagsByPurchaseId(purchaseId)
            .then((result) => {
              
            })
            .catch((error) => {
              enqueueSnackbar('Не удалось убрать закупку из избранного', { variant: "error" });
            })
        })
        .catch((error) => {
          enqueueSnackbar(`Произошла ошибка. ${error.Message}`, { variant: "error" });
        })
      },
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheckIsCompanyWithContractWrapper(TakePurchaseInWorkButton, true));
