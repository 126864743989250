import React from "react"
import { Button, TextField, Box, Tooltip, IconButton } from "@mui/material"
import { HelpOutline } from "@mui/icons-material"
import { useSnackbar } from "notistack";
import { Formik, Field, Form } from 'formik';
import * as Yup from "yup";
import InputMask from 'react-input-mask'
import { email_validation_rule, phone_number_validation_rule } from "../../constants/validation_rules";

import settingsService from "../../services/settingsService";

export const UpdateUserInfoRequest = (props) => {
    const { enqueueSnackbar } = useSnackbar();

    const handleUpdateRequest = async (formValues) => {
        const request = {
            NewEmail: formValues.email,
            NewPhone: formValues.phone_number,
            Comment: formValues.comment
        };
        return settingsService.sendUpdateRequest(request)
            .then(() => {
                enqueueSnackbar('Успешно отправлена заявка на изменение данных', { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            });
    }

    const validationSchema = Yup.object({
        phone_number: phone_number_validation_rule
            .required('Обязательное поле'),
        email: email_validation_rule
            .required('Обязательное поле')
    });

    return (
        <React.Fragment>
            <Box>
                <Formik
                    initialValues={{email: '', phone_number: '', comment: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        handleUpdateRequest(values)
                        .finally(() => {
                            setSubmitting(false);
                            resetForm();
                        });
                    }}
                >
                { formik => (
                    <Form onSubmit={formik.handleSubmit} className="settings_block settings_form pt20">
                        <Field name="email"> 
                            {({field, form, meta}) => (
                                <TextField
                                    className="settings_form_input"
                                    margin="normal"
                                    name="email"
                                    label="E-mail"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('email')}
                                    onBlur={(event) => {
                                        //set touched only if field value changed
                                        if (meta.value !== meta.initialValue)
                                        {
                                            formik.handleBlur(event);
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                    fullWidth
                                />
                            )}
                        </Field>
                        <Field name="phone_number">
                            {({field, form, meta}) => (
                                <InputMask
                                    {...field}
                                    mask="+7 999 999 9999"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        if (value){
                                            formik.setFieldValue('phone_number', value.replaceAll(' ',''));
                                        }
                                        else {
                                            formik.setFieldValue('phone_number', '');
                                        }
                                    }}
                                    onBlur={(event) => {
                                        //set field empty when not match mask
                                        if (meta.error)
                                        {
                                            formik.setFieldValue('phone_number', '');
                                        }
                                        //reset touched if all form values pristine
                                        if (form.dirty === false)
                                        {
                                            form.setTouched({});
                                        }
                                    }}
                                >
                                {(inputProps) => 
                                    <TextField
                                        className="settings_form_input"
                                        {...inputProps}
                                        margin="normal"
                                        name="phone_number"
                                        label="Номер телефона"
                                        helperText={meta.touched && meta.error}
                                        error={ meta.touched && meta.error != null }
                                        fullWidth
                                    />
                                }
                                </InputMask>
                            )}
                        </Field>
                        <Field name="comment">
                            {({field, form, meta}) => (
                                <TextField
                                    className="settings_form_input"
                                    margin="normal"
                                    name="comment"
                                    label="Комментарий"
                                    helperText={meta.touched && meta.error}
                                    error={ meta.touched && meta.error != null }
                                    {...formik.getFieldProps('comment')}
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            )}
                        </Field>

                        <Box className='update_user_info_button_container'>
                            <Button
                                type="submit"
                                variant="contained"
                                className="settings_form_submit"
                                disabled={formik.isSubmitting}
                            >
                                    Отправить запрос на изменение
                            </Button>
                            <Tooltip
                                enterTouchDelay={0}
                                title="К одной из ваших компаний прикреплен менеджер, поэтому изменение данных недоступно без предварительного согласования с ним"
                            >
                                <IconButton sx={{ ml: 1 }} size="small">
                                    <HelpOutline fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Form>
                    )}
                </Formik>
            </Box>
        </React.Fragment>
    )
}
