import React, { useCallback, useState, useEffect } from "react"
import { useDispatch } from 'react-redux';
import { LinearProgress, Typography } from "@mui/material"
import { useDropzone } from 'react-dropzone'
import { AuthenticatedLink } from "../authenticated-link";

import filesService from "../../services/filesService"
import purchasesInWorkService from "../../services/purchasesInWorkService";

export const DocumentRequest = (props) => {
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadFailure, setUploadFailure] = useState(false);
    const [uploadFailureMessage, setUploadFailureMessage] = useState("");
    const dispatch = useDispatch();  

    let document = props.document;

    useEffect(() => {
        if (document.FileName) {
            setUploadSuccess(true);
        }
    }, []);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            upload(file);
        });
    }, [])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop
    });

    const upload = (file) => {
        setUploadFailure(false);
        setUploadSuccess(false);
        filesService.uploadFile(`/PurchaseWorkSessions/${props.purchaseId}/Documents/${document.Id}/files`, file,
            (event) => {
                const updatedProgress = Math.round((100 * event.loaded) / event.total);
                setUploadProgress(updatedProgress);
        }, 
            () => {
                setUploadSuccess(true);
                purchasesInWorkService.getSinglePurchaseInWork(props.purchaseId)
                    .then((purchaseInWork) => {
                        const action = {
                            type: "PURCHASE_IN_WORK_UPDATED_SINGLE",
                            purchaseInWork: purchaseInWork
                        };
                        dispatch(action);
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                
        },
            (event) => {
                setUploadFailure(true);
                setUploadFailureMessage(event.response.data.Message);
        })
    }

    const getFileNameByUrl = (url) => {
        if (url) {
            const paramArr = url.slice(url.indexOf('?') + 1).split('&');
            const params = {};
            paramArr.map(param => {
                const [key, val] = param.split('=');
                params[key] = decodeURIComponent(val);
            })
            return params['fileName'];
        }
    }

    return (
        <React.Fragment>
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <Typography component="div" variant="overline" className="dropzone_header">{document.Name}</Typography>
                <Typography component="div" variant="caption" className="dropzone_caption">Переместите файл сюда или кликните для загрузки</Typography>
                {
                    uploadProgress > 0 || uploadSuccess
                    ?
                    <>
                        <LinearProgress className="dropzone_progress" variant="determinate" color={uploadFailure ? "error" : uploadSuccess ? "success" : "primary"} value={uploadProgress} />
                        {
                            uploadSuccess
                                ?
                                    <div className="dropzone_success" onClick={function (e) {e.stopPropagation();}}>
                                        <span>Загружено</span>
                                        <br/>
                                        <AuthenticatedLink url={document.FileName}><small>{getFileNameByUrl(document.FileName)}</small></AuthenticatedLink>
                                    </div>
                                : null
                        }
                        {
                            uploadFailure
                                ?
                                    <div className="dropzone_fail">{uploadFailureMessage}</div>
                                : null
                        }
                    </>
                    : null
                }
            </div>
		</React.Fragment>
    )
}
