import React, { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { MainPageUserSummary } from './main-page-user-summary';
import { MainPageCompaniesSummary } from './main-page-companies-summary';
import { MainPageRelevantSelectedPurchases } from './main-page-relevant-selected-purchases';
import { useDispatch } from "react-redux";

import profileService from "../../services/profileService";
import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';

export const MainPageIndex = (props) => {
    const [profileInfo, setProfileInfo] = useState({});
    const [isLoadedProfileInfo, setIsLoadedProfileInfo] = useState(false);
    const [isLoadedRelevantPurchases, setIsLoadedRelevantPurchases] = useState(false);
    const [selectedPurchases, setSelectedPurchases] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        const pageAction = {
            type: 'CURRENT_PAGE_CHANGED',
            currentPage: 'main'
        };
        dispatch(pageAction);

        setIsLoadedProfileInfo(true);
        setIsLoadedRelevantPurchases(true);
        profileService.getProfile()
            .then((result) => {
                setProfileInfo(result);
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка при получении данных о пользователе. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })
            .finally(() => {
                setIsLoadedProfileInfo(false);
            })
        PurchaseTagsService.getRelevantTagPurchases(3)
            .then((result) => {
                setSelectedPurchases(result);
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка при получении списка актуальных закупок. ${error.Message ? error.Message : ''}`, { variant: "error" });
            })
            .finally(() => {
                setIsLoadedRelevantPurchases(false);
            })
    }, [dispatch, enqueueSnackbar]);

    return (
        <React.Fragment>
            {isLoadedProfileInfo === false && isLoadedRelevantPurchases === false
            ?
                <>
                    <MainPageUserSummary client={profileInfo.Client}/>
                    <MainPageCompaniesSummary companies={profileInfo.Acvs}/>
                    <MainPageRelevantSelectedPurchases selectedPurchases={selectedPurchases}/>
                </>
            :
                <CircularProgress/>
        }
		</React.Fragment>
    )
}