import React, { useEffect, useState } from "react"
import { Button, Typography, Box } from "@mui/material"
import settingsService from "../../services/settingsService"
import { useSnackbar } from 'notistack';
import { useSelector } from "react-redux";

export const EmailConfirmation = (props) => {
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const currentUserInfo = useSelector((state) => state.profileState.currentUserInfo);

    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        setIsEmailVerified(currentUserInfo.email_confirmed);
    }, [currentUserInfo.email_confirmed])

    const handleSendConfirmationButton = () => {
        settingsService.sendEmailConfirmation()
            .then(() => {
                enqueueSnackbar('На указанный email отправлено письмо для подтверждения', { variant: "success" });
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error.Message ? error.Message : ''}`, { variant: "error" });
            });
    }

    return (
        <React.Fragment>
            {
                isEmailVerified
                ?
                    <>
                        <Box className="settings_block email_confirmation email_confirmed" sx={{mt: 6}}>
                            <Typography component="h1" variant="h5">
                                E-mail подтвержден
                            </Typography>                   
                            <img src="\confirm.png" alt="E-mail подтвержден" title="E-mail подтвержден" />         
                        </Box>
                    </>
                :
                    <>
                        <Box className="settings_block email_confirmation" sx={{mt: 6}}>
                            <Typography component="h1" variant="h5">
                                Подтверждение e-mail
                            </Typography>                            
                            <Button
                                onClick={handleSendConfirmationButton}
                                variant="contained"
                            >
                               Подтвердить
                            </Button>
                        </Box>
                    </>
            }
            
		</React.Fragment>
    )
}
