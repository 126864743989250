import React, {useState} from "react";
import { Dialog, DialogTitle, Typography, Button, DialogContent, DialogActions, IconButton, Box, Select, MenuItem } from "@mui/material";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from 'notistack';
import { complaintTypes } from "../../constants/complaint_types";

import { ButtonWithWaitForCompletion } from "../button-with-wait-for-completion";

import complaintsService from "../../services/complaintsService";

export const PurchaseInWorkComplaintDialog = (props) => {
    const [complaintType, setComplaintType] = useState(0);
    const [comment, setComment] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleSendComplaint = async () => {
        let complaint
        if (window.innerWidth < 1024) {
            complaint = {
                Type: '1',
                Comment: comment,
                PurchaseWorkSessionId: props.purchaseInWorkId
            };
        }
        else {
            complaint = {
                Type: complaintType,
                Comment: comment,
                PurchaseWorkSessionId: props.purchaseInWorkId
            };
        }
        return complaintsService.sendComplaint(complaint)
            .then(() => {
                enqueueSnackbar('Жалоба успешно отправлена', { variant: "success" });
                props.onClose();                
            })
            .catch((error) => {
                enqueueSnackbar(`Произошла ошибка. ${error ? error : ''}`, { variant: "error" });
            })
    }

    return (
        <React.Fragment>
            <Dialog open={props.openDialog} onClose={props.onClose} className="modal_purchase">
                <Box position="absolute" top={0} right={0} className="modal_purchase_close">
                    <IconButton 
                        onClick={props.onClose}
                    >
                        <CloseIcon color="action"/>
                    </IconButton>
                </Box>
                <DialogTitle className="modal_purchase_title">
                    Пожаловаться
                </DialogTitle>
                <DialogContent className="modal_purchase_content">
                    <Typography className="modal_purchase_title_select">Выберите причину обращения:</Typography>
                    <Select 
                        value={complaintType}
                        onChange={(event) => setComplaintType(event.target.value)}                        
                        className="reason_selector"
                    >
                        {complaintTypes.map((item, key) => 
                            <MenuItem value={item.value} key={key}>{item.title}</MenuItem>
                        )}
                    </Select>
                    <Typography className="modal_purchase_title_textarea">Текст:</Typography>
                    <TextareaAutosize
                        minRows={12}
                        cols={60}
                        onChange={(event) => setComment(event.target.value)}
                    />
                </DialogContent>
                <DialogActions className="modal_purchase_btns">
                    <Button onClick={props.onClose} variant="outlined">
                        Отмена
                    </Button>
                    <ButtonWithWaitForCompletion onClick={handleSendComplaint} variant="contained">
                        Отправить
                    </ButtonWithWaitForCompletion>
                </DialogActions>
            </Dialog>
		</React.Fragment>
    )
}
