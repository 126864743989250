import React, {useEffect, useState} from 'react';
import {Button, Card, CircularProgress, Dialog} from '@mui/material';
import Tree from 'rc-tree';
import '../css/rc-tree.css';
import okpdJson from '../json/code3.json'
import './okpd-selection-static.css'

export function OkpdSelection(props) {
    const [searchInputTimeout, setSearchInputTimeout] = useState();
    const [checkedKeys, setCheckedKeys] = useState();

    useEffect(() => {
        setCheckedKeys(props.checkedKeys)
    }, [])

    return (
        <React.Fragment>
            <Dialog

                className="dialog"
                open={props.open}
                onClose={props.onClose}
                maxWidth="80%"
                PaperProps={{
                    sx: {
                        display: 'flex',
                        gap: '20px',
                        paddingTop: '10px',
                    }
                }}
            >
                <Card sx={{overflowY: "scroll", boxShadow: 'none', maxHeight: '510px'}}>
                    {/*
          Пока поиск убрал, надо писать отдельный алгоритм поиска под СРМовский json

          <Grid container spacing="0" alignItems="center">
            <Grid item>
              <Typography>
                Поиск по ОКПД:
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant = "outlined"
                size = "small"
                onChange={(event) => {
                    const value = event.target.value;
                    if (value) {
                      if (searchInputTimeout !== null){
                        clearTimeout(searchInputTimeout);
                      }
                      setSearchInputTimeout(setTimeout(() => {
                        if (value.replace(' ','').length > 0) {
                          props.onInputOkpdSearch(value);
                        }
                      }, 500));
                    }
                    else {
                      clearTimeout(searchInputTimeout);
                      props.onInitialLoad(props.checkedStaticOkpdCodes)
                    }
                  }
                }
              />
            </Grid>
            <Grid item>
              <FormHelperText error>
                {props.searchWarningMessage}
              </FormHelperText>
            </Grid>
          </Grid>
              */}
                    {props.isLoading
                        ?
                        <CircularProgress/>
                        :
                        <Tree
                            checkable
                            treeData={okpdJson}
                            checkedKeys={checkedKeys}
                            onCheck={(keys) => setCheckedKeys(keys)}
                            showIcon={false}
                            className="highlighted"
                        />
                    }
                </Card>
                <Button sx={{marginBottom: '0px'}} onClick={() => props.onSave(checkedKeys)}>Выбрать</Button>
            </Dialog>
        </React.Fragment>
    )
}
