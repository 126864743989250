import React, { useEffect, useState } from "react"
import { Typography, Box, CircularProgress } from "@mui/material"
import { UpdateUserInfoPhoneNumber } from "./update-userinfo-phone-number"
import { UpdateUserInfoEmail } from "./update-userinfo-email"
import { UpdateUserInfoRequest } from "./update-userinfo-request"

import settingsService from "../../services/settingsService";

export const UpdateUserInfo = (props) => {
    const [isAllowedToUpdateInfo, setIsAllowedToUpdateInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        settingsService.checkIsAllowedToUpdateInfo()
            .then((result) => {
                setIsLoading(false);
                setIsAllowedToUpdateInfo(result);
            })
            .catch(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <React.Fragment>
            <Box sx={{mt: 3}}>
                <Typography className="heading" component="h1" variant="h5">
                    Изменение личной информации
                </Typography>
                {isLoading
                ? 
                    <Box className="settings_update_user_loading_block">
                        <CircularProgress status="loading" />
                    </Box>
                :
                    <>
                    {!isAllowedToUpdateInfo
                    ?
                        <UpdateUserInfoRequest />
                    :
                        <>
                        <Box>
                            <Box className="settings_block settings_form pt20">
                                <UpdateUserInfoPhoneNumber />
                                <UpdateUserInfoEmail />
                            </Box>
                        </Box>
                        </>
                    }
                    </>
                }
            </Box>
        </React.Fragment>
    )
}
