import React from 'react';
import { connect } from 'react-redux';
import PurchaseService from '../../services/search-purchases-services/PurchaseService';
import PurchaseTagsService from '../../services/search-purchases-services/PurchaseTagsService';
import purchasesInWorkService from '../../services/purchasesInWorkService';
import { withSnackbar } from 'notistack';
import SelectTagDialog from './SelectTagDialog';
import { ConfirmationDialog } from '../confirmation-dialog';
import { DateTime } from 'luxon';
import { Link as RouterLink } from "react-router-dom";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import noContentImg from "../../img/icons/empty-folder.png"
import favFolderImg from "../../img/icons/fav-folder.svg"

import './tags-mobile.css'

//MUI
import { Box, Button, Card, CardContent, Grid, Link, Typography, Stack, Dialog, TextField, CircularProgress, Tooltip, IconButton } from '@mui/material';
import { ButtonWithWaitForCompletion } from '../button-with-wait-for-completion';

class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addTagName: ''
    }; 
  }

  componentDidMount() {
    this.props.onPageOpen();
    this.props.onUpdateTags(this.props.enqueueSnackbar);
    if (this.props.selectedTagId && this.props.currentCompany.Id) {
      this.props.onGetPurchases(this.props.selectedTagId, this.props.currentCompany, this.props.enqueueSnackbar);
    }
  }

  componentDidUpdate(prevProps) {
    //reload purchases when company changes
    if (prevProps.currentCompany !== this.props.currentCompany && this.props.selectedTagId && this.props.currentCompany.Id) {
      this.props.onGetPurchases(this.props.selectedTagId, this.props.currentCompany, this.props.enqueueSnackbar);
    }

    if (prevProps.tagDialogSelectedPurchase.tags && this.props.tagDialogSelectedPurchase.tags){
      //reload purchases only if changed purchase tags
      //if added
      const difference_added = this.props.tagDialogSelectedPurchase.tags.filter(tag => !prevProps.tagDialogSelectedPurchase.tags.includes(tag));
      const is_difference_added_contains_current_purchase = difference_added.some(tag => tag.id === this.props.selectedTagId);
      //or removed
      const difference_removed = prevProps.tagDialogSelectedPurchase.tags.filter(tag => !this.props.tagDialogSelectedPurchase.tags.includes(tag));
      const is_difference_removed_contains_current_purchase = difference_removed.some(tag => tag.id === this.props.selectedTagId);

      if (this.props.selectedTagId && this.props.currentCompany.Id && this.props.purchases
        && this.props.tagDialogSelectedPurchase.id === prevProps.tagDialogSelectedPurchase.id
        && (is_difference_added_contains_current_purchase || is_difference_removed_contains_current_purchase)) {
          this.props.onGetPurchases(this.props.selectedTagId, this.props.currentCompany, this.props.enqueueSnackbar);
      }
    }
  }

  getSourcePlatform = (purchase) => {
    if (purchase.sourcePlatform) {
      return purchase.sourcePlatform.name;
    } else {
      return null
    }
  }  

  getLaw = (purchase) => {
    switch(purchase.law) {
      case 1:
        return '44-ФЗ';
      case 2:
        return '223-ФЗ';
      case 3:
        return 'Коммерческие';
      default:
        return 'Не определено';
    }    
  }

  getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy HH:mm');

    return formattedDate;
  }  

  getStage = (purchase) => {
    switch(purchase.stage) {
      case 1:
        return <div>Подача заявок <div>{this.getFormatDate(purchase.purchaseExpirationAt)}</div></div>;
      case 2:
        return <div>Работа комиссии</div>;
      case 3:
        return <div>Размещение завершено</div>;
      case 4:
        return <div>Размещение отменено</div>;
      case 5:
        return <div>Торги не состоялись</div>;
      case 6: 
        return <div>Торги запланированы</div>;
      case 7: 
        return <div>На исполнении</div>;
      default:
        return <div>Не определено</div>;
    }
  }

  closeDialog = (purchaseId, newTagId) => {
    this.props.onCloseTagDialog(purchaseId, this.props.selectedTagId, newTagId);
  }

  render() {
    return (
      <React.Fragment>
        <SelectTagDialog onClose={this.closeDialog} />
        <Dialog open={this.props.editTagDialogOpened} onClose={this.props.onCancelEditDialog}>
          <Grid>
            <form onSubmit={(event) => {
              event.preventDefault();
            }}>
              <div className='change_tag_folder'>
                <h2 className='modal_header'>Название папки</h2>
                <TextField 
                  defaultValue={this.props.editTag.name}
                  name="tag_name"
                  onChange={(event) => {this.props.onEditTagName(event.target.value)}}
                  autoFocus
                />
                { this.props.editTagDialogErrors.map((err, key) => <Grid item xs={12} sx={{color:'red', m:1}} key={key}>*{err}</Grid>) }
                <div className='change_tag_folder_btns'>
                  <ButtonWithWaitForCompletion type="submit" onClick={() => { 
                    return this.props.onSaveEditDialog(this.props.editTag, this.props.editTagId, this.props.enqueueSnackbar);
                  }}>Сохранить</ButtonWithWaitForCompletion>
                  <Button onClick={() => this.props.onCancelEditDialog()}>Отмена</Button>
                </div>
              </div>
            </form>
          </Grid>
        </Dialog>
        {!this.props.isTagsLoading 
          ?
            this.props.tags.length > 0
            ? 
              <>
                <Box className='tags_list_block'>
                  <Box className='tags_list_block_header'>
                    <Typography sx={{mt:'3px'}} component="h1" variant="h5">
                      Папки
                    </Typography>
                    <Box>
                      <Tooltip sx={{color:'black'}} title="Добавить новую папку">
                          <IconButton onClick={() => this.props.onOpenEditTagDialog()}>
                              <AddCircleOutlineIcon  fontSize="inherit" />
                          </IconButton>
                      </Tooltip>
                      {this.props.selectedTagId
                        ?
                        <>
                          <Tooltip sx={{color:'black'}} title="Редактировать выбранную папку">
                              <IconButton onClick={() => this.props.onOpenEditTagDialog(this.props.selectedTagId, this.props.enqueueSnackbar)}>
                                  <EditIcon fontSize="inherit" />
                              </IconButton>
                          </Tooltip>
                          <ConfirmationDialog 
                            onConfirm={() => {
                              return this.props.onRemovingTag(this.props.selectedTagId, this.props.enqueueSnackbar);
                            }} 
                            confirmationText="Вы действительно хотите удалить выбранную папку?"
                          >
                            <Tooltip sx={{color:'black'}} title="Удалить выбранную папку">
                                <IconButton>
                                    <DeleteForeverIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                          </ConfirmationDialog>
                        </>
                        : null
                      }
                    </Box>
                  </Box>
                  <Box className='tags_list_block_content'>
                    {this.props.tags.map((tag, key) => {
                        let variant = tag.id === this.props.selectedTagId ? "tags_list_block_item tags_list_block_item_selected" : "tags_list_block_item";
                        return <Box className={variant} key={key} onClick={() => this.props.onGetPurchases(tag.id, null, this.props.enqueueSnackbar)}>
                                <img className='tags_list_block_item_icon' src={favFolderImg}/>
                                <Box title={tag.name} className='tags_list_block_item_title'>{tag.name}</Box>
                              </Box>
                      }
                    )}
                  </Box>
                </Box>
                <Box className='tag_purchases_block'>
                  <Box className='tag_purchases_block_header'>
                    <Typography component="h1" variant="h5">
                      Закупки
                    </Typography>
                  </Box>
                  <Box className='tag_purchases_block_content'>
                    {this.props.selectedTagId
                      ?
                      <>
                        {this.props.isPurchasesLoading
                        ?
                          <CircularProgress />
                        :
                          <>
                        {this.props.purchases.length > 0 
                          ?
                            this.props.purchases.map((purchase, key) =>
                              <Card variant="outlined" sx={{ minWidth: 275, mt: 1 }} key={key}>
                                <CardContent>
                                  <Box className="zakupka_card">
                                    <Grid item xs={7}>
                                      <Link component={RouterLink} rel="noopener noreferrer" to={`/purchases/${purchase.id}`} underline="hover">  
                                        <div className="highlighted zakupka_name">
                                          {purchase.title}
                                        </div>
                                      </Link>
                                      <Typography variant="caption" color="text.secondary" component="div">
                                        {[this.getLaw(purchase), purchase.methodType, purchase.number].filter(Boolean).join(', ')}
                                      </Typography>
                                      <Typography sx={{mt: 2, mb: 2}} component="div">
                                        <div className='zakupka_block_header'>
                                          Площадка:
                                        </div> 
                                        {this.getSourcePlatform(purchase)}
                                      </Typography>
                                    </Grid>
                                    <Grid className='zakupka_aside'>
                                      <div className='zakupka_price'>
                                        {purchase.price ? purchase.price + ' ₽' : '—'}
                                      </div>
                                      <Typography variant="subtitle1" component="div" sx={{mt: 2}}>
                                        {this.getStage(purchase)}
                                      </Typography>
                                      {purchase.isInWork
                                        ? <div className='zakupka_inwork'>Закупка уже в работе</div>
                                        : null
                                      }
                                    </Grid>
                                  </Box>
                                  <Box>
                                  <Stack direction="row" justifyContent='flex-start'>
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      onClick={() => this.props.onPurchaseTagChangeCalled(purchase, this.props.selectedTagId, this.props.enqueueSnackbar)}
                                      sx={{mr:1}}
                                    >
                                      Сменить папку
                                    </Button>
                                    
                                    <Button 
                                      variant="outlined" 
                                      size="small"
                                      color="error"
                                      onClick={() => this.props.onRemovingPurchaseTag({purchaseId: purchase.id, tagId: this.props.selectedTagId}, this.props.enqueueSnackbar)}
                                      >
                                      Убрать закупку
                                    </Button>
                                  </Stack>
                                  </Box>
                                </CardContent>
                              </Card>
                            )
                          : 
                            <Box className='tag_purchases_block_content_empty'>
                              <Typography variant="h6">
                                Вы еще не добавили закупки в эту папку...
                              </Typography>
                            </Box>
                        }
                        </>
                        }
                      </>
                      :
                        <Box className='tag_purchases_block_content_empty'>
                          <Typography variant="h6">
                            Выберите папку...
                          </Typography>
                        </Box>
                    }
                  </Box>
                </Box>
              </>
            :
              <Box className="no_item_block">
                  <Box className="no_item_block_icon">
                      <img src={noContentImg}/>
                  </Box>
                  <Box className="no_item_block_text_main">
                      Упс... кажется на данный момент вы еще не создали папку для избранных закупок.
                  </Box>
                  <Box className="no_item_block_text_second">
                      Вы можете создавать различные папки для избранного и помещать туда выбранные закупки.
                      <br/>
                      Хотите создать папку? <Link href="#" onClick={() => this.props.onOpenEditTagDialog()}>создать</Link>
                  </Box>
              </Box>
          :
            <CircularProgress />
        }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    tags: state.tagsState.tags,
    purchases: state.tagsState.purchases,
    selectedTagId: state.tagsState.selectedTagId,
    selectedTag: state.tagsState.selectedTag,
    editTagDialogOpened: state.tagsState.editTagDialogOpened,
    editTagDialogErrors: state.tagsState.editTagDialogErrors,
    editTag: state.tagsState.editTag,
    editTagId: state.tagsState.editTagId,
    isPurchasesLoading: state.tagsState.isPurchasesLoading,
    currentCompany: state.profileState.currentCompany,
    isTagsLoading: state.tagsState.isTagsLoading,
    tagDialogSelectedPurchase: state.selectTagDialogState.selectedPurchase
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onPageOpen: () => {
      const action = {
        type: 'CURRENT_PAGE_CHANGED',
        currentPage: 'selected'
      };
      dispatch(action);
    },
    onOpenEditTagDialog: (tagId, enqueueSnackbar) => {
      if (tagId){
        PurchaseTagsService.getTag(tagId)
          .then((tag) => {
            const action = {
              type: 'EDIT_DIALOG_OPEN',
              editTag: tag,
              editTagId: tagId
            };
            dispatch(action);
          })
          .catch((error) => {
            enqueueSnackbar(
              'Произошла ошибка при получении папки избранного',
              { 
                variant: 'error'
              });
          });
      } else {
        const action = {
          type: 'EDIT_DIALOG_OPEN',
          editTag: {},
          editTagId: null
        };
        dispatch(action);
      }
    },
    onSaveEditDialog: async (tag, tagId, enqueueSnackbar) => {
      if (tagId) {
      return PurchaseTagsService.updateTag(tagId, tag)
        .then((response) => {
          if (response && response.status >= 400 && response.errors) {
            const action = {
              type: 'EDIT_DIALOG_ERROR',
              errors: response.errors['Name']
            };
            dispatch(action);
          }
          else {
              const action = {
                type: 'EDIT_DIALOG_SAVE',
                tag: tag,
                tagId: tagId
              };
              dispatch(action);
          }
        })
        .catch((error) => {
          const action = {
            type: 'EDIT_DIALOG_CANCEL'
          };
          dispatch(action);
          enqueueSnackbar(
            'Произошла ошибка при обновлении папки избранного',
            { 
              variant: 'error'
            });
        });
      } else {
        return PurchaseTagsService.addTag(tag)
          .then((response) => {
            if (response && response.status >= 400 && response.errors) {
              const action = {
                type: 'EDIT_DIALOG_ERROR',
                errors: response.errors['Name']
              };
              dispatch(action);
            }
            else {
              const action = {
                type: 'ADD_DIALOG_SAVE',
                tag: {id: response, name: tag.name}
              };
              dispatch(action);
            }
          })
          .catch((error) => {
            const action = {
              type: 'EDIT_DIALOG_CANCEL'
            };
            dispatch(action);
            enqueueSnackbar(
              'Произошла ошибка при добавлении папки избранного',
              { 
                variant: 'error'
              });
          });
      }
    },
    onCancelEditDialog: () => {
      const action = {
        type: 'EDIT_DIALOG_CANCEL'
      };
      dispatch(action);
    },
    onEditTagName: (value) => {
      const action = {
        type: 'TAG_NAME_EDIT',
        tagName: value
      };
      dispatch(action);
    },
    onUpdateTags: (enqueueSnackbar) => {
      const action = {
        type: 'TAGS_SEARCH_STARTED'
      };
      dispatch(action);
      PurchaseTagsService.getTags()
        .then(result => {
          const action = {
            type: 'TAGS_FOUND',
            tags: result
          };
          dispatch(action);
        })
        .catch(error => {
          enqueueSnackbar(
            'Произошла ошибка при получении списка избранного',
            { 
              variant: 'error'
            });

          const action = {
            type: 'TAGS_SEARCH_FAILED',
            error: error
          };
          dispatch(action);
        })
    },
    onGetPurchases: (tagId, currentCompany, enqueueSnackbar) => {
      const action = {
        type: 'TAG_SELECTED'
      };
      dispatch(action);

      PurchaseTagsService.getPurchasesForTag(tagId)
      .then(result => {
        if (result && result.length > 0) {
          PurchaseService.getMany(result)
            .then(purchases => {
              const action = {
                type: 'PURCHASES_FOR_TAG_FOUND',
                purchases: purchases,
                selectedTagId: tagId
              };
              dispatch(action);

              if (currentCompany && currentCompany.Id && currentCompany.Company) {
              purchasesInWorkService.checkIfPurchasesInWork(currentCompany.Id, purchases)
                .then(result => {
                  const action = {
                    type: 'PURCHASE_IN_WORK_FOR_TAG_FOUND',
                    purchasesInWork: result
                  };
                  dispatch(action);
                })
                .catch(error => {
                  enqueueSnackbar(
                    'Произошла ошибка при получении закупок в работе',
                    { 
                      variant: 'error'
                    });
                })
              }
            });
        }
        else {
          const action = {
            type: 'PURCHASES_FOR_TAG_FOUND',
            purchases: [],
            selectedTagId: tagId
          };
          dispatch(action);
        }
      })
      .catch(error => {
        enqueueSnackbar(
          'Произошла ошибка при получении закупок в папке избранного',
          { 
            variant: 'error'
          });

        const action = {
          type: 'ERROR_WHILE_GET_PURCHASES_FOR_TAG'
        };
        dispatch(action);
      });
    },
    onRemovingPurchaseTag: (purchaseTag, enqueueSnackbar) => {
      PurchaseTagsService.removePurchaseTag(purchaseTag)
        .then(result => {
          const action = {
            type: 'PURCHASE_TAG_REMOVED',
            purchaseId: purchaseTag.purchaseId
          };
          dispatch(action);
        })
        .catch(error => {
          enqueueSnackbar(
            'Произошла ошибка при удалении закупки из избранного',
            { 
              variant: 'error'
            });
        })
    },
    onRemovingTag: async (tagId, enqueueSnackbar) => {
      return PurchaseTagsService.removeTag(tagId)
        .then(result => {
          const action = {
            type: 'TAG_REMOVED',
            tagId: tagId
          };
          dispatch(action);
        })
        .catch(error => {
          enqueueSnackbar(
            'Произошла ошибка при удалении папки избранного',
            { 
              variant: 'error'
            });
        })
    },
    onPurchaseTagChangeCalled: (purchase, tagId, enqueueSnackbar) => {
      const purchaseIds = [purchase.id];
      PurchaseTagsService.getPurchaseTags(purchaseIds)
        .then((result) => {
          purchase.tags = result[0].tags;
          PurchaseTagsService.getTags()
            .then(result => {
              const action = {
                type: 'OPEN_TAG_DIALOG',
                selectedPurchase: purchase,
                //selectedTagId: tagId,
                tags: result
              };
              dispatch(action);
            })
            .catch(error => {
              enqueueSnackbar(
                'Произошла ошибка при получении папок избранного',
                { 
                  variant: 'error'
                });
              })
      })
      .catch(error => {
        enqueueSnackbar(
          'Произошла ошибка при получении избранного для закупки',
          { 
            variant: 'error'
          });
      })
    }
  }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(Tags));