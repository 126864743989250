import React, { useEffect, useState } from "react"
import { Typography, AppBar, Card, Stepper, Step, StepButton, Divider, CardContent, Stack, Tooltip, IconButton, Link, Box } from "@mui/material"
import { ManagerContacts } from "./manager-contacts";
import { DocumentRequestList } from "./document-request-list";
import { PurchaseInWorkItemOptionsButton } from "./purchase-in-work-item-options-button";
import { DateTime } from 'luxon';
import { NumberFormatCustom } from '../number-format-custom';
import arrow from '../../img/icons/arrow.svg'
import arr from '../../img/icons/arr.svg'

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const steps = [
    { key: "100", name: "Подготовка документов" },
    { key: "300", name: "Подача заявки" },
    { key: "400", name: "Заявка подана" },
    { key: "500", name: "Подписание" },
    { key: "600", name: "Подписание заказчиком" },
    { key: "700", name: "Завершено" },
];

const getFormatDate = (inputDate) => {
    const date = DateTime.fromISO(inputDate);
    const formattedDate = date.toFormat('dd.MM.yyyy');

    return formattedDate;
}

const isStepFinished = (revisions, step) => {
    if (revisions.filter(f => f.Stage == step).length > 0) {
        
        return true;
    }
    return false;
};

const getStageTitle = (stage, revisions) => {
    if (!stage)
    {
        return null;
    }

    const stageRevision = revisions.find(r => r.Stage == stage.key);
    if (stageRevision)
    {
        return (
            <React.Fragment>
                <Box sx={{display:'flex', flexDirection:'column', alignItems:'start'}}>
                    <Typography>
                        {stage.name}
                    </Typography>
                    <Box sx={{alignItems:'center', display:'flex'}}>
                        <span style={{paddingTop:'2px', paddingRight:'3px'}}><AccessTimeIcon fontSize='inherit'></AccessTimeIcon></span>
                        <small>{getFormatDate(stageRevision.RevisionCreatedAtUtc)}</small>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Typography>
                {stage.name}
            </Typography>
        </React.Fragment>
    );
};

export const PurchaseInWorkItem = (props) => {
    let purchaseInWork = props.purchaseInWork;

    const getActiveStep = (stage) => {
        for (var i = 0; i < steps.length; i++){
            const nextStepKey = steps[i+1] ? steps[i+1].key : "10000";
            if (stage >= steps[i].key && stage < nextStepKey){
                return i;
            }
        }
        return -1;
    }
    
    const isPurchaseInWorkFinished = purchaseInWork.Stage === 700;

    const getHeaderColor = () => {
        if (purchaseInWork.IsFinishSuccessful) {
            return 'green';
        }
        if (isPurchaseInWorkFinished) {
            return 'gray';
        }

        return '';
    }

    const [activePurchaseInWorkStage, setActivePurchaseInWorkStage] = useState([])
    useEffect(() => {
        const arr = steps.map((step, key) => {
            if (isStepFinished(purchaseInWork.Revisions, step.key)) {
                return (
                    <Step key={key} completed={true} className="one_card_step_active_purchanse">
                        <StepButton className="one_card_btn_active_purchanse">
                            {getStageTitle(step, purchaseInWork.Revisions)}
                        </StepButton>
                    </Step>
                )
            } else return null
        }
        )
        
        const filtredArr = arr.filter(i => i !== null)
        setActivePurchaseInWorkStage(filtredArr[filtredArr.length - 1])
    }, [purchaseInWork]) 


    const [barVisible, setBarVisible] = useState(false)
    return (
        <React.Fragment>
            <div className="one_card">
                <AppBar position="relative" sx={{ bgcolor: getHeaderColor() }} className="card_header">                     
                    {
                        purchaseInWork.IsFinishSuccessful
                        ?
                        <span className="card_icon check_icon"></span>
                        :
                        isPurchaseInWorkFinished
                        ?
                        <span className="card_icon x_icon"></span>
                        :
                        <span className="card_icon clock_icon"></span>
                    }
                    <div className="card_num">
                        <span className="card_purchase_num">
                            № {purchaseInWork.PurchaseNumber}
                            {
                                purchaseInWork.HasActiveCancelRequest === true
                                ?
                                    <Tooltip title="Вы отправили запрос на отзыв данной закупки из работы" className="appbar_cancel_icon">
                                        <ReportProblemIcon 
                                            fontSize='inherit'
                                            htmlColor="red"
                                        />
                                    </Tooltip>
                                :
                                    null
                            }
                        </span>
                        <span className="card_company_name">
                            {purchaseInWork.Company.AccountCompanyView.Name}
                        </span>
                    </div>
                    <div className="card_price"> 
                        <span className="card_purchase_price">
                            Сумма: <NumberFormatCustom value={purchaseInWork.Price} displayType="text" /> ₽
                        </span>
                        <span className="card_company_created_date">
                            <Typography>
                                Дата добавления: {getFormatDate(purchaseInWork.CreatedAtUtc)}
                            </Typography>
                        </span>
                    </div>
                    {
                        !purchaseInWork.IsFinishSuccessful
                        ?
                            <PurchaseInWorkItemOptionsButton purchaseInWorkId={purchaseInWork.Id} purchaseInWorkStage={purchaseInWork.Stage} hasActiveCancelRequest={purchaseInWork.HasActiveCancelRequest}/>
                        :
                            null
                    }
                </AppBar>
                <Card className="card_body">
                    <CardContent>
                        <div className={['pws_title', barVisible ? 'pws_stages_expanded' : null].join(' ')}  onClick={() => setBarVisible(!barVisible)} >
                            <div className={['pws_expand_mark', barVisible ? 'expanded' : null].join(' ')}><img src={arr} alt="arr"/></div>
                            <Typography sx={{mb: 2}}>{purchaseInWork.PurchaseObject}</Typography>
                        </div>
                        {!!activePurchaseInWorkStage && <Stepper nonLinear activeStep={getActiveStep(purchaseInWork.Stage)} sx={{ mb: 2 }} className="one_card_steps_active_purchanse">
                                {activePurchaseInWorkStage}
                            </Stepper>}
                        {
                            !isPurchaseInWorkFinished 
                            ?
                            <Divider 
                                className="divider"
                                light 
                                sx={{ borderStyle: 'dashed' }}
                            >
                                {/* Мы ведём работу над данным тендером. Ниже показаны текущий и предстоящие этапы работы */}
                                Этапы работ
                            </Divider>
                            :
                            null
                        }
                        
                        <Stepper nonLinear activeStep={getActiveStep(purchaseInWork.Stage)} sx={{ mb: 2 }} className="one_card_steps">
                            {steps.map((step, key) => (
                                    <Step key={key} completed={isStepFinished(purchaseInWork.Revisions, step.key)} className="one_card_step">
                                        <StepButton className="one_card_btn">
                                            {getStageTitle(step, purchaseInWork.Revisions)}
                                        </StepButton>
                                        <img className="one_card_steps_img" src={arrow} alt="arrow" width='12'/>
                                    </Step>
                            ))
                            } 
                        </Stepper>
                        { purchaseInWork.Stage === 200 ? <DocumentRequestList documentRequest={purchaseInWork.DocumentRequest} purchaseId={purchaseInWork.Id}/> : null }
                        
                        <Box className="pws_card_footer">
                            <Stack className="pws_source" direction="row" alignItems="center">
                                <Tooltip title="Ссылка на сторонний ресурс">
                                    <IconButton sx={{ ml: 1 }} size="small">
                                        <OpenInNewIcon fontSize='inherit'></OpenInNewIcon>
                                    </IconButton>
                                </Tooltip>
                                <Link className="pws_source_link"  rel="noopener noreferrer" target="_blank" href={purchaseInWork.PurchaseUrl} underline="hover">
                                    Источник
                                </Link>
                            </Stack>
                            {
                                purchaseInWork.Manager
                                ?
                                    <ManagerContacts manager={purchaseInWork.Manager} chatAvailable={!isPurchaseInWorkFinished} purchaseUrl={purchaseInWork.PurchaseUrl}/>
                                :
                                    <Box className="no_manager">
                                        Менеджер еще не назначен
                                    </Box>
                            }
                        </Box>
                    </CardContent>
                </Card>
            </div>
        </React.Fragment>
    )
}
