import React from "react"
import { Modal, Box } from '@mui/material';
import { MailingsEdit } from "./mailings-edit";

export const MailingsEditModal = (props) => {
    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={props.onClose}
                className="mailings_edit_modal"
            >
                <Box className="mailings_edit_modal_box">
                    <MailingsEdit mailing={props.mailing} onClose={props.onClose} onSave={props.onSave} />
                </Box>
            </Modal>
        </React.Fragment>
    )
}

